"use client";

import React, { useState } from "react";
import Link from "next/link";
import {
  workweare1,
  protectiveclothing,
  handarmprotection,
  footprotection,
  respiratoryprotection,
  eyeprotection,
  headprotection,
  kidsselection,
} from "@/data/products";

export default function SearchModal() {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  // Merge all products into one array
  const allProducts = [
    ...workweare1,
    ...protectiveclothing,
    ...handarmprotection,
    ...footprotection,
    ...respiratoryprotection,
    ...eyeprotection,
    ...headprotection,
    ...kidsselection,
  ];

  // Handle search input
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    // Filter products by matching `value` with the product's `title`
    const results = allProducts.filter((product) =>
      product.title.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredResults(results);
  };

  return (
    <div className="offcanvas offcanvas-end canvas-search" id="canvasSearch">
      <div className="canvas-wrapper">
        <header className="tf-search-head">
          <div className="title fw-5">
            Search our site
            <div className="close">
              <span
                className="icon-close icon-close-popup"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>
          </div>

          <div className="tf-search-sticky">
            <form
              onSubmit={(e) => e.preventDefault()}
              className="tf-mini-search-frm"
            >
              <fieldset className="text">
                <input
                  type="text"
                  placeholder="Search"
                  name="text"
                  tabIndex={0}
                  value={searchTerm}
                  onChange={handleSearch}
                  aria-required="true"
                  required
                />
              </fieldset>
              <button className="" type="submit">
                <i className="icon-search" />
              </button>
            </form>
          </div>
        </header>

        <div className="canvas-body p-0">
          <div className="tf-search-content">
            {/* Show search results only if user has typed something */}
            {searchTerm && (
              <div style={{ margin: "1rem 0" }}>
                {filteredResults.length > 0 ? (
                  filteredResults.map((item) => (
                    <div key={item.id} style={{ marginBottom: "0.5rem" }}>
                      {/* Example: Link to your product details page */}
                      <Link href={`/product-detail/${item.id}`}>
                        {item.title}
                      </Link>
                    </div>
                  ))
                ) : (
                  <p>No products found.</p>
                )}
              </div>
            )}

            {/* Quick links section (only show if user isn't searching) */}
            {!searchTerm && (
              <div className="tf-cart-hide-has-results">
                <div className="tf-col-quicklink">
                  <div className="tf-search-content-title fw-5">Quick link</div>
                  <ul className="tf-quicklink-list">
                    <li className="tf-quicklink-item">
                      <Link href={`/workwear`} className="">
                        Workwear
                      </Link>
                    </li>
                    <li className="tf-quicklink-item">
                      <Link href={`/eye-protection`} className="">
                        Eye Protection
                      </Link>
                    </li>
                    <li className="tf-quicklink-item">
                      <Link href={`/foot-protection`} className="">
                        Foot Protection
                      </Link>
                    </li>
                    <li className="tf-quicklink-item">
                      <Link href={`/hand-arm-protection`} className="">
                        Hand Arm Protection
                      </Link>
                    </li>
                    <li className="tf-quicklink-item">
                      <Link href={`/head-protection`} className="">
                        Head Protection
                      </Link>
                    </li>
                    <li className="tf-quicklink-item">
                      <Link href={`/kids-selection`} className="">
                        Kids Selection
                      </Link>
                    </li>
                    <li className="tf-quicklink-item">
                      <Link href={`/respiratory-protection`} className="">
                        Respiratory Protection
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
