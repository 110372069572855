import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import your translations (make sure the paths are correct)
import en from "../public/locales/en/common.json";
import de from "../public/locales/de/common.json";
import sq from "../public/locales/sq/common.json";

i18n
  .use(initReactI18next) // Use the i18next integration for React
  .init({
    resources: {
      en: {
        translation: en,
      },
      de: {
        translation: de,
      },
      sq: {
        translation: sq,
      },
    },
    lng: "de", // Set default language
    fallbackLng: "en", // Fallback language if the selected language is not available
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });

export default i18n;
