


export const productsPages = [
  {
    heading: "Service",
    links: [
      { href: "/hand-arm-protection", text: "Hand Arm Protection" },
      { href: "/foot-protection", text: "Foot protection" },
      { href: "/respiratory-protection", text: "Respiratory protection" },
      { href: "/eye-protection", text: "Eye protection" },
      { href: "/head-protection", text: "Head protection" },
      { href: "/kids-selection", text: "Kids selection" },
      { href: "/workwear", text: "Workwear" },
      { href: "/protective-clothing", text: "Protective clothing " },
    ],
  },
  {
    heading: "HAND / ARM PROTECTION",
    links: [
      { href: "/hand-arm-protection", text: "Arm protection" },
      { href: "/hand-arm-protection", text: "Antistatic gloves" },
      {
        href: "/hand-arm-protection",
        text: "Chemical protective gloves",
      },
      { href: "/hand-arm-protection", text: "Mechanic's gloves" },
      { href: "/hand-arm-protection", text: "Welding gloves" },
      { href: "/hand-arm-protection", text: "Puncture protection gloves" },
      { href: "/hand-arm-protection", text: "Winter gloves" },
      { href: "/hand-arm-protection", text: "Leather gloves" },
      { href: "/hand-arm-protection", text: "Nitrile gloves" },
      { href: "/hand-arm-protection", text: "Knitted gloves" },
      { href: "/hand-arm-protection", text: "PVC gloves" },
      { href: "/hand-arm-protection", text: "Cotton gloves" },
      { href: "/hand-arm-protection", text: "Disposable gloves" },
    ],
  },

  {
    heading: "FOOT PROTECTION",
    links: [
      { href: "/foot-protection", text: "Clogs" },
      { href: "/foot-protection", text: "Low shoes" },
      {
        href: "/foot-protection",
        text: "Sandals",
      },
      { href: "/foot-protection", text: "Slippers" },
      { href: "/foot-protection", text: "Boots" },
      { href: "/foot-protection", text: "Winter boots" },
      { href: "/foot-protection", text: "Winter gloves" },
    ],
  },
  {
    heading: "Respiratory protection",
    links: [{ href: "/respiratory-protection", text: "Respirator masks" }],
  },
  {
    heading: "Workwear",
    links: [{ href: "/respiratory-protection", text: "Trousers" }],
    links: [{ href: "/respiratory-protection", text: "Jackets" }],
    links: [{ href: "/respiratory-protection", text: "Waistcoats" }],
    links: [{ href: "/respiratory-protection", text: "Pullovers" }],
    links: [{ href: "/respiratory-protection", text: "Shirts" }],
    links: [{ href: "/respiratory-protection", text: "Aprons" }],
  },

  {
    heading: "Respiratory protection",
    links: [{ href: "/respiratory-protection", text: "Respirator masks" }],
    heading: "Kids selection",
    links: [{ href: "/respiratory-protection", text: "Garments" }],
    links: [{ href: "/respiratory-protection", text: "Hand protection" }],
  },
  {
    heading: "Eye protection",
    links: [
      { href: "/eye-protection", text: "Ultraviolet filter" },
      { href: "/eye-protection", text: "Sunglare filter" },
    ],
  },
];

export const productDetailPages = [
  {
    heading: "Product layouts",
    links: [
      { href: "/product-detail/1", text: "Catalogue" },
      { href: "/product-grid-1/2", text: "Category brochures" },
      { href: "/product-grid-2/3", text: "Guidelines" },
      { href: "/product-stacked/4", text: "Resistance lists" },
    ],
  },
];

export const pages = [
  {
    href: "/about-us",
    text: "About us",
    className: "menu-link-text link text_black-2",
    links: null,
  },
  {
    href: "#",
    text: "Brands",
    className: "menu-link-text link text_black-2",
    links: [
      {
        href: "/brands",
        text: "Brands",
        className: "menu-link-text link text_black-2 position-relative",
        label: "New",
      },
      {
        href: "/brands-v2",
        text: "Brand V2",
        className: "menu-link-text link text_black-2",
      },
    ],
  },
  {
    href: "#",
    text: "Contact",
    className: "menu-link-text link text_black-2",
    links: [
      {
        href: "/contact-1",
        text: "Contact 1",
        className: "menu-link-text link text_black-2",
      },
      {
        href: "/contact-2",
        text: "Contact 2",
        className: "menu-link-text link text_black-2",
      },
    ],
  },
  {
    href: "#",
    text: "FAQ",
    className: "menu-link-text link text_black-2",
    links: [
      {
        href: "/faq-1",
        text: "FAQ 01",
        className: "menu-link-text link text_black-2",
      },
      {
        href: "/faq-2",
        text: "FAQ 02",
        className: "menu-link-text link text_black-2",
      },
    ],
  },
  {
    href: "#",
    text: "Store",
    className: "menu-link-text link text_black-2",
    links: [
      {
        href: "/our-store",
        text: "Our store",
        className: "menu-link-text link text_black-2",
      },
      {
        href: "/store-locations",
        text: "Store locator",
        className: "menu-link-text link text_black-2",
      },
    ],
  },
  {
    href: "/timeline",
    text: "Timeline",
    className: "menu-link-text link text_black-2 position-relative",
    label: "New",
  },
  {
    href: "/view-cart",
    text: "View cart",
    className: "menu-link-text link text_black-2 position-relative",
  },
  {
    href: "/checkout",
    text: "Check out",
    className: "menu-link-text link text_black-2 position-relative",
  },
  {
    href: "#",
    text: "Payment",
    className: "menu-link-text link text_black-2",
    links: [
      {
        href: "/payment-confirmation",
        text: "Payment Confirmation",
        className: "menu-link-text link text_black-2",
      },
      {
        href: "/payment-failure",
        text: "Payment Failure",
        className: "menu-link-text link text_black-2",
      },
    ],
  },
  {
    href: "#",
    text: "My account",
    className: "menu-link-text link text_black-2",
    links: [
      {
        href: "/my-account",
        text: "My account",
        className: "menu-link-text link text_black-2",
      },
      {
        href: "/my-account-orders",
        text: "My order",
        className: "menu-link-text link text_black-2",
      },
      {
        href: "/my-account-orders-details",
        text: "My order details",
        className: "menu-link-text link text_black-2",
      },
      {
        href: "/my-account-address",
        text: "My address",
        className: "menu-link-text link text_black-2",
      },
      {
        href: "/my-account-edit",
        text: "My account details",
        className: "menu-link-text link text_black-2",
      },
      {
        href: "/my-account-wishlist",
        text: "My wishlist",
        className: "menu-link-text link text_black-2",
      },
    ],
  },
  {
    href: "/invoice",
    text: "Invoice",
    className: "menu-link-text link text_black-2 position-relative",
  },
  {
    href: "/page-not-found",
    text: "404",
    className: "menu-link-text link text_black-2 position-relative",
  },
];

export const blogLinks = [
  { href: "/blog-grid", text: "Grid layout" },
  { href: "/blog-sidebar-left", text: "Left sidebar" },
  { href: "/blog-sidebar-right", text: "Right sidebar" },
  { href: "/blog-list", text: "Blog list" },
  { href: "/blog-detail/1", text: "Single Post" },
];

export const navItems = [
  {
    id: "dropdown-menu-one",
    label: "Service",
    links: [
      { href: "/hand-arm-protection", label: "Hand Arm Protection" },
      { href: "/foot-protection", label: "Foot protection" },
      { href: "/respiratory-protection", label: "Respiratory protection" },
      { href: "/eye-protection", label: "Eye protection" },
      { href: "/head-protection", label: "Head protection" },
      { href: "/kids-selection", label: "Kids selection" },
      { href: "/workwear", label: "Workwear" },
      { href: "/protective-clothing", label: "Protective clothing" },
    ],
  },
  {
    id: "dropdown-menu-two",
    label: "HAND / ARM PROTECTION",
    links: [
      { href: "/hand-arm-protection", label: "Arm protection" },
      { href: "/hand-arm-protection", label: "Antistatic gloves" },
      { href: "/hand-arm-protection", label: "Chemical protective gloves" },
      { href: "/hand-arm-protection", label: "Mechanic's gloves" },
      { href: "/hand-arm-protection", label: "Welding gloves" },
      { href: "/hand-arm-protection", label: "Puncture protection gloves" },
      { href: "/hand-arm-protection", label: "Winter gloves" },
      { href: "/hand-arm-protection", label: "Leather gloves" },
      { href: "/hand-arm-protection", label: "Nitrile gloves" },
      { href: "/hand-arm-protection", label: "Knitted gloves" },
      { href: "/hand-arm-protection", label: "PVC gloves" },
      { href: "/hand-arm-protection", label: "Cotton gloves" },
      { href: "/hand-arm-protection", label: "Disposable gloves" },
    ],
  },
  {
    id: "dropdown-menu-three",
    label: "FOOT PROTECTION",
    links: [
      { href: "/foot-protection", label: "Clogs" },
      { href: "/foot-protection", label: "Low shoes" },
      { href: "/foot-protection", label: "Sandals" },
      { href: "/foot-protection", label: "Slippers" },
      { href: "/foot-protection", label: "Boots" },
      { href: "/foot-protection", label: "Winter boots" },
      { href: "/foot-protection", label: "Winter gloves" },
    ],
  },
  {
    id: "dropdown-menu-four",
    label: "Respiratory protection",
    links: [{ href: "/respiratory-protection", label: "Respirator masks" }],
  },
  {
    id: "dropdown-menu-five",
    label: "Workwear",
    links: [
      { href: "/respiratory-protection", label: "Trousers" },
      { href: "/respiratory-protection", label: "Jackets" },
      { href: "/respiratory-protection", label: "Waistcoats" },
      { href: "/respiratory-protection", label: "Pullovers" },
      { href: "/respiratory-protection", label: "Shirts" },
      { href: "/respiratory-protection", label: "Aprons" },
    ],
  },
  {
    id: "dropdown-menu-six",
    label: "Kids selection",
    links: [
      { href: "/respiratory-protection", label: "Garments" },
      { href: "/respiratory-protection", label: "Hand protection" },
    ],
  },
  {
    id: "dropdown-menu-seven",
    label: "Eye protection",
    links: [
      { href: "/eye-protection", label: "Ultraviolet filter" },
      { href: "/eye-protection", label: "Sunglare filter" },
    ],
  },
];
