"use client";
import React from "react";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import LanguageSelect from "../common/LanguageSelect";
import CurrencySelect from "../common/CurrencySelect";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
export default function Topbar1() {
  const { t } = useTranslation();
  return (
    <div className="tf-top-bar bg_white line">
      <div className="px_15 lg-px_40">
        <div className="tf-top-bar_wrap grid-3 gap-30 align-items-center">
          <ul className="tf-top-bar_item tf-social-icon d-flex gap-10">
            <li>
              <a
                href="https://www.facebook.com/KompaktArbeitsschutz"
                target="_blank"
                title="facebook kompakt arbeitsschutz"
                aria-label="facebook kompakt arbeitsschutz"
                className="box-icon w_28 round social-facebook bg_line"
              >
                <i className="icon fs-12 icon-fb" />
              </a>
            </li>
            <li>
              <a
                href="#"
                className="box-icon w_28 round social-twiter bg_line"
                aria-label="twitter kompakt arbeitsschutz"
                title=" twitter kompakt arbeitsschutz"
              >
                <i className="icon fs-10 icon-Icon-x" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/kompakt.arbeitsschutz?igsh=bTQ2OXB0ZmtsaWV3"
                target="_blank"
                aria-label="instagram kompakt arbeitsschutz"
                title="instagram kompakt arbeitsschutz"
                className="box-icon w_28 round social-instagram bg_line"
              >
                <i className="icon fs-12 icon-instagram" />
              </a>
            </li>
          </ul>
          <div className="text-center overflow-hidden">
            <Swiper
              dir="ltr"
              className="swiper tf-sw-top_bar"
              slidesPerView={1}
              modules={[Autoplay]}
              speed={1000}
              autoplay={{
                delay: 2000,
              }}
              loop
            >
              <SwiperSlide className="swiper-slide">
                <p className="top-bar-text fw-5">{t("topbar.top1")}</p>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <p className="top-bar-text fw-5">{t("topbar.top2")}</p>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <p className="top-bar-text fw-5">{t("topbar.top3")}</p>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="top-bar-language tf-cur justify-content-end">
            <div className="tf-languages">
              <LanguageSelect
                parentClassName={
                  "image-select center style-default type-languages"
                }
                topStart
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
