"use client"; // Ensure this is the first line

import { workweare1 } from "@/data/products";
import React, { useState } from "react";
import { useTranslation } from "react-i18next"; // Import the translation hook
import { ProductCard } from "../../shopCards/ProductCard";
import { useRouter } from "next/navigation"; // Correct import for App Directory

export default function Products() {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [allproducts, setAllproducts] = useState([...workweare1.slice(0, 8)]); // Initial 8 products
  const { t } = useTranslation(); // Access translation function

  const router = useRouter(); // Directly use useRouter

  const handleLoad = () => {
    setLoading(true);

    setTimeout(() => {
      // Load more products
      setAllproducts((prev) => [
        ...prev,
        ...workweare1.slice(prev.length, prev.length + 8), // Next 8 products
      ]);
      setLoading(false);
      setLoaded(true);

      // Navigate to /workwear after loading more products
      router.push("/workwear");
    }, 1000);
  };

  return (
    <section className="flat-spacing-5 pt_0 flat-seller">
      <div className="container">
        <div className="flat-title">
          <span className="title wow fadeInUp" data-wow-delay="0s">
            {t("products.title")}{" "}
          </span>
          <p className="sub-title wow fadeInUp" data-wow-delay="0s">
            {t("products.description")}
          </p>
        </div>
        <div
          className="grid-layout wow fadeInUp"
          data-wow-delay="0s"
          data-grid="grid-4"
        >
          {allproducts.map((product, i) => (
            <ProductCard product={product} key={i} />
          ))}
        </div>
        {!loaded && (
          <div className="tf-pagination-wrap view-more-button text-center">
            <button
              className={`tf-btn-loading tf-loading-default style-2 btn-loadmore ${
                loading ? "loading" : ""
              }`}
              onClick={handleLoad}
            >
              <span className="text">Load more</span>
            </button>
          </div>
        )}
      </div>
    </section>
  );
}
