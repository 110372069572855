"use client";
import React, { useState } from "react";
import { usePathname } from "next/navigation";
import Link from "next/link";
import { productsPages } from "@/data/menu"; // Assuming your menu data is imported correctly

export default function Nav({ isArrow = true, textColor = "", Linkfs = "" }) {
  const pathname = usePathname();

  const [activeCategory, setActiveCategory] = useState(null);

  const isMenuActive = (menuItem) => {
    const pathSegments = pathname.split("/");
    return menuItem.href?.split("/")[1] === pathSegments[1];
  };

  // Find the correct links for the active category
  const getCategoryLinks = (categoryHeading) => {
    const category = productsPages.find(
      (item) => item.heading.toLowerCase() === categoryHeading.toLowerCase()
    );
    return category ? category.links : [];
  };

  return (
    <>
      <li className="menu-item">
        <a
          href="#"
          className={`item-link ${Linkfs} ${textColor} ${
            isMenuActive(productsPages) ? "activeMenu" : ""
          }`}
        >
          Product
          {isArrow ? <i className="icon icon-arrow-down" /> : ""}
        </a>
        <div className="sub-menu mega-menu">
          <div className="container">
            <div className="row">
              {productsPages.map((menu, index) => (
                <div className="col-lg-2" key={index}>
                  <div className="mega-menu-item">
                    <div
                      className="menu-heading"
                      onClick={() => setActiveCategory(menu.heading)} // Set active category on click
                    >
                      {menu.heading}
                    </div>
                    <ul className="menu-list">
                      {/* Only render links for the active category */}
                      {activeCategory === menu.heading &&
                        menu.links.map((link, linkIndex) => (
                          <li key={linkIndex}>
                            <Link
                              href={link.href}
                              className={`menu-link-text link ${
                                isMenuActive(link) ? "activeMenu" : ""
                              }`}
                            >
                              {link.text}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </li>
    </>
  );
}
