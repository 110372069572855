export const products1 = [
  {
    id: 0,
    imgSrc: "/images/products/workwear_005.jpg",
    imgHoverSrc: "/images/products/Workwear_002.jpg",
    title: "7613 // MOTION TEX PLUS",
    sizes: ["XS", "S", "M", "L", "X", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    width: 800,
    height: 600,
    isAvailable: true,
  },
];

// * ================== Workwear ==================

export const workweare1 = [
  {
    id: 1,
    imgSrc: "/images/products/Workwear_001.jpg",
    imgHoverSrc: "/images/products/Workwear_002.jpg",
    title: "7613 // MOTION TEX PLUS",
    sizes: ["XS", "S", "M", "L", "X", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    width: 800, // Ensure these are present and are numbers
    height: 600,
    isAvailable: true,
  },
  {
    id: 2,
    imgSrc: "/images/products/workwear_005.jpg",
    imgHoverSrc: "/images/products/workwear_006.jpg",
    title: "7161 // MOTION TEX LIGHT",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "New arrivals"],
    brand: "Workweare",
    width: 800, // Ensure these are present and are numbers
    height: 600,
    isAvailable: true,
  },
  {
    id: 3,
    imgSrc: "/images/products/workweare_007.jpg",
    imgHoverSrc: "/images/products/workweare_008.jpg",
    title: "7163 // MOTION TEX LIGHT",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "On Sale"],
    width: 800, // Ensure these are present and are numbers
    height: 600,
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 4,
    imgSrc: "/images/products/workweare_8.jpg",
    imgHoverSrc: "/images/products/workweare_9.jpg",
    title: "7163 // MOTION TEX LIGHT",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 5,
    imgSrc: "/images/products/workwear9.jpg",
    imgHoverSrc: "/images/products/workwear10.jpg",
    title: "7164 // MOTION TEX LIGHT",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "New arrivals"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 6,
    imgSrc: "/images/products/workwear11.jpg",
    imgHoverSrc: "/images/products/workwear12.jpg",
    title: "7166 // MOTION TEX LIGHT",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "New arrivals"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 7,
    imgSrc: "/images/products/workwear13.jpg",
    imgHoverSrc: "/images/products/workwear14.jpg",
    title: "7167 // MOTION TEX LIGHT",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 8,
    imgSrc: "/images/products/workwear15.jpg",
    imgHoverSrc: "/images/products/workwear16.jpg",
    title: "7613 // MOTION TEX PLUS",
    sizes: [
      24, 25, 26, 27, 28, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64,
      66, 68, 90, 94, 98, 102, 106, 110,
    ],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 9,
    sizes: [
      24, 25, 26, 27, 28, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64,
      66, 68, 90, 94, 98, 102, 106, 110,
    ],
    imgSrc: "/images/products/workwear17.jpg",
    imgHoverSrc: "/images/products/workwear18.jpg",
    title: "7614 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 10,
    sizes: [
      24, 25, 26, 27, 28, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64,
      66, 68, 90, 94, 98, 102, 106, 110,
    ],
    imgSrc: "/images/products/workwear19.jpg",
    imgHoverSrc: "/images/products/workwear20.jpg",
    title: "7616 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 11,
    sizes: [
      24, 25, 26, 27, 28, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64,
      66, 68, 90, 94, 98, 102, 106, 110,
    ],
    imgSrc: "/images/products/workwear21.jpg",
    imgHoverSrc: "/images/products/workwear22.jpg",
    title: "7617 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "New arrivals"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 12,
    sizes: [
      24, 25, 26, 27, 28, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64,
      66, 68, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear23.jpg",
    imgHoverSrc: "/images/products/workwear24.jpg",

    title: "7620 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 13,
    sizes: [
      24, 25, 26, 27, 28, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64,
      66, 68, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear25.jpg",
    imgHoverSrc: "/images/products/workwear26.jpg",

    title: "7621 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 14,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear27.jpg",
    imgHoverSrc: "/images/products/workwear28.jpg",

    title: "7005 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 15,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear29.jpg",
    imgHoverSrc: "/images/products/workwear30.jpg",

    title: "7005 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 16,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear31.jpg",
    imgHoverSrc: "/images/products/workwear32.jpg",

    title: "7005 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 17,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear33.jpg",
    imgHoverSrc: "/images/products/workwear34.jpg",

    title: "7005 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 18,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear35.jpg",
    imgHoverSrc: "/images/products/workwear36.jpg",

    title: "7005 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 19,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear37.jpg",
    imgHoverSrc: "/images/products/workwear38.jpg",

    title: "7005 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 20,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear39.jpg",
    imgHoverSrc: "/images/products/workwear40.jpg",

    title: "7005 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 21,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear41.jpg",
    imgHoverSrc: "/images/products/workwear42.jpg",

    title: "7005 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 22,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear44.jpg",
    imgHoverSrc: "/images/products/workwear43.jpg",

    title: "7005 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 23,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear45.jpg",
    imgHoverSrc: "/images/products/workwear46.jpg",

    title: "7005 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 24,
    sizes: [
      24, 25, 26, 27, 28, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64,
      66, 68, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear47.jpg",
    imgHoverSrc: "/images/products/workwear48.jpg",

    title: "7622 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 25,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear49.jpg",
    imgHoverSrc: "/images/products/workwear50.jpg",

    title: "7010 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 26,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear51.jpg",
    imgHoverSrc: "/images/products/workwear52.jpg",

    title: "7010 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 27,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear54.jpg",
    imgHoverSrc: "/images/products/workwear53.jpg",

    title: "7010 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 28,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear55.jpg",
    imgHoverSrc: "/images/products/workwear56.jpg",

    title: "7010 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 29,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear57.jpg",
    imgHoverSrc: "/images/products/workwear58.jpg",

    title: "7010 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 30,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear59.jpg",
    imgHoverSrc: "/images/products/workwear60.jpg",

    title: "7010 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 31,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear61.jpg",
    imgHoverSrc: "/images/products/workwear62.jpg",

    title: "7010 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },

  {
    id: 32,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear63.jpg",
    imgHoverSrc: "/images/products/workwear64.jpg",

    title: "7010 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },

  {
    id: 33,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear65.jpg",
    imgHoverSrc: "/images/products/workwear66.jpg",

    title: "7010 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 34,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear67.jpg",
    imgHoverSrc: "/images/products/workwear68.jpg",

    title: "7010 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 35,
    sizes: [
      24, 25, 26, 27, 28, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64,
      66, 68, 90, 94, 98, 102, 106, 110,
    ],
    imgSrc: "/images/products/workwear69.jpg",
    imgHoverSrc: "/images/products/workwear70.jpg",

    title: "7623 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 36,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear71.jpg",
    imgHoverSrc: "/images/products/workwear72.jpg",

    title: "7015 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 37,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear73.jpg",
    imgHoverSrc: "/images/products/workwear74.jpg",

    title: "7015 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 38,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear75.jpg",
    imgHoverSrc: "/images/products/workwear76.jpg",

    title: "7015 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 39,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear77.jpg",
    imgHoverSrc: "/images/products/workwear78.jpg",

    title: "7015 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 40,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear79.jpg",
    imgHoverSrc: "/images/products/workwear80.jpg",

    title: "7015 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 41,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear81.jpg",
    imgHoverSrc: "/images/products/workwear82.jpg",

    title: "7015 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 42,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear85.jpg",
    imgHoverSrc: "/images/products/workwear86.jpg",

    title: "7015 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 43,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear87.jpg",
    imgHoverSrc: "/images/products/workwear88.jpg",

    title: "7015 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 44,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear89.jpg",
    imgHoverSrc: "/images/products/workwear90.jpg",

    title: "7015 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 45,
    sizes: [
      24, 25, 26, 27, 28, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64,
      66, 68, 90, 94, 98, 102, 106, 110,
    ],
    imgSrc: "/images/products/workwear91.jpg",
    imgHoverSrc: "/images/products/workwear92.jpg",

    title: "7624 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 46,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],
    imgSrc: "/images/products/workwear93.jpg",
    imgHoverSrc: "/images/products/workwear94.jpg",

    title: "7020 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 47,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear97.jpg",
    imgHoverSrc: "/images/products/workwear98.jpg",

    title: "7020 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 48,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear99.jpg",
    imgHoverSrc: "/images/products/workwear100.jpg",

    title: "7020 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 49,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear101.jpg",
    imgHoverSrc: "/images/products/workwear102.jpg",

    title: "7020 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 50,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear103.jpg",
    imgHoverSrc: "/images/products/workwear104.jpg",

    title: "7020 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 51,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear105.jpg",
    imgHoverSrc: "/images/products/workwear106.jpg",

    title: "7020 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 52,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear107.jpg",
    imgHoverSrc: "/images/products/workwear108.jpg",

    title: "7020 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 53,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear109.jpg",
    imgHoverSrc: "/images/products/workwear110.jpg",

    title: "7020 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 54,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear111.jpg",
    imgHoverSrc: "/images/products/workwear112.jpg",

    title: "7020 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 55,
    sizes: [
      24, 25, 26, 27, 28, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64,
      66, 68, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear113.jpg",
    imgHoverSrc: "/images/products/workwear114.jpg",

    title: "7626 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 56,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear115.jpg",
    imgHoverSrc: "/images/products/workwear116.jpg",

    title: "7025 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 57,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear118.jpg",
    imgHoverSrc: "/images/products/workwear117.jpg",

    title: "7025 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 58,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear119.jpg",
    imgHoverSrc: "/images/products/workwear120.jpg",

    title: "7025 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 59,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear121.jpg",
    imgHoverSrc: "/images/products/workwear122.jpg",

    title: "7025 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 60,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear124.jpg",
    imgHoverSrc: "/images/products/workwear123.jpg",

    title: "7025 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 61,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear125.jpg",
    imgHoverSrc: "/images/products/workwear126.jpg",

    title: "7025 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 62,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear127.jpg",
    imgHoverSrc: "/images/products/workwear128.jpg",

    title: "7025 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 63,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear129.jpg",
    imgHoverSrc: "/images/products/workwear130.jpg",

    title: "7025 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 64,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear131.jpg",
    imgHoverSrc: "/images/products/workwear132.jpg",

    title: "7025 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 65,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear133.jpg",
    imgHoverSrc: "/images/products/workwear134.jpg",

    title: "7025 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 66,
    sizes: [
      24, 25, 26, 27, 28, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64,
      66, 68, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear135.jpg",
    imgHoverSrc: "/images/products/workwear136.jpg",

    title: "7627 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 67,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear137.jpg",
    imgHoverSrc: "/images/products/workwear138.jpg",

    title: "7030 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 68,
    sizes: [
      "42",
      "44",
      "46",
      "48",
      "50",
      "50",
      "52",
      "52",
      "54",
      "56",
      "56",
      "58",
      "60",
      "60",
      "62",
      "64",
      "66",
      "68",
    ],

    imgSrc: "/images/products/workwear139.jpg",
    imgHoverSrc: "/images/products/workwear140.jpg",

    title: "7650 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 69,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear141.jpg",
    imgHoverSrc: "/images/products/workwear142.jpg",

    title: "7031 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 70,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear143.jpg",
    imgHoverSrc: "/images/products/workwear144.jpg",

    title: "7032 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 71,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear145.jpg",
    imgHoverSrc: "/images/products/workwear146.jpg",

    title: "7034 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 72,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear149.jpg",
    imgHoverSrc: "/images/products/workwear150.jpg",

    title: "7180 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 73,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear151.jpg",
    imgHoverSrc: "/images/products/workwear152.jpg",

    title: "7180W // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 74,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear153.jpg",
    imgHoverSrc: "/images/products/workwear154.jpg",

    title: "7181 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 75,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear155.jpg",
    imgHoverSrc: "/images/products/workwear156.jpg",

    title: "7181W // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 76,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear157.jpg",
    imgHoverSrc: "/images/products/workwear158.jpg",

    title: "7182 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 77,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear159.jpg",
    imgHoverSrc: "/images/products/workwear160.jpg",

    title: "7182W // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 78,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear161.jpg",
    imgHoverSrc: "/images/products/workwear162.jpg",

    title: "7190 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 79,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear163.jpg",
    imgHoverSrc: "/images/products/workwear164.jpg",

    title: "7036 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 80,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear165.jpg",
    imgHoverSrc: "/images/products/workwear166.jpg",

    title: "7192 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 81,
    sizes: [
      "42",
      "44",
      "46",
      "48",
      "88",
      "50",
      "52",
      "54",
      "54",
      "56",
      "58",
      "60",
      "62",
      "64",
      "66",
      "68",
      "89",
    ],

    imgSrc: "/images/products/workwear167.jpg",
    imgHoverSrc: "/images/products/workwear168.jpg",

    title: "7651 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 82,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear171.jpg",
    imgHoverSrc: "/images/products/workwear172.jpg",

    title: "7195 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 83,
    sizes: [
      "42",
      "44",
      "46",
      "48",
      "50",
      "52",
      "54",
      "56",
      "58",
      "60",
      "62",
      "64",
      "66",
      "68",
    ],

    imgSrc: "/images/products/workwear173.jpg",
    imgHoverSrc: "/images/products/workwear174.jpg",

    title: "7652 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 84,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear175.jpg",
    imgHoverSrc: "/images/products/workwear176.jpg",

    title: "7038 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 85,
    sizes: [
      "42",
      "44",
      "46",
      "48",
      "50",
      "52",
      "54",
      "56",
      "58",
      "60",
      "62",
      "64",
      "66",
      "68",
    ],

    imgSrc: "/images/products/workwear177.jpg",
    imgHoverSrc: "/images/products/workwear178.jpg",

    title: "7653 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 86,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear179.jpg",
    imgHoverSrc: "/images/products/workwear180.jpg",

    title: "7039 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 87,
    sizes: ["One Size"],

    imgSrc: "/images/products/workwear181.jpg",
    imgHoverSrc: "/images/products/workwear182.jpg",

    title: "731",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 88,
    sizes: ["One Size"],

    imgSrc: "/images/products/workwear183.jpg",
    imgHoverSrc: "/images/products/workwear184.jpg",

    title: "731",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 89,
    sizes: ["One Size"],

    imgSrc: "/images/products/workwear185.jpg",
    imgHoverSrc: "/images/products/workwear186.jpg",

    title: "731",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 90,
    sizes: [
      "42",
      "44",
      "46",
      "46",
      "48",
      "50",
      "50",
      "52",
      "54",
      "56",
      "56",
      "58",
      "60",
      "62",
      "64",
      "66",
      "68",
    ],

    imgSrc: "/images/products/workwear187.jpg",
    imgHoverSrc: "/images/products/workwear188.jpg",

    title: "7654 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 91,
    sizes: ["One Size"],

    imgSrc: "/images/products/workwear192.jpg",
    imgHoverSrc: "/images/products/workwear192.jpg",

    title: "731P",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 92,
    sizes: [
      "42",
      "44",
      "46",
      "48",
      "59",
      "50",
      "52",
      "52",
      "54",
      "54",
      "56",
      "58",
      "60",
      "62",
      "64",
      "69",
      "66",
      "68",
    ],

    imgSrc: "/images/products/workwear193.jpg",
    imgHoverSrc: "/images/products/workwear194.jpg",

    title: "7656 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 93,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear195.jpg",
    imgHoverSrc: "/images/products/workwear196.jpg",

    title: "7041 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 94,
    sizes: ["One Size"],

    imgSrc: "/images/products/workwear197.jpg",
    imgHoverSrc: "/images/products/workwear198.jpg",

    title: "732",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 95,
    sizes: ["One Size"],

    imgSrc: "/images/products/workwear199.jpg",
    imgHoverSrc: "/images/products/workwear200.jpg",

    title: "732",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 96,
    sizes: ["One Size"],

    imgSrc: "/images/products/workwear201.jpg",
    imgHoverSrc: "/images/products/workwear202.jpg",

    title: "732",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 97,
    sizes: [42, 44, 46, 48, 50, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear203.jpg",
    imgHoverSrc: "/images/products/workwear204.jpg",

    title: "7657 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 98,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear205.jpg",
    imgHoverSrc: "/images/products/workwear206.jpg",

    title: "7043 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 99,
    sizes: [42, 44, 46, 48, 50, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear207.jpg",
    imgHoverSrc: "/images/products/workwear208.jpg",

    title: "7500 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 100,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear209.jpg",
    imgHoverSrc: "/images/products/workwear210.jpg",

    title: "7044 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 101,
    sizes: [42, 44, 46, 48, 50, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear211.jpg",
    imgHoverSrc: "/images/products/workwear211.jpg",

    title: "7501 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 102,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear213.jpg",
    imgHoverSrc: "/images/products/workwear214.jpg",

    title: "7047 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 103,
    sizes: [42, 44, 46, 48, 50, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear215.jpg",
    imgHoverSrc: "/images/products/workwear216.jpg",

    title: "7502 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 104,
    sizes: [42, 44, 46, 48, 50, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear217.jpg",
    imgHoverSrc: "/images/products/workwear218.jpg",

    title: "7503 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 105,
    sizes: [42, 44, 46, 48, 50, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear219.jpg",
    imgHoverSrc: "/images/products/workwear220.jpg",

    title: "7504 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 106,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear221.jpg",
    imgHoverSrc: "/images/products/workwear222.jpg",

    title: "7510 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 107,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear223.jpg",
    imgHoverSrc: "/images/products/workwear224.jpg",

    title: "7511 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 108,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear225.jpg",
    imgHoverSrc: "/images/products/workwear226.jpg",

    title: "7512 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 109,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear227.jpg",
    imgHoverSrc: "/images/products/workwear228.jpg",

    title: "7513 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 110,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear227.jpg",
    imgHoverSrc: "/images/products/workwear228.jpg",

    title: "7513 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 111,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear229.jpg",
    imgHoverSrc: "/images/products/workwear230.jpg",

    title: "7514 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 112,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear231.jpg",
    imgHoverSrc: "/images/products/workwear232.jpg",

    title: "7520 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 113,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear231.jpg",
    imgHoverSrc: "/images/products/workwear232.jpg",

    title: "7520 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 114,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear233.jpg",
    imgHoverSrc: "/images/products/workwear234.jpg",

    title: "7150 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 115,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear235.jpg",
    imgHoverSrc: "/images/products/workwear236.jpg",

    title: "7521 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 116,
    sizes: ["/"],

    imgSrc: "/images/products/workwear237.jpg",
    imgHoverSrc: "/images/products/workwear238.jpg",

    title: "7150K",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 117,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear241.jpg",
    imgHoverSrc: "/images/products/workwear242.jpg",

    title: "7151 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 118,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear243.jpg",
    imgHoverSrc: "/images/products/workwear244.jpg",

    title: "7523 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 119,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear245.jpg",
    imgHoverSrc: "/images/products/workwear246.jpg",

    title: "7151K",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 120,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear247.jpg",
    imgHoverSrc: "/images/products/workwear248.jpg",

    title: "7524 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 121,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear249.jpg",
    imgHoverSrc: "/images/products/workwear250.jpg",

    title: "7152 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 122,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear251.jpg",
    imgHoverSrc: "/images/products/workwear252.jpg",

    title: "7550 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 123,
    sizes: ["/"],

    imgSrc: "/images/products/workwear253.jpg",
    imgHoverSrc: "/images/products/workwear254.jpg",

    title: "7152K",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 124,
    sizes: ["/"],

    imgSrc: "/images/products/workwear255.jpg",
    imgHoverSrc: "/images/products/workwear256.jpg",

    title: "7551 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 125,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear257.jpg",
    imgHoverSrc: "/images/products/workwear258.jpg",

    title: "7153 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 126,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear259.jpg",
    imgHoverSrc: "/images/products/workwear260.jpg",

    title: "7552 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 127,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear263.jpg",
    imgHoverSrc: "/images/products/workwear264.jpg",

    title: "7553 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 128,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear265.jpg",
    imgHoverSrc: "/images/products/workwear266.jpg",

    title: "7154 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 129,
    sizes: ["/"],

    imgSrc: "/images/products/workwear269.jpg",
    imgHoverSrc: "/images/products/workwear270.jpg",

    title: "7154K",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 130,
    sizes: [42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear271.jpg",
    imgHoverSrc: "/images/products/workwear272.jpg",

    title: "7600 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 131,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear273.jpg",
    imgHoverSrc: "/images/products/workwear274.jpg",

    title: "7156 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 132,
    sizes: [42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear275.jpg",
    imgHoverSrc: "/images/products/workwear276.jpg",

    title: "7601 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 133,
    sizes: [42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear277.jpg",
    imgHoverSrc: "/images/products/workwear278.jpg",

    title: "7602 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 134,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear279.jpg",
    imgHoverSrc: "/images/products/workwear280.jpg",

    title: "7157 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 135,
    sizes: ["/"],

    imgSrc: "/images/products/workwear283.jpg",
    imgHoverSrc: "/images/products/workwear284.jpg",

    title: "7157K",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 136,
    sizes: [42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear285.jpg",
    imgHoverSrc: "/images/products/workwear286.jpg",

    title: "7604 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 137,
    sizes: [42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear287.jpg",
    imgHoverSrc: "/images/products/workwear288.jpg",

    title: "7606 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 138,
    sizes: [42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear289.jpg",
    imgHoverSrc: "/images/products/workwear290.jpg",

    title: "7607 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 139,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear291.jpg",
    imgHoverSrc: "/images/products/workwear292.jpg",

    title: "7610 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 140,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear293.jpg",
    imgHoverSrc: "/images/products/workwear294.jpg",

    title: "7611 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 141,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear295.jpg",
    imgHoverSrc: "/images/products/workwear296.jpg",

    title: "7660 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 142,
    sizes: [42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear297.jpg",
    imgHoverSrc: "/images/products/workwear298.jpg",

    title: "7700 // MOTION TEX PRO FX",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 143,
    sizes: [42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear299.jpg",
    imgHoverSrc: "/images/products/workwear230.jpg",

    title: "7701 // MOTION TEX PRO FX",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 144,
    sizes: [42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear301.jpg",
    imgHoverSrc: "/images/products/workwear302.jpg",

    title: "7702 // MOTION TEX PRO FX",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 145,
    sizes: [42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear304.jpg",
    imgHoverSrc: "/images/products/workwear305.jpg",

    title: "7705 // MOTION TEX PRO FX",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 146,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear306.jpg",
    imgHoverSrc: "/images/products/workwear307.jpg",

    title: "7710 // MOTION TEX PRO FX",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 147,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear308.jpg",
    imgHoverSrc: "/images/products/workwear309.jpg",

    title: "7711 // MOTION TEX PRO FX",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 148,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear310.jpg",
    imgHoverSrc: "/images/products/workwear311.jpg",

    title: "7712 // MOTION TEX PRO FX",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 149,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear312.jpg",
    imgHoverSrc: "/images/products/workwear313.jpg",

    title: "7715 // MOTION TEX PRO FX",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 150,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear314.jpg",
    imgHoverSrc: "/images/products/workwear315.jpg",

    title: "7720 // MOTION TEX PRO FX",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 151,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear316.jpg",
    imgHoverSrc: "/images/products/workwear317.jpg",

    title: "7725 // MOTION TEX PRO FX",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 152,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear318.jpg",
    imgHoverSrc: "/images/products/workwear319.jpg",

    title: "7750 // MOTION TEX PRO FX",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 153,
    sizes: [42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear322.jpg",
    imgHoverSrc: "/images/products/workwear323.jpg",

    title: "7752 // MOTION TEX PRO FX",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 154,
    sizes: [42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear324.jpg",
    imgHoverSrc: "/images/products/workwear325.jpg",

    title: "7755 // MOTION TEX PRO FX",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 155,
    sizes: [42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68],

    imgSrc: "/images/products/workwear324.jpg",
    imgHoverSrc: "/images/products/workwear325.jpg",

    title: "7755 // MOTION TEX PRO FX",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 156,
    sizes: ["/"],

    imgSrc: "/images/products/workwear326.jpg",
    imgHoverSrc: "/images/products/workwear326.jpg",

    title: "780P",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 157,
    sizes: ["/"],

    imgSrc: "/images/products/workwear327.jpg",
    imgHoverSrc: "/images/products/workwear327.jpg",

    title: "G115BP",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 158,
    sizes: [
      24, 25, 26, 27, 28, 29, 29, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62,
      64, 66, 68, 90, 90, 94, 98, 102, 106, 110,
    ],

    imgSrc: "/images/products/workwear328.jpg",
    imgHoverSrc: "/images/products/workwear329.jpg",

    title: "7612 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 159,
    sizes: ["/"],

    imgSrc: "/images/products/workwear330.jpg",
    imgHoverSrc: "/images/products/workwear330.jpg",

    title: "G130BP",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 160,
    sizes: ["/"],

    imgSrc: "/images/products/workwear331.jpg",
    imgHoverSrc: "/images/products/workwear331.jpg",

    title: "G145BP",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 161,
    sizes: ["/"],

    imgSrc: "/images/products/workwear332.jpg",
    imgHoverSrc: "/images/products/workwear333.jpg",

    title: "NHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 162,
    sizes: ["35/38", "39/42", "43/46", "47/50"],

    imgSrc: "/images/products/workwear334.jpg",
    imgHoverSrc: "/images/products/workwear335.jpg",

    title: "720",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 163,
    sizes: ["35/38", "39/42", "43/46", "47/50"],

    imgSrc: "/images/products/workwear336.jpg",
    imgHoverSrc: "/images/products/workwear337.jpg",

    title: "720",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 164,
    sizes: ["35/38", "39/42", "43/46", "47/50"],

    imgSrc: "/images/products/workwear338.jpg",
    imgHoverSrc: "/images/products/workwear339.jpg",

    title: "721",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 165,
    sizes: ["35/38", "39/42", "43/46", "47/50"],

    imgSrc: "/images/products/workwear340.jpg",
    imgHoverSrc: "/images/products/workwear341.jpg",

    title: "721",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 166,
    sizes: ["35/38", "39/42", "43/46", "47/50"],

    imgSrc: "/images/products/workwear342.jpg",
    imgHoverSrc: "/images/products/workwear343.jpg",

    title: "722",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 167,
    sizes: ["35/38", "39/42", "43/46", "47/50"],

    imgSrc: "/images/products/workwear344.jpg",
    imgHoverSrc: "/images/products/workwear345.jpg",

    title: "722",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 168,
    sizes: ["35/38", "39/42", "43/46", "47/50"],

    imgSrc: "/images/products/workwear346.jpg",
    imgHoverSrc: "/images/products/workwear347.jpg",

    title: "723",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 169,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear348.jpg",
    imgHoverSrc: "/images/products/workwear349.jpg",

    title: "7004 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },

  {
    id: 170,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear350.jpg",
    imgHoverSrc: "/images/products/workwear351.jpg",

    title: "7004 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },

  {
    id: 171,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear352.jpg",
    imgHoverSrc: "/images/products/workwear353.jpg",

    title: "7004 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },

  {
    id: 172,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear355.jpg",
    imgHoverSrc: "/images/products/workwear354.jpg",

    title: "7004 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },

  {
    id: 173,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear356.jpg",
    imgHoverSrc: "/images/products/workwear357.jpg",

    title: "7004 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },

  {
    id: 174,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear358.jpg",
    imgHoverSrc: "/images/products/workwear359.jpg",

    title: "7004 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },

  {
    id: 175,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear362.jpg",
    imgHoverSrc: "/images/products/workwear363.jpg",

    title: "7004 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },

  {
    id: 176,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear364.jpg",
    imgHoverSrc: "/images/products/workwear365.jpg",

    title: "7004 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },

  {
    id: 177,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],

    imgSrc: "/images/products/workwear366.jpg",
    imgHoverSrc: "/images/products/workwear367.jpg",

    title: "7004 // MOTION TEX LIGHT",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },

  {
    id: 178,
    sizes: ["One Size"],

    imgSrc: "/images/products/workwear368.jpg",
    imgHoverSrc: "/images/products/workwear368.jpg",

    title: "734",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },

  {
    id: 179,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear369.jpg",
    imgHoverSrc: "/images/products/workwear370.jpg",

    title: "7661 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
  {
    id: 180,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear371.jpg",
    imgHoverSrc: "/images/products/workwear372.jpg",

    title: "7042 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },

  {
    id: 181,
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],

    imgSrc: "/images/products/workwear373.jpg",
    imgHoverSrc: "/images/products/workwear374.jpg",

    title: "7042 // MOTION TEX PLUS",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Workweare",
    isAvailable: true,
  },
];

// * ================== Protective clothing ==================

export const protectiveclothing = [
  {
    id: 182,
    imgSrc: "/images/products/protectiveclothing1.jpg",
    imgHoverSrc: "/images/products/protectiveclothing2.jpg",
    title: "730",
    sizes: ["One Size"],
    subcategory: "Headgear",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 183,
    imgSrc: "/images/products/protectiveclothing3.jpg",
    imgHoverSrc: "/images/products/protectiveclothing4.jpg",
    title: "730",
    sizes: ["One Size"],
    subcategory: "Headgear",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 184,
    imgSrc: "/images/products/protectiveclothing5.jpg",
    imgHoverSrc: "/images/products/protectiveclothing6.jpg",
    title: "4500 // PP",
    sizes: ["M", "L", "XL", "2XL", "3XL"],
    subcategory: "Protective overalls",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 185,
    imgSrc: "/images/products/protectiveclothing7.jpg",
    imgHoverSrc: "/images/products/protectiveclothing8.jpg",
    title: "4510 // PP",
    sizes: ["L", "XL", "2XL", "3XL"],
    subcategory: "Protective overalls",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 186,
    imgSrc: "/images/products/protectiveclothing9.jpg",
    imgHoverSrc: "/images/products/protectiveclothing10.jpg",
    title: "4600 // PROTECT",
    sizes: ["M", "L", "XL", "2XL", "3XL"],
    subcategory: "Protective overalls",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 187,
    imgSrc: "/images/products/protectiveclothing11.jpg",
    imgHoverSrc: "/images/products/protectiveclothing12.jpg",
    title: "7008 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 188,
    imgSrc: "/images/products/protectiveclothing13.jpg",
    imgHoverSrc: "/images/products/protectiveclothing14.jpg",
    title: "7008 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 189,
    imgSrc: "/images/products/protectiveclothing15.jpg",
    imgHoverSrc: "/images/products/protectiveclothing16.jpg",
    title: "7009 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 190,
    imgSrc: "/images/products/protectiveclothing17.jpg",
    imgHoverSrc: "/images/products/protectiveclothing18.jpg",
    title: "7009 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 191,
    imgSrc: "/images/products/protectiveclothing21.jpg",
    imgHoverSrc: "/images/products/protectiveclothing22.jpg",
    title: "7013 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 192,
    imgSrc: "/images/products/protectiveclothing23.jpg",
    imgHoverSrc: "/images/products/protectiveclothing24.jpg",
    title: "7013 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 193,
    imgSrc: "/images/products/protectiveclothing25.jpg",
    imgHoverSrc: "/images/products/protectiveclothing26.jpg",
    title: "7014 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 194,
    imgSrc: "/images/products/protectiveclothing27.jpg",
    imgHoverSrc: "/images/products/protectiveclothing28.jpg",
    title: "7014 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 195,
    imgSrc: "/images/products/protectiveclothing29.jpg",
    imgHoverSrc: "/images/products/protectiveclothing30.jpg",
    title: "7110",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 196,
    imgSrc: "/images/products/protectiveclothing31.jpg",
    imgHoverSrc: "/images/products/protectiveclothing32.jpg",
    title: "7111",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 197,
    imgSrc: "/images/products/protectiveclothing33.jpg",
    imgHoverSrc: "/images/products/protectiveclothing34.jpg",
    title: "7111",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 198,
    imgSrc: "/images/products/protectiveclothing37.jpg",
    imgHoverSrc: "/images/products/protectiveclothing38.jpg",
    title: "7140 // MOTION TEX VIZ PLUS",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 199,
    imgSrc: "/images/products/protectiveclothing39.jpg",
    imgHoverSrc: "/images/products/protectiveclothing40.jpg",
    title: "7140K",
    sizes: ["/"],
    subcategory: "Headgear",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 200,
    imgSrc: "/images/products/protectiveclothing41.jpg",
    imgHoverSrc: "/images/products/protectiveclothing42.jpg",
    title: "7141 // MOTION TEX VIZ PLUS",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 201,
    imgSrc: "/images/products/protectiveclothing43.jpg",
    imgHoverSrc: "/images/products/protectiveclothing44.jpg",
    title: "7141K",
    sizes: ["/"],
    subcategory: "Headgear",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 202,
    imgSrc: "/images/products/protectiveclothing45.jpg",
    imgHoverSrc: "/images/products/protectiveclothing46.jpg",
    title: "7142 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 203,
    imgSrc: "/images/products/protectiveclothing47.jpg",
    imgHoverSrc: "/images/products/protectiveclothing48.jpg",
    title: "7143 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 204,
    imgSrc: "/images/products/protectiveclothing49.jpg",
    imgHoverSrc: "/images/products/protectiveclothing50.jpg",
    title: "7144 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 205,
    imgSrc: "/images/products/protectiveclothing51.jpg",
    imgHoverSrc: "/images/products/protectiveclothing52.jpg",
    title: "7145 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 206,
    imgSrc: "/images/products/protectiveclothing53.jpg",
    imgHoverSrc: "/images/products/protectiveclothing54.jpg",
    title: "7170 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 207,
    imgSrc: "/images/products/protectiveclothing55.jpg",
    imgHoverSrc: "/images/products/protectiveclothing56.jpg",
    title: "7170K",
    sizes: ["/"],
    subcategory: "Headgear",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 208,
    imgSrc: "/images/products/protectiveclothing57.jpg",
    imgHoverSrc: "/images/products/protectiveclothing58.jpg",
    title: "7171 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 209,
    imgSrc: "/images/products/protectiveclothing59.jpg",
    imgHoverSrc: "/images/products/protectiveclothing60.jpg",
    title: "7171K",
    sizes: ["/"],
    subcategory: "Headgear",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 210,
    imgSrc: "/images/products/protectiveclothing61.jpg",
    imgHoverSrc: "/images/products/protectiveclothing62.jpg",
    title: "1020609 // POLYSAFE BASIC II",
    sizes: ["M", "L", "XL", "2XL", "3XL", "4XL"],
    subcategory: "Protective overalls",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 211,
    imgSrc: "/images/products/protectiveclothing63.jpg",
    imgHoverSrc: "/images/products/protectiveclothing64.jpg",
    title: "1020609B // POLYSAFE BASIC II",
    sizes: ["M", "L", "XL", "2XL", "3XL"],
    subcategory: "Protective overalls",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 212,
    imgSrc: "/images/products/protectiveclothing65.jpg",
    imgHoverSrc: "/images/products/protectiveclothing66.jpg",
    title: "7175 // MOTION TEX VIZ PLUS",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 213,
    imgSrc: "/images/products/protectiveclothing68.jpg",
    imgHoverSrc: "/images/products/protectiveclothing69.jpg",
    title: "7175 // MOTION TEX VIZ PLUS",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 214,
    imgSrc: "/images/products/protectiveclothing70.jpg",
    imgHoverSrc: "/images/products/protectiveclothing71.jpg",
    title: "7172 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 215,
    imgSrc: "/images/products/protectiveclothing72.jpg",
    imgHoverSrc: "/images/products/protectiveclothing73.jpg",
    title: "7172 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 216,
    imgSrc: "/images/products/protectiveclothing74.jpg",
    imgHoverSrc: "/images/products/protectiveclothing75.jpg",
    title: "7172K",
    sizes: ["One Size"],
    subcategory: "Headgear",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 217,
    imgSrc: "/images/products/protectiveclothing76.jpg",
    imgHoverSrc: "/images/products/protectiveclothing77.jpg",
    title: "7172K",
    sizes: ["One Size"],
    subcategory: "Headgear",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 218,
    imgSrc: "/images/products/protectiveclothing78.jpg",
    imgHoverSrc: "/images/products/protectiveclothing79.jpg",
    title: "7573 // MOTION TEX VIZ",
    sizes: [
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "42",
      "44",
      "46",
      "48",
      "50",
      "52",
      "54",
      "56",
      "58",
      "60",
      "62",
      "64",
      "66",
      "68",
      "90",
      "94",
      "98",
      "102",
      "106",
      "110",
    ],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 219,
    imgSrc: "/images/products/protectiveclothing80.jpg",
    imgHoverSrc: "/images/products/protectiveclothing81.jpg",
    title: "7573 // MOTION TEX VIZ",
    sizes: [
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "42",
      "44",
      "46",
      "48",
      "50",
      "52",
      "54",
      "56",
      "58",
      "60",
      "62",
      "64",
      "66",
      "68",
      "90",
      "94",
      "98",
      "102",
      "106",
      "110",
    ],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 220,
    imgSrc: "/images/products/protectiveclothing82.jpg",
    imgHoverSrc: "/images/products/protectiveclothing83.jpg",
    title: "7572 // MOTION TEX VIZ",
    sizes: [
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "42",
      "44",
      "46",
      "48",
      "50",
      "52",
      "54",
      "56",
      "58",
      "60",
      "62",
      "64",
      "66",
      "68",
      "90",
      "94",
      "98",
      "102",
      "106",
      "110",
    ],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 221,
    imgSrc: "/images/products/protectiveclothing84.jpg",
    imgHoverSrc: "/images/products/protectiveclothing85.jpg",
    title: "7572 // MOTION TEX VIZ",
    sizes: [
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "42",
      "44",
      "46",
      "48",
      "50",
      "52",
      "54",
      "56",
      "58",
      "60",
      "62",
      "64",
      "66",
      "68",
      "90",
      "94",
      "98",
      "102",
      "106",
      "110",
    ],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 222,
    imgSrc: "/images/products/protectiveclothing86.jpg",
    imgHoverSrc: "/images/products/protectiveclothing87.jpg",
    title: "7571 // MOTION TEX VIZ",
    sizes: [
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "42",
      "44",
      "46",
      "48",
      "50",
      "52",
      "54",
      "56",
      "58",
      "60",
      "62",
      "64",
      "66",
      "68",
      "90",
      "94",
      "98",
      "102",
      "106",
      "110",
    ],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 223,
    imgSrc: "/images/products/protectiveclothing90.jpg",
    imgHoverSrc: "/images/products/protectiveclothing91.jpg",
    title: "7570 // MOTION TEX VIZ",
    sizes: [
      "42",
      "44",
      "46",
      "48",
      "50",
      "52",
      "54",
      "56",
      "58",
      "60",
      "62",
      "64",
      "66",
      "68",
    ],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 224,
    imgSrc: "/images/products/protectiveclothing92.jpg",
    imgHoverSrc: "/images/products/protectiveclothing93.jpg",
    title: "7570 // MOTION TEX VIZ",
    sizes: [
      "42",
      "44",
      "46",
      "48",
      "50",
      "52",
      "54",
      "56",
      "58",
      "60",
      "62",
      "64",
      "66",
      "68",
    ],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 225,
    imgSrc: "/images/products/protectiveclothing94.jpg",
    imgHoverSrc: "/images/products/protectiveclothing95.jpg",
    title: "7196 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 226,
    imgSrc: "/images/products/protectiveclothing96.jpg",
    imgHoverSrc: "/images/products/protectiveclothing97.jpg",
    title: "7196 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 227,
    imgSrc: "/images/products/protectiveclothing98.jpg",
    imgHoverSrc: "/images/products/protectiveclothing99.jpg",
    title: "7177 // MOTION TEX VIZ PLUS",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 228,
    imgSrc: "/images/products/protectiveclothing100.jpg",
    imgHoverSrc: "/images/products/protectiveclothing101.jpg",
    title: "7196 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 229,
    imgSrc: "/images/products/protectiveclothing104.jpg",
    imgHoverSrc: "/images/products/protectiveclothing105.jpg",
    title: "7196 // MOTION TEX VIZ",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 230,
    imgSrc: "/images/products/protectiveclothing106.jpg",
    imgHoverSrc: "/images/products/protectiveclothing107.jpg",
    title: "7177 // MOTION TEX VIZ PLUS",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 231,
    imgSrc: "/images/products/protectiveclothing108.jpg",
    imgHoverSrc: "/images/products/protectiveclothing109.jpg",
    title: "7177 // MOTION TEX VIZ PLUS",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 232,
    imgSrc: "/images/products/protectiveclothing110.jpg",
    imgHoverSrc: "/images/products/protectiveclothing111.jpg",
    title: "7179 // MOTION TEX VIZ PLUS",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 233,
    imgSrc: "/images/products/protectiveclothing112.jpg",
    imgHoverSrc: "/images/products/protectiveclothing113.jpg",
    title: "7179 // MOTION TEX VIZ PLUS",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 234,
    imgSrc: "/images/products/protectiveclothing114.jpg",
    imgHoverSrc: "/images/products/protectiveclothing115.jpg",
    title: "7118",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 235,
    imgSrc: "/images/products/protectiveclothing116.jpg",
    imgHoverSrc: "/images/products/protectiveclothing117.jpg",
    title: "7118",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 236,
    imgSrc: "/images/products/protectiveclothing118.jpg",
    imgHoverSrc: "/images/products/protectiveclothing119.jpg",
    title: "7118",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 237,
    imgSrc: "/images/products/protectiveclothing120.jpg",
    imgHoverSrc: "/images/products/protectiveclothing121.jpg",
    title: "7119",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 238,
    imgSrc: "/images/products/protectiveclothing122.jpg",
    imgHoverSrc: "/images/products/protectiveclothing123.jpg",
    title: "7119",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
  {
    id: 239,
    imgSrc: "/images/products/protectiveclothing124.jpg",
    imgHoverSrc: "/images/products/protectiveclothing125.jpg",
    title: "7119",
    sizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"],
    subcategory: "High-visibility clothing",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Protective clothing",
    isAvailable: true,
  },
];

// * ================== Hand / arm protection ==================

export const handarmprotection = [
  {
    id: 240,
    imgSrc: "/images/products/handarmprotection1.jpg",
    imgHoverSrc: "/images/products/handarmprotection1.jpg",
    title: "03400",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Nitrile gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 241,
    imgSrc: "/images/products/handarmprotection2.jpg",
    imgHoverSrc: "/images/products/handarmprotection2.jpg",
    title: "03400V",
    sizes: ["7", "8", "9", "10", "11"],
    subcategory: "Nitrile gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 242,
    imgSrc: "/images/products/handarmprotection3.jpg",
    imgHoverSrc: "/images/products/handarmprotection3.jpg",
    title: "03405",
    sizes: ["7", "8", "9", "10", "11"],
    subcategory: "Nitrile gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 243,
    imgSrc: "/images/products/handarmprotection4.jpg",
    imgHoverSrc: "/images/products/handarmprotection4.jpg",
    title: "03410",
    sizes: ["8", "9", "10", "11"],
    subcategory: "Nitrile gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 244,
    imgSrc: "/images/products/handarmprotection5.jpg",
    imgHoverSrc: "/images/products/handarmprotection5.jpg",
    title: "03420",
    sizes: ["8", "9", "10", "11"],
    subcategory: "Nitrile gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 245,
    imgSrc: "/images/products/handarmprotection6.jpg",
    imgHoverSrc: "/images/products/handarmprotection6.jpg",
    title: "03430",
    sizes: ["8", "9", "10", "11"],
    subcategory: "Nitrile gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 246,
    imgSrc: "/images/products/handarmprotection8.jpg",
    imgHoverSrc: "/images/products/handarmprotection8.jpg",
    title: "03435",
    sizes: ["10"],
    subcategory: "Nitrile gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 247,
    imgSrc: "/images/products/handarmprotection9.jpg",
    imgHoverSrc: "/images/products/handarmprotection9.jpg",
    title: "03440",
    sizes: ["8", "9", "10", "11"],
    subcategory: "Nitrile gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 248,
    imgSrc: "/images/products/handarmprotection10.jpg",
    imgHoverSrc: "/images/products/handarmprotection10.jpg",
    title: "1301C // SPLIT STAR 1",
    sizes: ["8", "9", "10", "11"],
    subcategory: "Leather gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 249,
    imgSrc: "/images/products/handarmprotection10.jpg",
    imgHoverSrc: "/images/products/handarmprotection10.jpg",
    title: "1301C // SPLIT STAR 1",
    sizes: ["8", "9", "10", "11"],
    subcategory: "Leather gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 250,
    imgSrc: "/images/products/handarmprotection11.jpg",
    imgHoverSrc: "/images/products/handarmprotection11.jpg",
    title: "1302 // SPLIT MASTER",
    sizes: ["8", "9", "10", "11", "12"],
    subcategory: "Leather gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 251,
    imgSrc: "/images/products/handarmprotection12.jpg",
    imgHoverSrc: "/images/products/handarmprotection12.jpg",
    title: "1302W // SPLIT STAR 2",
    sizes: ["10"],
    subcategory: "Leather gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 252,
    imgSrc: "/images/products/handarmprotection13.jpg",
    imgHoverSrc: "/images/products/handarmprotection13.jpg",
    title: "1304 // SPLIT STAR+",
    sizes: ["11"],
    subcategory: "Leather gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 253,
    imgSrc: "/images/products/handarmprotection14.jpg",
    imgHoverSrc: "/images/products/handarmprotection14.jpg",
    title: "1302W // SPLIT STAR 2",
    sizes: ["10"],
    subcategory: "Leather gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 254,
    imgSrc: "/images/products/handarmprotection15.jpg",
    imgHoverSrc: "/images/products/handarmprotection15.jpg",
    title: "1304 // SPLIT STAR+",
    sizes: ["11"],
    subcategory: "Leather gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 255,
    imgSrc: "/images/products/handarmprotection16.jpg",
    imgHoverSrc: "/images/products/handarmprotection16.jpg",
    title: "1312 // SPLIT MASTER PRO",
    sizes: ["11"],
    subcategory: "Leather gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 256,
    imgSrc: "/images/products/handarmprotection17.jpg",
    imgHoverSrc: "/images/products/handarmprotection17.jpg",
    title: "1315 // SPLIT STAR V",
    sizes: ["10"],
    subcategory: "Leather gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 257,
    imgSrc: "/images/products/handarmprotection18.jpg",
    imgHoverSrc: "/images/products/handarmprotection18.jpg",
    title: "1403B // APOLLO",
    sizes: ["8", "9", "10", "11", "12"],
    subcategory: "Leather gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 258,
    imgSrc: "/images/products/handarmprotection19.jpg",
    imgHoverSrc: "/images/products/handarmprotection19.jpg",
    title: "1403C // POSEIDON",
    sizes: ["10"],
    subcategory: "Leather gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 259,
    imgSrc: "/images/products/handarmprotection20.jpg",
    imgHoverSrc: "/images/products/handarmprotection20.jpg",
    title: "1403H TOP // ZEUS",
    sizes: ["8", "9", "10", "11", "12"],
    subcategory: "Leather gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 260,
    imgSrc: "/images/products/handarmprotection21.jpg",
    imgHoverSrc: "/images/products/handarmprotection21.jpg",
    title: "1403K // ARES",
    sizes: ["8", "9", "10", "11", "12"],
    subcategory: "Leather gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 261,
    imgSrc: "/images/products/handarmprotection22.jpg",
    imgHoverSrc: "/images/products/handarmprotection22.jpg",
    title: "1403KSP // HADES",
    sizes: ["8", "9", "10", "11", "12"],
    subcategory: "Leather gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 262,
    imgSrc: "/images/products/handarmprotection23.jpg",
    imgHoverSrc: "/images/products/handarmprotection23.jpg",
    title: "1403M // MERKUR",
    sizes: ["10"],
    subcategory: "Leather gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 263,
    imgSrc: "/images/products/handarmprotection24.jpg",
    imgHoverSrc: "/images/products/handarmprotection24.jpg",
    title: "1403NEO",
    sizes: ["10"],
    subcategory: "Leather gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },

  {
    id: 264,
    imgSrc: "/images/products/handarmprotection25.jpg",
    imgHoverSrc: "/images/products/handarmprotection25.jpg",
    title: "1403P // PLUTO",
    sizes: ["10"],
    subcategory: "Leather gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 265,
    imgSrc: "/images/products/handarmprotection26.jpg",
    imgHoverSrc: "/images/products/handarmprotection26.jpg",
    title: "1403W // WINTER WORKER",
    sizes: ["11"],
    subcategory: "Winter gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 266,
    imgSrc: "/images/products/handarmprotection27.jpg",
    imgHoverSrc: "/images/products/handarmprotection27.jpg",
    title: "1409 // DRIVER",
    sizes: ["8", "9", "10", "11", "12"],
    subcategory: "Leather gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 267,
    imgSrc: "/images/products/handarmprotection28.jpg",
    imgHoverSrc: "/images/products/handarmprotection28.jpg",
    title: "1409W // DRIVER WINTER",
    sizes: ["8", "9", "10", "11", "12"],
    subcategory: "Leather gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 268,
    imgSrc: "/images/products/handarmprotection29.jpg",
    imgHoverSrc: "/images/products/handarmprotection29.jpg",
    title: "160227",
    sizes: ["10"],
    subcategory: "PVC gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 269,
    imgSrc: "/images/products/handarmprotection30.jpg",
    imgHoverSrc: "/images/products/handarmprotection30.jpg",
    title: "160235",
    sizes: ["10"],
    subcategory: "PVC gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 270,
    imgSrc: "/images/products/handarmprotection31.jpg",
    imgHoverSrc: "/images/products/handarmprotection31.jpg",
    title: "160240",
    sizes: ["10"],
    subcategory: "PVC gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 271,
    imgSrc: "/images/products/handarmprotection32.jpg",
    imgHoverSrc: "/images/products/handarmprotection32.jpg",
    title: "1603 // GRIP",
    sizes: ["8", "9", "10", "11"],
    subcategory: "Knitted gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 272,
    imgSrc: "/images/products/handarmprotection33.jpg",
    imgHoverSrc: "/images/products/handarmprotection33.jpg",
    title: "1603W // WINTER BLOCKER",
    sizes: ["8", "9", "10", "11"],
    subcategory: "Winter gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 273,
    imgSrc: "/images/products/handarmprotection34.jpg",
    imgHoverSrc: "/images/products/handarmprotection34.jpg",
    title: "20035 // VULCANUS 35",
    sizes: ["9", "10", "11"],
    subcategory: "Welding gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 274,
    imgSrc: "/images/products/handarmprotection35.jpg",
    imgHoverSrc: "/images/products/handarmprotection35.jpg",
    title: "160435",
    sizes: ["10"],
    subcategory: "Chemical protective gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 275,
    imgSrc: "/images/products/handarmprotection36.jpg",
    imgHoverSrc: "/images/products/handarmprotection36.jpg",
    title: "1606W // SNOW FIGHTER",
    sizes: ["8", "9", "10", "11"],
    subcategory: "Winter gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 276,
    imgSrc: "/images/products/handarmprotection37.jpg",
    imgHoverSrc: "/images/products/handarmprotection37.jpg",
    title: "1606WV // SNOW FIGHTER+",
    sizes: ["8", "9", "10", "11"],
    subcategory: "Winter gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 277,
    imgSrc: "/images/products/handarmprotection38.jpg",
    imgHoverSrc: "/images/products/handarmprotection38.jpg",
    title: "1611 // BLUE POWER GRIP",
    sizes: ["7", "8", "9", "10", "11"],
    subcategory: "Chemical protective gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 278,
    imgSrc: "/images/products/handarmprotection39.jpg",
    imgHoverSrc: "/images/products/handarmprotection39.jpg",
    title: "20028 // VULCANUS 28",
    sizes: ["10"],
    subcategory: "Leather gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 279,
    imgSrc: "/images/products/handarmprotection40.jpg",
    imgHoverSrc: "/images/products/handarmprotection40.jpg",
    title: "5310",
    sizes: ["7"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 280,
    imgSrc: "/images/products/handarmprotection41.jpg",
    imgHoverSrc: "/images/products/handarmprotection41.jpg",
    title: "5311",
    sizes: ["8"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 281,
    imgSrc: "/images/products/handarmprotection42.jpg",
    imgHoverSrc: "/images/products/handarmprotection42.jpg",
    title: "5312",
    sizes: ["9"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 282,
    imgSrc: "/images/products/handarmprotection43.jpg",
    imgHoverSrc: "/images/products/handarmprotection43.jpg",
    title: "5312",
    sizes: ["9"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 283,
    imgSrc: "/images/products/handarmprotection44.jpg",
    imgHoverSrc: "/images/products/handarmprotection44.jpg",
    title: "5313",
    sizes: ["10"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 284,
    imgSrc: "/images/products/handarmprotection45.jpg",
    imgHoverSrc: "/images/products/handarmprotection45.jpg",
    title: "5314",
    sizes: ["11"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 285,
    imgSrc: "/images/products/handarmprotection46.jpg",
    imgHoverSrc: "/images/products/handarmprotection46.jpg",
    title: "5315",
    sizes: ["11"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 286,
    imgSrc: "/images/products/handarmprotection47.jpg",
    imgHoverSrc: "/images/products/handarmprotection47.jpg",
    title: "5316",
    sizes: ["13"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 287,
    imgSrc: "/images/products/handarmprotection48.jpg",
    imgHoverSrc: "/images/products/handarmprotection48.jpg",
    title: "5320",
    sizes: ["7"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 288,
    imgSrc: "/images/products/handarmprotection49.jpg",
    imgHoverSrc: "/images/products/handarmprotection49.jpg",
    title: "5321",
    sizes: ["8"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 289,
    imgSrc: "/images/products/handarmprotection50.jpg",
    imgHoverSrc: "/images/products/handarmprotection50.jpg",
    title: "5322",
    sizes: ["9"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 290,
    imgSrc: "/images/products/handarmprotection50.jpg",
    imgHoverSrc: "/images/products/handarmprotection50.jpg",
    title: "5323",
    sizes: ["10"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 291,
    imgSrc: "/images/products/handarmprotection51.jpg",
    imgHoverSrc: "/images/products/handarmprotection51.jpg",
    title: "20535 // SAFE",
    sizes: ["9", "10", "11"],
    subcategory: "Welding gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 292,
    imgSrc: "/images/products/handarmprotection52.jpg",
    imgHoverSrc: "/images/products/handarmprotection52.jpg",
    title: "5324",
    sizes: ["11"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 293,
    imgSrc: "/images/products/handarmprotection53.jpg",
    imgHoverSrc: "/images/products/handarmprotection53.jpg",
    title: "20535PK // SAFE PRO",
    sizes: ["10"],
    subcategory: "Welding gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 294,
    imgSrc: "/images/products/handarmprotection54.jpg",
    imgHoverSrc: "/images/products/handarmprotection54.jpg",
    title: "5325",
    sizes: ["12"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 295,
    imgSrc: "/images/products/handarmprotection55.jpg",
    imgHoverSrc: "/images/products/handarmprotection55.jpg",
    title: "5400",
    sizes: ["10"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 296,
    imgSrc: "/images/products/handarmprotection56.jpg",
    imgHoverSrc: "/images/products/handarmprotection56.jpg",
    title: "5410",
    sizes: ["/"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 297,
    imgSrc: "/images/products/handarmprotection57.jpg",
    imgHoverSrc: "/images/products/handarmprotection57.jpg",
    title: "6100",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Knitted gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 298,
    imgSrc: "/images/products/handarmprotection58.jpg",
    imgHoverSrc: "/images/products/handarmprotection58.jpg",
    title: "6100ND",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Knitted gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 299,
    imgSrc: "/images/products/handarmprotection59.jpg",
    imgHoverSrc: "/images/products/handarmprotection59.jpg",
    title: "6101",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Knitted gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 300,
    imgSrc: "/images/products/handarmprotection60.jpg",
    imgHoverSrc: "/images/products/handarmprotection60.jpg",
    title: "6200",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Knitted gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 301,
    imgSrc: "/images/products/handarmprotection62.jpg",
    imgHoverSrc: "/images/products/handarmprotection62.jpg",
    title: "6205",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Knitted gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 302,
    imgSrc: "/images/products/handarmprotection63.jpg",
    imgHoverSrc: "/images/products/handarmprotection63.jpg",
    title: "3200 // ARGON",
    sizes: ["10"],
    subcategory: "Welding gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 303,
    imgSrc: "/images/products/handarmprotection64.jpg",
    imgHoverSrc: "/images/products/handarmprotection64.jpg",
    title: "6210",
    sizes: ["6", "7", "8", "9", "10"],
    subcategory: "Knitted gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 304,
    imgSrc: "/images/products/handarmprotection65.jpg",
    imgHoverSrc: "/images/products/handarmprotection65.jpg",
    title: "3201 // ARGON",
    sizes: ["11"],
    subcategory: "Welding gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 305,
    imgSrc: "/images/products/handarmprotection66.jpg",
    imgHoverSrc: "/images/products/handarmprotection66.jpg",
    title: "6215",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Knitted gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 306,
    imgSrc: "/images/products/handarmprotection67.jpg",
    imgHoverSrc: "/images/products/handarmprotection67.jpg",
    title: "3202 // ARGON",
    sizes: ["9"],
    subcategory: "Welding gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 307,
    imgSrc: "/images/products/handarmprotection68.jpg",
    imgHoverSrc: "/images/products/handarmprotection68.jpg",
    title: "6220",
    sizes: ["6", "7", "8", "9", "10"],
    subcategory: "Knitted gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 308,
    imgSrc: "/images/products/handarmprotection69.jpg",
    imgHoverSrc: "/images/products/handarmprotection69.jpg",
    title: "3203 // ARGON",
    sizes: ["10"],
    subcategory: "Welding gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 309,
    imgSrc: "/images/products/handarmprotection70.jpg",
    imgHoverSrc: "/images/products/handarmprotection70.jpg",
    title: "6230",
    sizes: ["5", "6", "7", "8", "9", "10", "11"],
    subcategory: "Antistatic gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 310,
    imgSrc: "/images/products/handarmprotection71.jpg",
    imgHoverSrc: "/images/products/handarmprotection71.jpg",
    title: "3220 // YELLOW CLEANER",
    sizes: ["7", "8", "9", "10"],
    subcategory: "Chemical protective gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 311,
    imgSrc: "/images/products/handarmprotection72.jpg",
    imgHoverSrc: "/images/products/handarmprotection72.jpg",
    title: "6230T",
    sizes: ["7", "8", "9", "10", "11"],
    subcategory: "Antistatic gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 312,
    imgSrc: "/images/products/handarmprotection73.jpg",
    imgHoverSrc: "/images/products/handarmprotection73.jpg",
    title: "3221 // BLUE CLEANER",
    sizes: ["7", "8", "9", "10"],
    subcategory: "Chemical protective gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 313,
    imgSrc: "/images/products/handarmprotection74.jpg",
    imgHoverSrc: "/images/products/handarmprotection74.jpg",
    title: "6230UC",
    sizes: ["7", "8", "9", "10", "11"],
    subcategory: "Antistatic gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 314,
    imgSrc: "/images/products/handarmprotection76.jpg",
    imgHoverSrc: "/images/products/handarmprotection76.jpg",
    title: "6750",
    sizes: ["8", "10"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 315,
    imgSrc: "/images/products/handarmprotection77.jpg",
    imgHoverSrc: "/images/products/handarmprotection77.jpg",
    title: "6240 // SKIN",
    sizes: ["8", "10"],
    subcategory: "Knitted gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 316,
    imgSrc: "/images/products/handarmprotection78.jpg",
    imgHoverSrc: "/images/products/handarmprotection78.jpg",
    title: "3400X",
    sizes: ["7", "8", "9", "10", "11"],
    subcategory: "Nitrile gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 317,
    imgSrc: "/images/products/handarmprotection79.jpg",
    imgHoverSrc: "/images/products/handarmprotection79.jpg",
    title: "6300",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 318,
    imgSrc: "/images/products/handarmprotection80.jpg",
    imgHoverSrc: "/images/products/handarmprotection80.jpg",
    title: "6305",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 319,
    imgSrc: "/images/products/handarmprotection81.jpg",
    imgHoverSrc: "/images/products/handarmprotection81.jpg",
    title: "6315",
    sizes: ["5", "6", "7", "8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 320,
    imgSrc: "/images/products/handarmprotection82.jpg",
    imgHoverSrc: "/images/products/handarmprotection82.jpg",
    title: "3410P",
    sizes: ["10"],
    subcategory: "Nitrile gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 321,
    imgSrc: "/images/products/handarmprotection83.jpg",
    imgHoverSrc: "/images/products/handarmprotection83.jpg",
    title: "6790",
    sizes: ["/"],
    subcategory: "Arm protection",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 322,
    imgSrc: "/images/products/handarmprotection84.jpg",
    imgHoverSrc: "/images/products/handarmprotection84.jpg",
    title: "3420P",
    sizes: ["10"],
    subcategory: "Nitrile gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 323,
    imgSrc: "/images/products/handarmprotection85.jpg",
    imgHoverSrc: "/images/products/handarmprotection85.jpg",
    title: "3430P",
    sizes: ["10"],
    subcategory: "Nitrile gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 324,
    imgSrc: "/images/products/handarmprotection86.jpg",
    imgHoverSrc: "/images/products/handarmprotection86.jpg",
    title: "3440P",
    sizes: ["10", "11"],
    subcategory: "Nitrile gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 325,
    imgSrc: "/images/products/handarmprotection87.jpg",
    imgHoverSrc: "/images/products/handarmprotection87.jpg",
    title: "3450 // GREEN BARRIER",
    sizes: ["7", "8", "9", "10", "11"],
    subcategory: "Chemical protective gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 326,
    imgSrc: "/images/products/handarmprotection88.jpg",
    imgHoverSrc: "/images/products/handarmprotection88.jpg",
    title: "3451 // GREEN BARRIER FLEX",
    sizes: ["7", "8", "9", "10", "11"],
    subcategory: "Chemical protective gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 327,
    imgSrc: "/images/products/handarmprotection89.jpg",
    imgHoverSrc: "/images/products/handarmprotection89.jpg",
    title: "3455 // GREEN BARRIER GRIP",
    sizes: ["8", "9", "10", "11"],
    subcategory: "Chemical protective gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 328,
    imgSrc: "/images/products/handarmprotection90.jpg",
    imgHoverSrc: "/images/products/handarmprotection90.jpg",
    title: "3460 // BLACK BARRIER",
    sizes: ["7", "8", "9", "10", "11"],
    subcategory: "Chemical protective gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 329,
    imgSrc: "/images/products/handarmprotection91.jpg",
    imgHoverSrc: "/images/products/handarmprotection91.jpg",
    title: "3470 // DUAL BARRIER",
    sizes: ["7", "8", "9", "10", "11"],
    subcategory: "Chemical protective gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 330,
    imgSrc: "/images/products/handarmprotection92.jpg",
    imgHoverSrc: "/images/products/handarmprotection92.jpg",
    title: "3500",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Knitted gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 331,
    imgSrc: "/images/products/handarmprotection93.jpg",
    imgHoverSrc: "/images/products/handarmprotection93.jpg",
    title: "3510",
    sizes: ["7", "8", "9", "10", "11"],
    subcategory: "Knitted gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 332,
    imgSrc: "/images/products/handarmprotection94.jpg",
    imgHoverSrc: "/images/products/handarmprotection94.jpg",
    title: "3515 // NEON",
    sizes: ["7", "8", "9", "10", "11", "12"],
    subcategory: "Knitted gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 333,
    imgSrc: "/images/products/handarmprotection95.jpg",
    imgHoverSrc: "/images/products/handarmprotection95.jpg",
    title: "3520 // NYLOTEX",
    sizes: ["7", "8", "9", "10", "11", "12"],
    subcategory: "Knitted gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 334,
    imgSrc: "/images/products/handarmprotection96.jpg",
    imgHoverSrc: "/images/products/handarmprotection96.jpg",
    title: "3521",
    sizes: ["7", "8", "9", "10", "11", "12"],
    subcategory: "Knitted gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 335,
    imgSrc: "/images/products/handarmprotection97.jpg",
    imgHoverSrc: "/images/products/handarmprotection97.jpg",
    title: "3550 // OIL GRIP",
    sizes: ["7", "8", "9", "10", "11"],
    subcategory: "Assembly gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 336,
    imgSrc: "/images/products/handarmprotection98.jpg",
    imgHoverSrc: "/images/products/handarmprotection98.jpg",
    title: "3560 // SOFT GRIP",
    sizes: ["7", "8", "9", "10", "11"],
    subcategory: "Assembly gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 337,
    imgSrc: "/images/products/handarmprotection99.jpg",
    imgHoverSrc: "/images/products/handarmprotection99.jpg",
    title: "3560W // SOFT GRIP W",
    sizes: ["8", "9", "10", "11"],
    subcategory: "Winter gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 338,
    imgSrc: "/images/products/handarmprotection100.jpg",
    imgHoverSrc: "/images/products/handarmprotection100.jpg",
    title: "5010",
    sizes: ["10"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 339,
    imgSrc: "/images/products/handarmprotection101.jpg",
    imgHoverSrc: "/images/products/handarmprotection101.jpg",
    title: "5011",
    sizes: ["8"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 340,
    imgSrc: "/images/products/handarmprotection102.jpg",
    imgHoverSrc: "/images/products/handarmprotection102.jpg",
    title: "5102",
    sizes: ["10"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 341,
    imgSrc: "/images/products/handarmprotection103.jpg",
    imgHoverSrc: "/images/products/handarmprotection103.jpg",
    title: "6330",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 342,
    imgSrc: "/images/products/handarmprotection104.jpg",
    imgHoverSrc: "/images/products/handarmprotection104.jpg",
    title: "6340 // SKIN FLEX CUT3",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 343,
    imgSrc: "/images/products/handarmprotection105.jpg",
    imgHoverSrc: "/images/products/handarmprotection105.jpg",
    title: "6791",
    sizes: ["/"],
    subcategory: "Arm protection",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 344,
    imgSrc: "/images/products/handarmprotection106.jpg",
    imgHoverSrc: "/images/products/handarmprotection106.jpg",
    title: "6350 // CUT3",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 345,
    imgSrc: "/images/products/handarmprotection107.jpg",
    imgHoverSrc: "/images/products/handarmprotection107.jpg",
    title: "6795",
    sizes: ["/"],
    subcategory: "Arm protection",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 346,
    imgSrc: "/images/products/handarmprotection108.jpg",
    imgHoverSrc: "/images/products/handarmprotection108.jpg",
    title: "6355 // CUT3 NF",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 347,
    imgSrc: "/images/products/handarmprotection109.jpg",
    imgHoverSrc: "/images/products/handarmprotection109.jpg",
    title: "6796",
    sizes: ["/"],
    subcategory: "Arm protection",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 348,
    imgSrc: "/images/products/handarmprotection110.jpg",
    imgHoverSrc: "/images/products/handarmprotection110.jpg",
    title: "6360 // OIL GRIP CUT",
    sizes: ["7", "8", "9", "10", "11", "12"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 349,
    imgSrc: "/images/products/handarmprotection111.jpg",
    imgHoverSrc: "/images/products/handarmprotection111.jpg",
    title: "6806 // CUT F",
    sizes: ["7", "8", "9", "10", "11", "12"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 350,
    imgSrc: "/images/products/handarmprotection112.jpg",
    imgHoverSrc: "/images/products/handarmprotection112.jpg",
    title: "6605 // CUT5",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 351,
    imgSrc: "/images/products/handarmprotection113.jpg",
    imgHoverSrc: "/images/products/handarmprotection113.jpg",
    title: "6835 // CUT F PRO",
    sizes: ["6", "7", "8", "9", "10", "11", "12"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 352,
    imgSrc: "/images/products/handarmprotection114.jpg",
    imgHoverSrc: "/images/products/handarmprotection114.jpg",
    title: "6640 // SKIN FLEX CUT5",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 353,
    imgSrc: "/images/products/handarmprotection115.jpg",
    imgHoverSrc: "/images/products/handarmprotection115.jpg",
    title: "6655 // STEEL",
    sizes: ["9", "10", "11"],
    subcategory: "Puncture protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 354,
    imgSrc: "/images/products/handarmprotection116.jpg",
    imgHoverSrc: "/images/products/handarmprotection116.jpg",
    title: "6705",
    sizes: ["5", "6", "7", "8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 355,
    imgSrc: "/images/products/handarmprotection117.jpg",
    imgHoverSrc: "/images/products/handarmprotection117.jpg",
    title: "6706",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 356,
    imgSrc: "/images/products/handarmprotection118.jpg",
    imgHoverSrc: "/images/products/handarmprotection118.jpg",
    title: "6710",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 357,
    imgSrc: "/images/products/handarmprotection119.jpg",
    imgHoverSrc: "/images/products/handarmprotection119.jpg",
    title: "6720",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 358,
    imgSrc: "/images/products/handarmprotection120.jpg",
    imgHoverSrc: "/images/products/handarmprotection120.jpg",
    title: "6720W // WINTER CUT",
    sizes: ["8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 359,
    imgSrc: "/images/products/handarmprotection121.jpg",
    imgHoverSrc: "/images/products/handarmprotection121.jpg",
    title: "8300 // SUPREME NITRILE",
    sizes: ["S", "M", "L", "XL", "2XL"],
    subcategory: "Disposable gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 360,
    imgSrc: "/images/products/handarmprotection122.jpg",
    imgHoverSrc: "/images/products/handarmprotection122.jpg",
    title: "6726",
    sizes: ["9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 361,
    imgSrc: "/images/products/handarmprotection123.jpg",
    imgHoverSrc: "/images/products/handarmprotection123.jpg",
    title: "6730",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 362,
    imgSrc: "/images/products/handarmprotection124.jpg",
    imgHoverSrc: "/images/products/handarmprotection124.jpg",
    title: "6735",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 363,
    imgSrc: "/images/products/handarmprotection125.jpg",
    imgHoverSrc: "/images/products/handarmprotection125.jpg",
    title: "5103",
    sizes: ["8"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 364,
    imgSrc: "/images/products/handarmprotection126.jpg",
    imgHoverSrc: "/images/products/handarmprotection126.jpg",
    title: "5202",
    sizes: ["10"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 365,
    imgSrc: "/images/products/handarmprotection127.jpg",
    imgHoverSrc: "/images/products/handarmprotection127.jpg",
    title: "5203",
    sizes: ["8"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 366,
    imgSrc: "/images/products/handarmprotection128.jpg",
    imgHoverSrc: "/images/products/handarmprotection128.jpg",
    title: "5210",
    sizes: ["10"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 367,
    imgSrc: "/images/products/handarmprotection129.jpg",
    imgHoverSrc: "/images/products/handarmprotection129.jpg",
    title: "5211",
    sizes: ["8"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 368,
    imgSrc: "/images/products/handarmprotection130.jpg",
    imgHoverSrc: "/images/products/handarmprotection130.jpg",
    title: "5309",
    sizes: ["6"],
    subcategory: "Cotton gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 369,
    imgSrc: "/images/products/handarmprotection131.jpg",
    imgHoverSrc: "/images/products/handarmprotection131.jpg",
    title: "8330 // TOUGH GRIP N",
    sizes: ["S", "M", "L", "XL", "2XL"],
    subcategory: "Disposable gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 370,
    imgSrc: "/images/products/handarmprotection132.jpg",
    imgHoverSrc: "/images/products/handarmprotection132.jpg",
    title: "8335 // TOUGH GRIP N",
    sizes: ["S", "M", "L", "XL", "2XL"],
    subcategory: "Disposable gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 371,
    imgSrc: "/images/products/handarmprotection133.jpg",
    imgHoverSrc: "/images/products/handarmprotection133.jpg",
    title: "8340 // TOUGH GRIP N 300",
    sizes: ["S", "M", "L", "XL", "2XL"],
    subcategory: "Disposable gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 372,
    imgSrc: "/images/products/handarmprotection134.jpg",
    imgHoverSrc: "/images/products/handarmprotection134.jpg",
    title: "8700 // SKIN FLEX 3D",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Assembly gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 373,
    imgSrc: "/images/products/handarmprotection135.jpg",
    imgHoverSrc: "/images/products/handarmprotection135.jpg",
    title: "8710 // SKIN FLEX V",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Assembly gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 374,
    imgSrc: "/images/products/handarmprotection136.jpg",
    imgHoverSrc: "/images/products/handarmprotection136.jpg",
    title: "8711 // SKIN FLEX 3D",
    sizes: ["7", "8", "9", "10", "11"],
    subcategory: "Assembly gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 375,
    imgSrc: "/images/products/handarmprotection137.jpg",
    imgHoverSrc: "/images/products/handarmprotection137.jpg",
    title: "8720 // SKIN CLEAN",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Assembly gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 376,
    imgSrc: "/images/products/handarmprotection138.jpg",
    imgHoverSrc: "/images/products/handarmprotection138.jpg",
    title: "8800 // FLEXIBLE FIT",
    sizes: ["6", "7", "8", "9", "10", "11", "12"],
    subcategory: "Assembly gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 377,
    imgSrc: "/images/products/handarmprotection139.jpg",
    imgHoverSrc: "/images/products/handarmprotection139.jpg",
    title: "8805 // FLEXIBLE FIT+",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Assembly gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 378,
    imgSrc: "/images/products/handarmprotection140.jpg",
    imgHoverSrc: "/images/products/handarmprotection140.jpg",
    title: "8810 // FLEXIBLE FIT K",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Assembly gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 379,
    imgSrc: "/images/products/handarmprotection141.jpg",
    imgHoverSrc: "/images/products/handarmprotection141.jpg",
    title: "8815 // FLEXIBLE FIT MF",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Assembly gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 380,
    imgSrc: "/images/products/handarmprotection142.jpg",
    imgHoverSrc: "/images/products/handarmprotection142.jpg",
    title: "8900 // TROPIC",
    sizes: ["7", "8", "9", "10", "11"],
    subcategory: "Mechanic's gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 381,
    imgSrc: "/images/products/handarmprotection143.jpg",
    imgHoverSrc: "/images/products/handarmprotection143.jpg",
    title: "8905 // DEXTER 1",
    sizes: ["7", "8", "9", "10", "11"],
    subcategory: "Mechanic's gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 382,
    imgSrc: "/images/products/handarmprotection144.jpg",
    imgHoverSrc: "/images/products/handarmprotection144.jpg",
    title: "8905W // DEXTER WINTER",
    sizes: ["7", "8", "9", "10", "11", "12"],
    subcategory: "Mechanic's gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 383,
    imgSrc: "/images/products/handarmprotection145.jpg",
    imgHoverSrc: "/images/products/handarmprotection145.jpg",
    title: "8910 // DEXTER 2",
    sizes: ["8", "9", "10", "11"],
    subcategory: "Mechanic's gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 384,
    imgSrc: "/images/products/handarmprotection146.jpg",
    imgHoverSrc: "/images/products/handarmprotection146.jpg",
    title: "8200 // IDEAL CARE",
    sizes: ["XS", "S", "M", "L", "XL"],
    subcategory: "Disposable gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 385,
    imgSrc: "/images/products/handarmprotection147.jpg",
    imgHoverSrc: "/images/products/handarmprotection147.jpg",
    title: "8201 // IDEAL CARE",
    sizes: ["S", "M", "L", "XL"],
    subcategory: "Disposable gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 386,
    imgSrc: "/images/products/handarmprotection148.jpg",
    imgHoverSrc: "/images/products/handarmprotection148.jpg",
    title: "6102 // SMART SWIPE",
    sizes: ["6", "7", "8", "9", "10", "11"],
    subcategory: "Knitted gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 387,
    imgSrc: "/images/products/handarmprotection149.jpg",
    imgHoverSrc: "/images/products/handarmprotection149.jpg",
    title: "3525 // NYLOTEX SOFT HV",
    sizes: ["7", "8", "9", "10", "11", "12"],
    subcategory: "Assembly gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 388,
    imgSrc: "/images/products/handarmprotection150.jpg",
    imgHoverSrc: "/images/products/handarmprotection150.jpg",
    title: "20029 // CUT F",
    sizes: ["8", "9", "10", "11", "12"],
    subcategory: "Assembly gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 389,
    imgSrc: "/images/products/handarmprotection151.jpg",
    imgHoverSrc: "/images/products/handarmprotection151.jpg",
    title: "1104 // UNIVERSAL",
    sizes: ["10"],
    subcategory: "Leather gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 390,
    imgSrc: "/images/products/handarmprotection152.jpg",
    imgHoverSrc: "/images/products/handarmprotection152.jpg",
    title: "8065 // HIGH RISK NITRILE",
    sizes: ["XS", "S", "M", "L", "XL", "2XL"],
    subcategory: "Disposable gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 391,
    imgSrc: "/images/products/handarmprotection153.jpg",
    imgHoverSrc: "/images/products/handarmprotection153.jpg",
    title: "8700ESD3D",
    sizes: ["5", "6", "7", "8", "9", "10", "11", "12"],
    subcategory: "Assembly gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 392,
    imgSrc: "/images/products/handarmprotection154.jpg",
    imgHoverSrc: "/images/products/handarmprotection154.jpg",
    title: "20540 // SAFE PRO",
    sizes: ["9", "10", "11", "12"],
    subcategory: "Welding gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 393,
    imgSrc: "/images/products/handarmprotection155.jpg",
    imgHoverSrc: "/images/products/handarmprotection155.jpg",
    title: "20440 // WELDER PRO",
    sizes: ["9", "10", "11", "12"],
    subcategory: "Welding gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 394,
    imgSrc: "/images/products/handarmprotection156.jpg",
    imgHoverSrc: "/images/products/handarmprotection156.jpg",
    title: "6845 // ESD 3D CUT F",
    sizes: ["6", "7", "8", "9", "10", "11", "12"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 395,
    imgSrc: "/images/products/handarmprotection157.jpg",
    imgHoverSrc: "/images/products/handarmprotection157.jpg",
    title: "6346 // ESD 3D CUT D",
    sizes: ["6", "7", "8", "9", "10", "11", "12"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 396,
    imgSrc: "/images/products/handarmprotection158.jpg",
    imgHoverSrc: "/images/products/handarmprotection158.jpg",
    title: "6345 // ESD 3D CUT C",
    sizes: ["6", "7", "8", "9", "10", "11", "12"],
    subcategory: "Cut protection gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 397,
    imgSrc: "/images/products/handarmprotection159.jpg",
    imgHoverSrc: "/images/products/handarmprotection159.jpg",
    title: "8331 // TOUGH GRIP LIGHT",
    sizes: ["S", "M", "L", "XL", "2XL"],
    subcategory: "Disposable gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
  {
    id: 398,
    imgSrc: "/images/products/handarmprotection160.jpg",
    imgHoverSrc: "/images/products/handarmprotection160.jpg",
    title: "3456 // GREEN BARRIER CUT",
    sizes: ["8", "9", "10", "11", "12"],
    subcategory: "Chemical protective gloves",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Hand / arm protection",
    isAvailable: true,
  },
];

// * ================== Foot protection ==================

export const footprotection = [
  {
    id: 399,
    imgSrc: "/images/products/footprotection1.jpg",
    imgHoverSrc: "/images/products/footprotection1.jpg",
    title: "700C // COMFORT STEP",
    sizes: ["35", "45", "46", "47", "48", "49", "50"],
    subcategory: "Accessories",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 400,
    imgSrc: "/images/products/footprotection2.jpg",
    imgHoverSrc: "/images/products/footprotection2.jpg",
    title: "700H // ERGO STEP HIGH",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],

    subcategory: "Accessories",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 401,
    imgSrc: "/images/products/footprotection3.jpg",
    imgHoverSrc: "/images/products/footprotection3.jpg",
    title: "700L // ERGO STEP LOW",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Accessories",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 402,
    imgSrc: "/images/products/footprotection4.jpg",
    imgHoverSrc: "/images/products/footprotection4.jpg",
    title: "700M // ERGO STEP MEDIUM",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Accessories",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 403,
    imgSrc: "/images/products/footprotection5.jpg",
    imgHoverSrc: "/images/products/footprotection5.jpg",
    title: "7312 // EASY STEP SA",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Sandals",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 404,
    imgSrc: "/images/products/footprotection6.jpg",
    imgHoverSrc: "/images/products/footprotection7.jpg",
    title: "7200 // STEP",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 405,
    imgSrc: "/images/products/footprotection8.jpg",
    imgHoverSrc: "/images/products/footprotection9.jpg",
    title: "7313 // EASY STEP P",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 406,
    imgSrc: "/images/products/footprotection10.jpg",
    imgHoverSrc: "/images/products/footprotection11.jpg",
    title: "7200MF // STEP MF",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 407,
    imgSrc: "/images/products/footprotection12.jpg",
    imgHoverSrc: "/images/products/footprotection13.jpg",
    title: "7410 // PRO STEP",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 408,
    imgSrc: "/images/products/footprotection14.jpg",
    imgHoverSrc: "/images/products/footprotection15.jpg",
    title: "7411 // PRO STEP MID",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 409,
    imgSrc: "/images/products/footprotection16.jpg",
    imgHoverSrc: "/images/products/footprotection17.jpg",
    title: "7201 // STEP MID",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 410,
    imgSrc: "/images/products/footprotection18.jpg",
    imgHoverSrc: "/images/products/footprotection19.jpg",
    title: "7201 MF // STEP MID MF",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 411,
    imgSrc: "/images/products/footprotection20.jpg",
    imgHoverSrc: "/images/products/footprotection21.jpg",
    title: "7420 // MICRO STEP",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 412,
    imgSrc: "/images/products/footprotection22.jpg",
    imgHoverSrc: "/images/products/footprotection23.jpg",
    title: "7201W // STEP MID+ W",
    sizes: ["38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48"],
    subcategory: "Winter boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 413,
    imgSrc: "/images/products/footprotection24.jpg",
    imgHoverSrc: "/images/products/footprotection25.jpg",
    title: "7421 // MICRO STEP MID",
    sizes: ["38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48"],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 414,
    imgSrc: "/images/products/footprotection26.jpg",
    imgHoverSrc: "/images/products/footprotection27.jpg",
    title: "7201W MF // STEP MID+ MF W",
    sizes: ["38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48"],
    subcategory: "Winter boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 415,
    imgSrc: "/images/products/footprotection28.jpg",
    imgHoverSrc: "/images/products/footprotection29.jpg",
    title: "7205 // BASIC STEP",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 416,
    imgSrc: "/images/products/footprotection31.jpg",
    imgHoverSrc: "/images/products/footprotection32.jpg",
    title: "7206 // BASIC STEP MID",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 417,
    imgSrc: "/images/products/footprotection32.jpg",
    imgHoverSrc: "/images/products/footprotection33.jpg",
    title: "7207 // BASIC STEP SA",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Sandals",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 418,
    imgSrc: "/images/products/footprotection34.jpg",
    imgHoverSrc: "/images/products/footprotection35.jpg",
    title: "7208 // BASIC STEP MID+ W",
    sizes: ["38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48"],
    subcategory: "Winter boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 419,
    imgSrc: "/images/products/footprotection36.jpg",
    imgHoverSrc: "/images/products/footprotection37.jpg",
    title: "7210 // POWER STEP",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 420,
    imgSrc: "/images/products/footprotection38.jpg",
    imgHoverSrc: "/images/products/footprotection39.jpg",
    title: "7211 // POWER STEP MID",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 421,
    imgSrc: "/images/products/footprotection40.jpg",
    imgHoverSrc: "/images/products/footprotection41.jpg",
    title: "7213 // POWER STEP MID+",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 422,
    imgSrc: "/images/products/footprotection42.jpg",
    imgHoverSrc: "/images/products/footprotection43.jpg",
    title: "7213W // POWER STEP MID+ W",
    sizes: [
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Winter boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 423,
    imgSrc: "/images/products/footprotection44.jpg",
    imgHoverSrc: "/images/products/footprotection45.jpg",
    title: "7250 // CLEAN STEP SL",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Slippers",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 424,
    imgSrc: "/images/products/footprotection46.jpg",
    imgHoverSrc: "/images/products/footprotection47.jpg",
    title: "7251 // CLEAN STEP MID SL",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Slippers",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 425,
    imgSrc: "/images/products/footprotection48.jpg",
    imgHoverSrc: "/images/products/footprotection49.jpg",
    title: "7252 // CLEAN STEP CL",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Clogs",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 426,
    imgSrc: "/images/products/footprotection50.jpg",
    imgHoverSrc: "/images/products/footprotection51.jpg",
    title: "7255 // CLEAN STEP",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 427,
    imgSrc: "/images/products/footprotection52.jpg",
    imgHoverSrc: "/images/products/footprotection53.jpg",
    title: "7256 // CLEAN STEP MID",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 428,
    imgSrc: "/images/products/footprotection54.jpg",
    imgHoverSrc: "/images/products/footprotection55.jpg",
    title: "7300 // SPORT STEP",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 429,
    imgSrc: "/images/products/footprotection54.jpg",
    imgHoverSrc: "/images/products/footprotection55.jpg",
    title: "7300 // SPORT STEP",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 430,
    imgSrc: "/images/products/footprotection56.jpg",
    imgHoverSrc: "/images/products/footprotection57.jpg",
    title: "7301 // SPORT STEP MID",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 431,
    imgSrc: "/images/products/footprotection58.jpg",
    imgHoverSrc: "/images/products/footprotection59.jpg",
    title: "7301W // SPORT STEP MID W",
    sizes: ["38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48"],
    subcategory: "Winter boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 432,
    imgSrc: "/images/products/footprotection60.jpg",
    imgHoverSrc: "/images/products/footprotection61.jpg",
    title: "7302 // SPORT STEP SA",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Sandals",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 433,
    imgSrc: "/images/products/footprotection62.jpg",
    imgHoverSrc: "/images/products/footprotection63.jpg",
    title: "7305 // SPORT STEP WH",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 434,
    imgSrc: "/images/products/footprotection64.jpg",
    imgHoverSrc: "/images/products/footprotection65.jpg",
    title: "7310 // EASY STEP",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 435,
    imgSrc: "/images/products/footprotection66.jpg",
    imgHoverSrc: "/images/products/footprotection66.jpg",
    title: "712C // COMFORT STEP",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Accessories",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 436,
    imgSrc: "/images/products/footprotection67.jpg",
    imgHoverSrc: "/images/products/footprotection67.jpg",
    title: "711C // COMFORT STEP",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Accessories",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 437,
    imgSrc: "/images/products/footprotection67.jpg",
    imgHoverSrc: "/images/products/footprotection67.jpg",
    title: "711C // COMFORT STEP",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Accessories",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 438,
    imgSrc: "/images/products/footprotection68.jpg",
    imgHoverSrc: "/images/products/footprotection68.jpg",
    title: "710C // COMFORT STEP",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Accessories",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 439,
    imgSrc: "/images/products/footprotection69.jpg",
    imgHoverSrc: "/images/products/footprotection70.jpg",
    title: "7414 // PRO STEP FIT",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 440,
    imgSrc: "/images/products/footprotection71.jpg",
    imgHoverSrc: "/images/products/footprotection72.jpg",
    title: "7413 // PRO STEP",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 441,
    imgSrc: "/images/products/footprotection73.jpg",
    imgHoverSrc: "/images/products/footprotection74.jpg",
    title: "7413 // PRO STEP",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 442,
    imgSrc: "/images/products/footprotection75.jpg",
    imgHoverSrc: "/images/products/footprotection76.jpg",
    title: "7413 // PRO STEP",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 443,
    imgSrc: "/images/products/footprotection77.jpg",
    imgHoverSrc: "/images/products/footprotection78.jpg",
    title: "7440 // SONIC STEP",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 444,
    imgSrc: "/images/products/footprotection79.jpg",
    imgHoverSrc: "/images/products/footprotection80.jpg",
    title: "7440 // SONIC STEP",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 445,
    imgSrc: "/images/products/footprotection81.jpg",
    imgHoverSrc: "/images/products/footprotection82.jpg",
    title: "7440 // SONIC STEP",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 446,
    imgSrc: "/images/products/footprotection83.jpg",
    imgHoverSrc: "/images/products/footprotection83.jpg",
    title: "7440 // SONIC STEP",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 447,
    imgSrc: "/images/products/footprotection84.jpg",
    imgHoverSrc: "/images/products/footprotection85.jpg",
    title: "7306 // SPORT STEP FIT",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 448,
    imgSrc: "/images/products/footprotection86.jpg",
    imgHoverSrc: "/images/products/footprotection87.jpg",
    title: "7307 // SPORT STEP MID FIT",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 449,
    imgSrc: "/images/products/footprotection88.jpg",
    imgHoverSrc: "/images/products/footprotection89.jpg",
    title: "7435 // SOLID STEP",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 450,
    imgSrc: "/images/products/footprotection90.jpg",
    imgHoverSrc: "/images/products/footprotection91.jpg",
    title: "7435 // SOLID STEP",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 451,
    imgSrc: "/images/products/footprotection92.jpg",
    imgHoverSrc: "/images/products/footprotection93.jpg",
    title: "7436 // SOLID STEP MID",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 452,
    imgSrc: "/images/products/footprotection94.jpg",
    imgHoverSrc: "/images/products/footprotection95.jpg",
    title: "7320 // CLASSIC STEP",
    sizes: [
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
    ],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 453,
    imgSrc: "/images/products/footprotection96.jpg",
    imgHoverSrc: "/images/products/footprotection97.jpg",
    title: "7320 // CLASSIC STEP",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 454,
    imgSrc: "/images/products/footprotection98.jpg",
    imgHoverSrc: "/images/products/footprotection99.jpg",
    title: "7215 // UTILITY STEP",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Low shoes",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 455,
    imgSrc: "/images/products/footprotection100.jpg",
    imgHoverSrc: "/images/products/footprotection101.jpg",
    title: "7216 // UTILITY STEP MID",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 456,
    imgSrc: "/images/products/footprotection102.jpg",
    imgHoverSrc: "/images/products/footprotection103.jpg",
    title: "7220 // ULTIMATE STEP MID FIT",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
  {
    id: 457,
    imgSrc: "/images/products/footprotection104.jpg",
    imgHoverSrc: "/images/products/footprotection105.jpg",
    title: "7221 // ULTIMATE STEP HIGH FIT",
    sizes: [
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    subcategory: "Boots",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Foot protection",
    isAvailable: true,
  },
];

// * ================== Respiratory protection ==================

export const respiratoryprotection = [
  {
    id: 458,
    imgSrc: "/images/products/respiratoryprotection1.jpg",
    imgHoverSrc: "/images/products/respiratoryprotection1.jpg",
    title: "4120SI // SAFE AIR",
    sizes: ["/"],
    subcategory: "Respirator masks",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Respiratory protection",
    isAvailable: true,
  },
  {
    id: 459,
    imgSrc: "/images/products/respiratoryprotection2.jpg",
    imgHoverSrc: "/images/products/respiratoryprotection2.jpg",
    title: "4130SI // SAFE AIR",
    sizes: ["/"],
    subcategory: "Respirator masks",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Respiratory protection",
    isAvailable: true,
  },
  {
    id: 460,
    imgSrc: "/images/products/respiratoryprotection3.jpg",
    imgHoverSrc: "/images/products/respiratoryprotection3.jpg",
    title: "4140SI // SAFE AIR",
    sizes: ["/"],
    subcategory: "Respirator masks",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Respiratory protection",
    isAvailable: true,
  },
];

// * ================== Eye protection ==================

export const eyeprotection = [
  {
    id: 461,
    imgSrc: "/images/products/eyeprotection1.jpg",
    imgHoverSrc: "/images/products/eyeprotection2.jpg",
    title: "9000 // VISION PROTECT BASIC",
    sizes: ["/"],
    subcategory: "Spectacles",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 462,
    imgSrc: "/images/products/eyeprotection3.jpg",
    imgHoverSrc: "/images/products/eyeprotection4.jpg",
    title: "9001 // VISION PROTECT BASIC",
    sizes: ["/"],
    subcategory: "Spectacles",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 463,
    imgSrc: "/images/products/eyeprotection5.jpg",
    imgHoverSrc: "/images/products/eyeprotection6.jpg",
    title: "9010 // VISION PROTECT",
    sizes: ["/"],
    subcategory: "Spectacles",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 464,
    imgSrc: "/images/products/eyeprotection7.jpg",
    imgHoverSrc: "/images/products/eyeprotection8.jpg",
    title: "9011 // VISION PROTECT",
    sizes: ["/"],
    subcategory: "Spectacles",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 465,
    imgSrc: "/images/products/eyeprotection9.jpg",
    imgHoverSrc: "/images/products/eyeprotection10.jpg",
    title: "9012 // VISION PROTECT",
    sizes: ["/"],
    subcategory: "Spectacles",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 466,
    imgSrc: "/images/products/eyeprotection11.jpg",
    imgHoverSrc: "/images/products/eyeprotection12.jpg",
    title: "9013 // VISION PROTECT",
    sizes: ["/"],
    subcategory: "Spectacles",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 467,
    imgSrc: "/images/products/eyeprotection13.jpg",
    imgHoverSrc: "/images/products/eyeprotection14.jpg",
    title: "9015 // VISION PROTECT OTG",
    sizes: ["/"],
    subcategory: "Spectacles",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 468,
    imgSrc: "/images/products/eyeprotection15.jpg",
    imgHoverSrc: "/images/products/eyeprotection16.jpg",
    title: "9020 // VISION PROTECT PREMIUM",
    sizes: ["/"],
    subcategory: "Spectacles",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 469,
    imgSrc: "/images/products/eyeprotection17.jpg",
    imgHoverSrc: "/images/products/eyeprotection18.jpg",
    title: "9021 // VISION PROTECT PREMIUM",
    sizes: ["/"],
    subcategory: "Spectacles",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 470,
    imgSrc: "/images/products/eyeprotection19.jpg",
    imgHoverSrc: "/images/products/eyeprotection20.jpg",
    title: "9021 // VISION PROTECT PREMIUM",
    sizes: ["One Size"],
    subcategory: "Full-vision goggles",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
];

// * ================== Head protection ==================

export const headprotection = [
  {
    id: 471,
    imgSrc: "/images/products/headprotection1.jpg",
    imgHoverSrc: "/images/products/headprotection2.jpg",
    title: "9500 // HEAD PROTECT",
    sizes: ["One Size"],
    subcategory: "Safety helmets",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 472,
    imgSrc: "/images/products/headprotection3.jpg",
    imgHoverSrc: "/images/products/headprotection4.jpg",
    title: "9500 // HEAD PROTECT",
    sizes: ["One Size"],
    subcategory: "Safety helmets",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 473,
    imgSrc: "/images/products/headprotection5.jpg",
    imgHoverSrc: "/images/products/headprotection6.jpg",
    title: "9500 // HEAD PROTECT",
    sizes: ["One Size"],
    subcategory: "Safety helmets",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 474,
    imgSrc: "/images/products/headprotection7.jpg",
    imgHoverSrc: "/images/products/headprotection8.jpg",
    title: "9500 // HEAD PROTECT",
    sizes: ["One Size"],
    subcategory: "Safety helmets",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 475,
    imgSrc: "/images/products/headprotection9.jpg",
    imgHoverSrc: "/images/products/headprotection10.jpg",
    title: "9500 // HEAD PROTECT",
    sizes: ["One Size"],
    subcategory: "Safety helmets",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 476,
    imgSrc: "/images/products/headprotection11.jpg",
    imgHoverSrc: "/images/products/headprotection12.jpg",
    title: "9500 // HEAD PROTECT",
    sizes: ["One Size"],
    subcategory: "Safety helmets",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 477,
    imgSrc: "/images/products/headprotection13.jpg",
    imgHoverSrc: "/images/products/headprotection14.jpg",
    title: "9500 // HEAD PROTECT",
    sizes: ["One Size"],
    subcategory: "Safety helmets",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
  {
    id: 478,
    imgSrc: "/images/products/headprotection13.jpg",
    imgHoverSrc: "/images/products/headprotection14.jpg",
    title: "9500 // HEAD PROTECT",
    sizes: ["One Size"],
    subcategory: "Safety helmets",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Eye protection",
    isAvailable: true,
  },
];

// * ================== Kids selection ==================

export const kidsselection = [
  {
    id: 479,
    imgSrc: "/images/products/kidsselection1.jpg",
    imgHoverSrc: "/images/products/kidsselection1.jpg",
    title: "1408NITRAS",
    sizes: ["2", "3", "4"],
    subcategory: "Hand protection",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Kids selection",
    isAvailable: true,
  },
  {
    id: 480,
    imgSrc: "/images/products/kidsselection2.jpg",
    imgHoverSrc: "/images/products/kidsselection3.jpg",
    title: "7171KIDS // MOTION TEX KIDS",
    sizes: ["98/104", "110/116", "122/128", "134/140", "146/152", "158/164"],
    subcategory: "Garments",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Kids selection",
    isAvailable: true,
  },
  {
    id: 481,
    imgSrc: "/images/products/kidsselection4.jpg",
    imgHoverSrc: "/images/products/kidsselection5.jpg",
    title: "7180KIDS // MOTION TEX KIDS",
    sizes: ["98/104", "110/116", "122/128", "134/140", "146/152", "158/164"],
    subcategory: "Garments",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Kids selection",
    isAvailable: true,
  },
  {
    id: 482,
    imgSrc: "/images/products/kidsselection6.jpg",
    imgHoverSrc: "/images/products/kidsselection7.jpg",
    title: "7190KIDS // MOTION TEX KIDS",
    sizes: ["98/104", "110/116", "122/128", "134/140", "146/152", "158/164"],
    subcategory: "Garments",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Kids selection",
    isAvailable: true,
  },
  {
    id: 483,
    imgSrc: "/images/products/kidsselection8.jpg",
    imgHoverSrc: "/images/products/kidsselection9.jpg",
    title: "7192KIDS // MOTION TEX KIDS",
    sizes: ["98/104", "110/116", "122/128", "134/140", "146/152", "158/164"],
    subcategory: "Garments",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Kids selection",
    isAvailable: true,
  },
  {
    id: 484,
    imgSrc: "/images/products/kidsselection10.jpg",
    imgHoverSrc: "/images/products/kidsselection11.jpg",
    title: "7602KIDS // MOTION TEX KIDS",
    sizes: ["98/104", "110/116", "122/128", "134/140", "146/152", "158/164"],
    subcategory: "Garments",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Kids selection",
    isAvailable: true,
  },
  {
    id: 485,
    imgSrc: "/images/products/kidsselection12.jpg",
    imgHoverSrc: "/images/products/kidsselection13.jpg",
    title: "7612KIDS // MOTION TEX KIDS",
    sizes: ["98/104", "110/116", "122/128", "134/140", "146/152", "158/164"],
    subcategory: "Garments",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Kids selection",
    isAvailable: true,
  },
  {
    id: 486,
    imgSrc: "/images/products/kidsselection14.jpg",
    imgHoverSrc: "/images/products/kidsselection14.jpg",
    title: "7180W KIDS/ MOTION TEX KIDS",
    sizes: ["98/104", "110/116", "122/128", "134/140", "146/152", "158/164"],
    subcategory: "Garments",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Kids selection",
    isAvailable: true,
  },
  {
    id: 487,
    imgSrc: "/images/products/kidsselection15.jpg",
    imgHoverSrc: "/images/products/kidsselection16.jpg",
    title: "7175KIDS // MOTION TEX KIDS",
    sizes: ["98/104", "110/116", "122/128", "134/140", "146/152", "158/164"],
    subcategory: "Garments",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Kids selection",
    isAvailable: true,
  },
  {
    id: 488,
    imgSrc: "/images/products/kidsselection17.jpg",
    imgHoverSrc: "/images/products/kidsselection18.jpg",
    title: "7175KIDS // MOTION TEX KIDS",
    sizes: ["One Size"],
    subcategory: "Headgear",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Kids selection",
    isAvailable: true,
  },
  {
    id: 489,
    imgSrc: "/images/products/kidsselection19.jpg",
    imgHoverSrc: "/images/products/kidsselection20.jpg",
    title: "730KIDS",
    sizes: ["One Size"],
    subcategory: "Headgear",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Kids selection",
    isAvailable: true,
  },
];

export const products2 = [
  {
    id: 490,
    imgSrc: "/images/products/black-13.jpg",
    imgHoverSrc: "/images/products/brown-5.jpg",
    title: "Shopper",
    price: 120.95,
    colors: [
      {
        name: "Black",
        bgClass: "bg_dark",
        imgSrc: "/images/products/black-13.jpg",
      },
      {
        name: "Brown",
        bgClass: "bg_brown",
        imgSrc: "/images/products/brown-5.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
  },
  {
    id: 491,
    imgSrc: "/images/products/light-brown.jpg",
    imgHoverSrc: "/images/products/light-green-3.jpg",
    title: "Large hair claw",
    price: 8.95,
    colors: [
      {
        name: "Light Brown",
        bgClass: "bg_light-brown",
        imgSrc: "/images/products/light-brown.jpg",
      },
      {
        name: "Light Green",
        bgClass: "bg_light-green",
        imgSrc: "/images/products/light-green-3.jpg",
      },
      {
        name: "Orange",
        bgClass: "bg_orange",
        imgSrc: "/images/products/orange-2.jpg",
      },
    ],
    sizes: ["M", "L", "XL"],
    countdown: { time: 1007500, labels: "d :,h :,m :,s" },
  },
  {
    id: 492,
    imgSrc: "/images/products/light-blue-2.jpg",
    imgHoverSrc: "/images/products/black-14.jpg",
    title: "Large hair claw",
    price: 8.95,
    colors: [
      {
        name: "Light Blue",
        bgClass: "bg_light-blue sold-out",
        imgSrc: "/images/products/light-blue-2.jpg",
      },
      {
        name: "Black",
        bgClass: "bg_dark",
        imgSrc: "/images/products/black-14.jpg",
      },
      {
        name: "White",
        bgClass: "bg_white",
        imgSrc: "/images/products/white-10.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
  },
  {
    id: 493,
    imgSrc: "/images/products/brown-6.jpg",
    imgHoverSrc: "/images/products/brown-7.jpg",
    title: "Cat Eye Sunglasses",
    price: 12.95,
    colors: [],
    sizes: ["S", "M", "L", "XL"],
  },
  {
    id: 494,
    imgSrc: "/images/products/brown-8.jpg",
    imgHoverSrc: "/images/products/black-15.jpg",
    title: "Block-heeled Mary Janes",
    price: 139.95,
    colors: [
      {
        name: "Brown",
        bgClass: "bg_brown",
        imgSrc: "/images/products/brown-8.jpg",
      },
      {
        name: "Black",
        bgClass: "bg_white",
        imgSrc: "/images/products/black-15.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
  },
  {
    id: 495,
    imgSrc: "/images/products/light-green-4.jpg",
    imgHoverSrc: "/images/products/light-green-5.jpg",
    title: "Frida - Misty Jade",
    price: 130.95,
    colors: [],
  },
  {
    id: 496,
    imgSrc: "/images/products/brown-9.jpg",
    imgHoverSrc: "/images/products/brown-10.jpg",
    title: "Grace - Brown",
    price: 130.95,
    colors: [],
    sizes: ["S", "M", "L"],
  },
  {
    id: 497,
    imgSrc: "/images/products/white-11.jpg",
    imgHoverSrc: "/images/products/light-blue-3.jpg",
    title: "Mini Headset Wired & Wireless",
    price: 235.95,
    colors: [
      {
        name: "White",
        bgClass: "bg_white",
        imgSrc: "/images/products/white-11.jpg",
      },
      {
        name: "Light Blue",
        bgClass: "bg_light-blue",
        imgSrc: "/images/products/light-blue-3.jpg",
      },
      {
        name: "Pink",
        bgClass: "bg_pink",
        imgSrc: "/images/products/pink-3.jpg",
      },
      {
        name: "Black",
        bgClass: "bg_dark",
        imgSrc: "/images/products/black-16.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
  },
];
export const products3 = [
  {
    id: 498,

    imgSrc: "/images/products/light-blue-2.jpg",
    imgHoverSrc: "/images/products/black-14.jpg",
    title: "Cotton Twill Cap",
    price: 26.95,
    colors: [
      {
        name: "Light Blue",
        colorClass: "bg_light-blue",
        imgSrc: "/images/products/light-blue-2.jpg",
        soldOut: true,
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-14.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/white-10.jpg",
      },
    ],
  },
  {
    id: 499,

    imgSrc: "/images/products/beige-2.jpg",
    imgHoverSrc: "/images/products/beige-3.jpg",
    title: "Cotton jersey top",
    price: 7.95,
    colors: [
      {
        name: "Beige",
        colorClass: "bg_beige",
        imgSrc: "/images/products/beige-2.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-17.jpg",
      },
      {
        name: "Blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/blue-3.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/white-12.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
    countdown: { time: 1007500, labels: "d :,h :,m :,s" },
  },
];

export const galleryItems = [
  {
    id: 500,
    imgSrc: "/images/shop/gallery/gallery-7.jpg",
    imgAlt: "image-gallery",
    imgWidth: 400,
    imgHeight: 400,
    title: "demo title",
    price: 29.66,
    delay: "0s",
    tooltip: "Quick Add",
  },

  {
    id: 503,
    imgSrc: "/images/shop/gallery/gallery-8.jpg",
    imgAlt: "image-gallery",
    imgWidth: 400,
    imgHeight: 400,
    title: "demo title",
    price: 29.66,
    delay: ".3s",
    tooltip: "View product",
    detail: true,
  },
];

export const products4 = [
  {
    id: 506,
    imgSrc: "/images/shop/products/p-d3.png",
    imgWidth: 713,
    imgHeight: 995,
    title: "Ribbed modal T-shirt",
    price: 8.0,
    compareAtPrice: "$10.00",
    options: [
      "Beige / S",
      "Beige / M",
      "Beige / L",
      "Beige / XL",
      "Black / S",
      "Black / M",
      "Black / L",
      "Black / XL",
      "Blue / S",
      "Blue / M",
      "Blue / L",
      "Blue / XL",
      "White / S",
      "White / M",
      "White / L",
      "White / XL",
    ],
  },
];

export const products5 = [
  {
    id: 508,
    imgSrc: "/images/products/skincare-1.jpg",
    imgHoverSrc: "/images/products/skincare-2.jpg",
    title: "Natural Moisturizing Factors",
    price: 32.0,
    sizes: ["30ml", "60ml"],
    filterCategories: ["Essentials", "Gift Sets"],
  },
  {
    id: 509,
    imgSrc: "/images/products/skincare-3.jpg",
    imgHoverSrc: "/images/products/skincare-4.jpg",
    title: "Anhydrous Solution Pore Serum",
    price: 65.0,
    sizes: ["30ml", "60ml"],
    filterCategories: ["Essentials"],
  },
  {
    id: 510,
    imgSrc: "/images/products/skincare-5.jpg",
    imgHoverSrc: "/images/products/skincare-6.jpg",
    title: "Serum for Hair Density",
    price: 65.0,
    sizes: ["40ml", "50ml"],
    filterCategories: ["Essentials", "Gift Sets"],
  },
  {
    id: 511,
    imgSrc: "/images/products/skincare-7.jpg",
    imgHoverSrc: "/images/products/skincare-8.jpg",
    title: "Mini Confidence in a Cleanser",
    price: 22.0,
    sizes: ["50ml", "80ml"],
    filterCategories: ["Essentials"],
  },
  {
    id: 512,
    imgSrc: "/images/products/skincare-9.jpg",
    imgHoverSrc: "/images/products/skincare-10.jpg",
    title: "Natural Moisturizing Factors",
    price: 52.0,
    oldPrice: "$62.00",
    sizes: ["50ml", "80ml"],
    filterCategories: ["Essentials"],
  },
  {
    id: 513,
    imgSrc: "/images/products/skincare-11.jpg",
    imgHoverSrc: "/images/products/skincare-12.jpg",
    title: "Natural Moisturizing Factors",
    price: 52.0,
    oldPrice: "$62.00",
    sizes: ["50ml", "80ml"],
    filterCategories: ["Essentials", "Gift Sets"],
  },
  {
    id: 514,
    imgSrc: "/images/products/skincare-13.jpg",
    imgHoverSrc: "/images/products/skincare-14.jpg",
    title: "Mini Skin Perfecting Exfoliant",
    price: 65.0,
    sizes: ["40ml", "50ml"],
    filterCategories: ["Essentials", "Gift Sets"],
  },
  {
    id: 515,
    imgSrc: "/images/products/skincare-15.jpg",
    imgHoverSrc: "/images/products/skincare-16.jpg",
    title: "Mini Skin Perfecting Exfoliant",
    price: 65.0,
    sizes: ["40ml", "50ml"],
    filterCategories: ["Essentials", "Gift Sets"],
  },
];

export const galleryItems2 = [
  {
    id: 516,
    src: "/images/shop/gallery/gallery-9.jpg",
    alt: "image-gallery",
    delay: "0s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 517,
    src: "/images/shop/gallery/gallery-10.jpg",
    alt: "image-gallery",
    delay: ".1s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 518,
    src: "/images/shop/gallery/gallery-11.jpg",
    alt: "image-gallery",
    delay: ".2s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 519,
    src: "/images/shop/gallery/gallery-12.jpg",
    alt: "image-gallery",
    delay: ".3s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 520,
    src: "/images/shop/gallery/gallery-13.jpg",
    alt: "image-gallery",
    delay: ".4s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 521,
    src: "/images/shop/gallery/gallery-14.jpg",
    alt: "image-gallery",
    delay: ".4s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 522,
    src: "/images/shop/gallery/gallery-15.jpg",
    alt: "image-gallery",
    delay: ".4s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 523,
    src: "/images/shop/gallery/gallery-16.jpg",
    alt: "image-gallery",
    delay: ".4s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 524,
    src: "/images/shop/gallery/gallery-9.jpg",
    alt: "image-gallery",
    delay: "0s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 525,
    src: "/images/shop/gallery/gallery-10.jpg",
    alt: "image-gallery",
    delay: ".1s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 526,
    src: "/images/shop/gallery/gallery-11.jpg",
    alt: "image-gallery",
    delay: ".2s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 527,
    src: "/images/shop/gallery/gallery-12.jpg",
    alt: "image-gallery",
    delay: ".3s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 528,
    src: "/images/shop/gallery/gallery-13.jpg",
    alt: "image-gallery",
    delay: ".4s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 529,
    src: "/images/shop/gallery/gallery-14.jpg",
    alt: "image-gallery",
    delay: ".4s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 530,
    src: "/images/shop/gallery/gallery-15.jpg",
    alt: "image-gallery",
    delay: ".4s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 531,
    src: "/images/shop/gallery/gallery-16.jpg",
    alt: "image-gallery",
    delay: ".4s",

    price: 29.66,
  },
];

export const products6 = [
  {
    id: 532,
    imgSrc: "/images/products/headphone-1.png",
    imgHoverSrc: "/images/products/headphone-2.png",
    alt: "image-product",
    price: 499.0,
    description: [
      "32 hours of listening",
      "Bluetooth@ 5.1",
      "30mm beryllium drivers",
      "4 beamforming microphones",
      "Warranty : 1-year",
    ],

    vendorName: "Gaming X- Seri 1",
    title: "ECOMUS20 BAPE®",
    colors: [
      {
        tooltip: "Light Green",
        bgColor: "bg_light-green",
        image: "/images/products/headphone-1.png",
      },
      {
        tooltip: "Black",
        bgColor: "bg_dark",
        image: "/images/products/headphone-2.png",
      },
      {
        tooltip: "White",
        bgColor: "bg_white",
        image: "/images/products/headphone-3.png",
      },
    ],
    onSale: "New !",
    colorbg: "colorbg-light-green",
  },
  {
    id: 533,
    imgSrc: "/images/products/headphone-4.png",
    imgHoverSrc: "/images/products/headphone-5.png",
    alt: "image-product",
    price: 384.0,
    description: [
      "28 hours of listening",
      "Bluetooth@ 5.1",
      "30mm beryllium drivers",
      "3 beamforming microphones",
      "Warranty : 3-year",
    ],
    populer: true,

    vendorName: "Gaming X- Seri 1",
    title: "MG20 BAPE®",
    colors: [
      {
        tooltip: "Blue",
        bgColor: "bg_blue-2",
        image: "/images/products/headphone-4.png",
      },
      {
        tooltip: "Pink",
        bgColor: "bg_purple",
        image: "/images/products/headphone-5.png",
      },
    ],
    onSale: "Best Seller !",
    colorbg: "colorbg-black",
  },
  {
    id: 534,
    imgSrc: "/images/products/headphone-6.png",
    imgHoverSrc: "/images/products/headphone-7.png",
    alt: "image-product",
    price: 384.0,
    description: [
      "30 hours of listening",
      "Bluetooth@ 5.1",
      "30mm beryllium drivers",
      "3 beamforming microphones",
      "Warranty : 2-year",
    ],

    vendorName: "Gaming X- Seri 1",
    title: "MW75 BUGATTI",
    colors: [
      {
        tooltip: "Blue",
        bgColor: "bg_blue-2",
        image: "/images/products/headphone-6.png",
      },
      {
        tooltip: "Orange",
        bgColor: "bg_orange-3",
        image: "/images/products/headphone-7.png",
      },
    ],
    onSale: "Recommend !",
    colorbg: "colorbg-radius-20",
  },
  {
    id: 535,
    imgSrc: "/images/products/headphone-1.png",
    imgHoverSrc: "/images/products/headphone-2.png",
    alt: "image-product",
    price: 499.0,
    description: [
      "32 hours of listening",
      "Bluetooth@ 5.1",
      "30mm beryllium drivers",
      "4 beamforming microphones",
      "Warranty : 1-year",
    ],

    vendorName: "Gaming X- Seri 1",
    title: "ECOMUS20 BAPE®",
    colors: [
      {
        tooltip: "Light Green",
        bgColor: "bg_light-green",
        image: "/images/products/headphone-1.png",
      },
      {
        tooltip: "Black",
        bgColor: "bg_dark",
        image: "/images/products/headphone-2.png",
      },
      {
        tooltip: "White",
        bgColor: "bg_white",
        image: "/images/products/headphone-3.png",
      },
    ],
    onSale: "New !",
    colorbg: "colorbg-light-green",
  },
  {
    id: 536,
    imgSrc: "/images/products/headphone-4.png",
    imgHoverSrc: "/images/products/headphone-5.png",
    alt: "image-product",
    price: 384.0,
    description: [
      "28 hours of listening",
      "Bluetooth@ 5.1",
      "30mm beryllium drivers",
      "3 beamforming microphones",
      "Warranty : 3-year",
    ],
    populer: true,

    vendorName: "Gaming X- Seri 1",
    title: "MG20 BAPE®",
    colors: [
      {
        tooltip: "Blue",
        bgColor: "bg_blue-2",
        image: "/images/products/headphone-4.png",
      },
      {
        tooltip: "Pink",
        bgColor: "bg_purple",
        image: "/images/products/headphone-5.png",
      },
    ],
    onSale: "Best Seller !",
    colorbg: "colorbg-black",
  },
  {
    id: 537,
    imgSrc: "/images/products/headphone-6.png",
    imgHoverSrc: "/images/products/headphone-7.png",
    alt: "image-product",
    price: 384.0,
    description: [
      "30 hours of listening",
      "Bluetooth@ 5.1",
      "30mm beryllium drivers",
      "3 beamforming microphones",
      "Warranty : 2-year",
    ],

    vendorName: "Gaming X- Seri 1",
    title: "MW75 BUGATTI",
    colors: [
      {
        tooltip: "Blue",
        bgColor: "bg_blue-2",
        image: "/images/products/headphone-6.png",
      },
      {
        tooltip: "Orange",
        bgColor: "bg_orange-3",
        image: "/images/products/headphone-7.png",
      },
    ],
    onSale: "Recommend !",
    colorbg: "colorbg-radius-20",
  },
];

export const products7 = [
  {
    id: 538,
    title: "WH-1000XM4 Wireless Headphones",
    imgSrc: "/images/products/headphone-8.png",
    imgHoverSrc: "/images/products/headphone-9.png",
    price: 279.0,
    originalPrice: "$349.00",
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/headphone-8.png",
      },
      {
        name: "Blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/headphone-9.png",
      },
      {
        name: "Light Grey",
        colorClass: "bg_light-grey",
        imgSrc: "/images/products/headphone-10.png",
      },
    ],
  },
  {
    id: 539,
    title: "LinkBuds S Wireless Earbuds",
    imgSrc: "/images/products/headphone-11.png",
    imgHoverSrc: "/images/products/headphone-12.png",
    price: 199.0,
    colors: [
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/headphone-11.png",
      },
      {
        name: "Pink",
        colorClass: "bg_purple",
        imgSrc: "/images/products/headphone-12.png",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/headphone-13.png",
      },
    ],
  },
  {
    id: 540,
    title: "Beats Studio Buds",
    imgSrc: "/images/products/headphone-14.png",
    imgHoverSrc: "/images/products/headphone-15.png",
    price: 149.99,
    colors: [
      {
        name: "Red",
        colorClass: "bg_red",
        imgSrc: "/images/products/headphone-14.png",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/headphone-15.png",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/headphone-16.png",
      },
    ],
  },
  {
    id: 541,
    title: "AirPods Max",
    imgSrc: "/images/products/headphone-17.png",
    imgHoverSrc: "/images/products/headphone-18.png",
    price: 479.0,
    originalPrice: "$549.00",
    colors: [
      {
        name: "Green",
        colorClass: "bg_green",
        imgSrc: "/images/products/headphone-17.png",
      },
      {
        name: "Pink",
        colorClass: "bg_pink",
        imgSrc: "/images/products/headphone-18.png",
      },
      {
        name: "Silver",
        colorClass: "bg_silver",
        imgSrc: "/images/products/headphone-19.png",
      },
    ],
  },
  {
    id: 542,
    title: "WH-1000XM4 Wireless Headphones",
    imgSrc: "/images/products/headphone-8.png",
    imgHoverSrc: "/images/products/headphone-9.png",
    price: 279.0,
    originalPrice: "$349.00",
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/headphone-8.png",
      },
      {
        name: "Blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/headphone-9.png",
      },
      {
        name: "Light Grey",
        colorClass: "bg_light-grey",
        imgSrc: "/images/products/headphone-10.png",
      },
    ],
  },
  {
    id: 543,
    title: "LinkBuds S Wireless Earbuds",
    imgSrc: "/images/products/headphone-11.png",
    imgHoverSrc: "/images/products/headphone-12.png",
    price: 199.0,
    colors: [
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/headphone-11.png",
      },
      {
        name: "Pink",
        colorClass: "bg_purple",
        imgSrc: "/images/products/headphone-12.png",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/headphone-13.png",
      },
    ],
  },
  {
    id: 544,
    title: "Beats Studio Buds",
    imgSrc: "/images/products/headphone-14.png",
    imgHoverSrc: "/images/products/headphone-15.png",
    price: 149.99,
    colors: [
      {
        name: "Red",
        colorClass: "bg_red",
        imgSrc: "/images/products/headphone-14.png",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/headphone-15.png",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/headphone-16.png",
      },
    ],
  },
  {
    id: 545,
    title: "AirPods Max",
    imgSrc: "/images/products/headphone-17.png",
    imgHoverSrc: "/images/products/headphone-18.png",
    price: 479.0,
    originalPrice: "$549.00",
    colors: [
      {
        name: "Green",
        colorClass: "bg_green",
        imgSrc: "/images/products/headphone-17.png",
      },
      {
        name: "Pink",
        colorClass: "bg_pink",
        imgSrc: "/images/products/headphone-18.png",
      },
      {
        name: "Silver",
        colorClass: "bg_silver",
        imgSrc: "/images/products/headphone-19.png",
      },
    ],
  },
];

export const productSlides = [
  {
    id: 546,
    imgSrc: "/images/products/giftcard-1.jpg",
    title: "AWS eGift Card",
    price: 121,
  },
  {
    id: 547,
    imgSrc: "/images/products/giftcard-2.jpg",
    title: "Bodums eGift Card",
    price: 33,
  },
  {
    id: 548,
    imgSrc: "/images/products/giftcard-3.jpg",
    title: "Jay Jays eGift Card",
    price: 10,
  },
  {
    id: 549,
    imgSrc: "/images/products/giftcard-4.jpg",
    title: "Adventure eGift Card",
    price: 10,
  },
  {
    id: 550,
    imgSrc: "/images/products/giftcard-5.jpg",
    title: "AWS eGift Card",
    price: 121,
  },
  {
    id: 551,
    imgSrc: "/images/products/giftcard-6.jpg",
    title: "Bodums eGift Card",
    price: 33,
  },
  {
    id: 552,
    imgSrc: "/images/products/giftcard-7.jpg",
    title: "Jay Jays eGift Card",
    price: 10,
  },
];

export const products9 = [
  {
    id: 553,
    imgSrc: "/images/products/lamp-black.jpg",
    imgHoverSrc: "/images/products/lamp-black.jpg",
    title: "AJ Wall Sconce",
    price: 982.0,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/lamp-black.jpg",
      },
      {
        name: "Dark Green",
        colorClass: "bg_dark-green",
        imgSrc: "/images/products/lamp-dark-green.jpg",
      },
    ],
    sizes: [], // No sizes provided
  },
  {
    id: 554,
    imgSrc: "/images/products/furniture-1.jpg",
    imgHoverSrc: "/images/products/furniture-1.jpg",
    title: "Brasilia Lounge Chair",
    price: 982.0,
    colors: [],
    sizes: [], // No sizes provided
  },
  {
    id: 555,
    imgSrc: "/images/products/furniture-2.jpg",
    imgHoverSrc: "/images/products/furniture-3.jpg",
    title: "Lina Swivel Chair",
    price: 3600.0,
    colors: [
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/furniture-2.jpg",
      },
      {
        name: "Cream",
        colorClass: "bg_cream",
        imgSrc: "/images/products/furniture-3.jpg",
      },
    ],
    sizes: [], // No sizes provided
  },
  {
    id: 556,
    imgSrc: "/images/products/furniture-6.jpg",
    imgHoverSrc: "/images/products/furniture-7.jpg",
    title: "MR Chaise",
    price: 1999.0,
    colors: [
      {
        name: "Light Grey",
        colorClass: "bg_light-grey",
        imgSrc: "/images/products/furniture-6.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/furniture-7.jpg",
      },
    ],
    sizes: [], // No sizes provided
  },
  {
    id: 557,
    imgSrc: "/images/products/furniture-4.jpg",
    imgHoverSrc: "/images/products/furniture-5.jpg",
    title: "MG501 Cuba Lounge Chair, Paper Cord",
    price: 1290.0,
    oldPrice: "$1,366.00",
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/furniture-4.jpg",
      },
      {
        name: "Cream",
        colorClass: "bg_cream",
        imgSrc: "/images/products/furniture-5.jpg",
      },
    ],
    sizes: [], // No sizes provided
  },
  {
    id: 558,
    imgSrc: "/images/products/furniture-8.jpg",
    imgHoverSrc: "/images/products/furniture-9.jpg",
    title: "Scissor Chair",
    price: 2755.0,
    colors: [
      {
        name: "Beige",
        colorClass: "bg_beige",
        imgSrc: "/images/products/furniture-8.jpg",
      },
      {
        name: "Navy",
        colorClass: "bg_navy",
        imgSrc: "/images/products/furniture-9.jpg",
      },
    ],
    sizes: [], // No sizes provided
  },
  {
    id: 559,
    imgSrc: "/images/products/furniture-10.jpg",
    imgHoverSrc: "/images/products/furniture-11.jpg",
    title: "Soborg Model 3052 Dining Chair",
    price: 1356.0,
    oldPrice: "$1,695.00",
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/furniture-10.jpg",
      },
      {
        name: "Copper",
        colorClass: "bg_copper",
        imgSrc: "/images/products/furniture-11.jpg",
      },
    ],
    sizes: [], // No sizes provided
    onSale: true,
    saleLabel: "Pre-Order",
  },
  {
    id: 560,
    imgSrc: "/images/products/furniture-12.jpg",
    imgHoverSrc: "/images/products/furniture-13.jpg",
    title: "Product Name Here", // Replace with actual product title
    price: 1356.0,
    colors: [
      {
        name: "Color Name",
        colorClass: "color_class",
        imgSrc: "/images/products/furniture-12.jpg",
      }, // Replace with actual color details
      {
        name: "Color Name",
        colorClass: "color_class",
        imgSrc: "/images/products/furniture-13.jpg",
      }, // Replace with actual color details
    ],
    sizes: [], // No sizes provided
  },
];

export const products10 = [
  {
    id: 561,
    imgSrc: "/images/slider/skateboard-slider-01.jpg",
    alt: "fashion-slideshow",
    title: "Yow Surfskates Christenson Hole Shot Surfskate",
    price: 279.99,
  },
  {
    id: 562,
    imgSrc: "/images/slider/skateboard-slider-02.jpg",
    alt: "fashion-slideshow",
    title: "Element Skateboards Section Complete Skateboard",
    price: 71.99,
  },
  {
    id: 563,
    imgSrc: "/images/slider/skateboard-slider-03.jpg",
    alt: "fashion-slideshow",
    title: "Foundation Skateboards Star & Moon White Complete Skateboard",
    price: 89.99,
  },
];

export const products11 = [
  {
    id: 564,
    title: "Habitat Skateboards Ellipse Complete Skateboard",
    price: 9999,
    imgSrc: "/images/products/stakeboard-greenblack.jpg",
    imgHoverSrc: "/images/products/stakeboard-yellowblack.jpg",
    colors: [
      {
        name: "Green Black",
        colorClass: "green-black bg-multiple-color",
        imgSrc: "/images/products/stakeboard-greenblack.jpg",
      },
      {
        name: "Yellow Black",
        colorClass: "yellow-black bg-multiple-color",
        imgSrc: "/images/products/stakeboard-yellowblack.jpg",
      },
    ],
  },
  {
    id: 565,
    title: "Skateboards Thrasher Complete Skateboard",
    price: 89.99,
    imgSrc: "/images/products/stakeboard-blueblack.jpg",
    imgHoverSrc: "/images/products/stakeboard-blueblack.jpg",
    colors: [
      {
        name: "Blue Black",
        colorClass: "blue-black bg-multiple-color",
        imgSrc: "/images/products/stakeboard-blueblack.jpg",
      },
      {
        name: "Blue White",
        colorClass: "blue-white bg-multiple-color",
        imgSrc: "/images/products/stakeboard-bluewhite.jpg",
      },
    ],
  },
  {
    id: 566,
    title: "RAD Wheels Checker Complete Skateboard",
    price: 53.99,
    imgSrc: "/images/products/stakeboard-redblack.jpg",
    imgHoverSrc: "/images/products/stakeboard-redblack.jpg",
    colors: [
      {
        name: "Red Black",
        colorClass: "red-black bg-multiple-color",
        imgSrc: "/images/products/stakeboard-redblack.jpg",
      },
      {
        name: "White Striped",
        colorClass: "white-striped bg-multiple-color",
        imgSrc: "/images/products/stakeboard-whitestriped.jpg",
      },
    ],
  },
  {
    id: 567,
    title: "Element Skateboards Section Complete Skateboard",
    price: 71.99,
    imgSrc: "/images/products/stakeboard-blackwhite.jpg",
    imgHoverSrc: "/images/products/stakeboard-black.jpg",
    colors: [],
  },
  {
    id: 568,
    title: "Foundation Skateboards Star & Moon White Complete Skateboard",
    price: 89.99,
    imgSrc: "/images/products/stakeboard-blackwhite2.jpg",
    imgHoverSrc: "/images/products/stakeboard-black.jpg",
    colors: [],
  },
  {
    id: 569,
    title:
      "Globe Skateboards Blazer XL Coconut / Lime Cruiser Complete Skateboard",
    price: 138.99,
    imgSrc: "/images/products/stakeboard-lightgreen.jpg",
    imgHoverSrc: "/images/products/stakeboard-orange2.jpg",
    colors: [
      {
        name: "Light Green",
        colorClass: "bg_light-green",
        imgSrc: "/images/products/stakeboard-lightgreen.jpg",
      },
    ],
  },
];

export const products12 = [
  {
    id: 570,
    imgSrc: "/images/products/playing-card-1.jpg",
    imgHoverSrc: "/images/products/playing-card-2.jpg",
    alt: "image-product",
    width: 533,
    height: 695,
    title: "Thrasher Magazine Playing Cards",
    price: 9.99,
  },
  {
    id: 571,
    imgSrc: "/images/products/skateboard-grind-rail-white.jpg",
    imgHoverSrc: "/images/products/skateboard-grind-rail-light-green.jpg",
    alt: "image-product",
    width: 533,
    height: 695,
    title: "OC Ramps 8 Foot Round Glow In The Dark Skateboard Grind Rail",
    price: 299,
    colors: [
      {
        tooltip: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/skateboard-grind-rail-white.jpg",
      },
      {
        tooltip: "Light Green",
        colorClass: "bg_light-green",
        imgSrc: "/images/products/skateboard-grind-rail-light-green.jpg",
      },
    ],
  },
  {
    id: 572,
    imgSrc: "/images/products/bubble-gum-yellow.jpg",
    imgHoverSrc: "/images/products/bubble-gum-light-green.jpg",
    alt: "image-product",
    width: 533,
    height: 695,
    title: "Bubble Gum Surf Wax 6 oz.",
    price: 11.99,
    colors: [
      {
        tooltip: "Yellow",
        colorClass: "bg_yellow",
        imgSrc: "/images/products/bubble-gum-yellow.jpg",
      },
      {
        tooltip: "Light Green",
        colorClass: "bg_light-green",
        imgSrc: "/images/products/bubble-gum-light-green.jpg",
      },
    ],
  },
  {
    id: 573,
    imgSrc: "/images/products/sunglasses-black.jpg",
    imgHoverSrc: "/images/products/sunglasses-red.jpg",
    alt: "image-product",
    width: 533,
    height: 695,
    title: "Glassy Sunhaters Leo Premium Sunglasses",
    price: 39.99,
    colors: [
      {
        tooltip: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/sunglasses-black.jpg",
      },
      {
        tooltip: "Blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/sunglasses-blue.jpg",
      },
      {
        tooltip: "Red",
        colorClass: "bg_red",
        imgSrc: "/images/products/sunglasses-red.jpg",
      },
    ],
  },
  {
    id: 574,
    imgSrc: "/images/products/skateboards-tool-black.jpg",
    imgHoverSrc: "/images/products/skateboards-tool-red.jpg",
    alt: "image-product",
    width: 676,
    height: 882,
    title: "Skateboards Tool",
    price: 29.99,
  },
];

export const products13 = [
  {
    id: 575,
    imgSrc: "/images/products/stroller-baby-brown.jpg",
    imgHoverSrc: "/images/products/stroller-baby-black.jpg",
    title: "Baby Stroller with Ride-Along Board",
    price: 950.0,
    colors: [
      {
        name: "Pastel Brown",
        colorClass: "bg_pastel-brown",
        imgSrc: "/images/products/stroller-baby-brown.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/stroller-baby-black.jpg",
      },
    ],
  },
  {
    id: 576,
    imgSrc: "/images/products/stroller-baby-black2.jpg",
    imgHoverSrc: "/images/products/stroller-baby-black2.jpg",
    title: "Racing Tricycle Baby Stroller",
    price: 300.0,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/stroller-baby-black2.jpg",
      },
      {
        name: "Dark Green",
        colorClass: "bg_dark-green",
        imgSrc: "/images/products/stroller-baby-darkgreen.jpg",
      },
    ],
  },
  {
    id: 577,
    imgSrc: "/images/products/stroller-baby-black3.jpg",
    imgHoverSrc: "/images/products/stroller-baby-black3.jpg",
    title: "Stroller Bundle with Black Frame",
    price: 499.0,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/stroller-baby-black3.jpg",
      },
      {
        name: "Pastel Brown",
        colorClass: "bg_pastel-brown",
        imgSrc: "/images/products/stroller-baby-brown.jpg",
      },
      {
        name: "Pink",
        colorClass: "bg_pink",
        imgSrc: "/images/products/stroller-baby-pink.jpg",
      },
    ],
  },
  {
    id: 578,
    imgSrc: "/images/products/stroller-baby-yellow.jpg",
    imgHoverSrc: "/images/products/stroller-baby-black4.jpg",
    title: "Stokke X Baby Stroller",
    price: 1199.0,
    colors: [
      {
        name: "Yellow",
        colorClass: "bg_yellow-4",
        imgSrc: "/images/products/stroller-baby-yellow.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/stroller-baby-black4.jpg",
      },
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/stroller-baby-grey.jpg",
      },
    ],
  },
  {
    id: 579,
    imgSrc: "/images/products/stroller-baby-black5.jpg",
    imgHoverSrc: "/images/products/stroller-baby-beige.jpg",
    title: "Sand Jogging Baby Stroller",
    price: 399.0,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/stroller-baby-black5.jpg",
      },
      {
        name: "Beige",
        colorClass: "bg_beige",
        imgSrc: "/images/products/stroller-baby-beige.jpg",
      },
      {
        name: "Dark Blue",
        colorClass: "bg_dark-blue",
        imgSrc: "/images/products/stroller-baby-darkblue.jpg",
      },
    ],
  },
];
export const products14 = [
  {
    id: 580,
    imgSrc: "/images/products/decor-1.jpg",
    imgHoverSrc: "/images/products/decor-2.jpg",
    title: "Flycatche",
    price: 82.0,
    colors: [
      {
        name: "Light Pink",
        colorClass: "bg_light-pink",
        imgSrc: "/images/products/decor-1.jpg",
      },
      {
        name: "Light Green",
        colorClass: "bg_light-green",
        imgSrc: "/images/products/decor-3.jpg",
      },
    ],
  },
  {
    id: 581,
    imgSrc: "/images/products/decor-4.jpg",
    imgHoverSrc: "/images/products/decor-4.jpg",
    title: "Candleholder",
    price: 85.0,
    colors: [
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/decor-4.jpg",
      },
      {
        name: "Brown",
        colorClass: "bg_brown",
        imgSrc: "/images/products/decor-5.jpg",
      },
    ],
  },
  {
    id: 582,
    imgSrc: "/images/products/decor-6.jpg",
    imgHoverSrc: "/images/products/decor-7.jpg",
    title: "Standing Polar fox",
    price: 82.0,
    colors: [
      {
        name: "Light Pink",
        colorClass: "bg_light-pink",
        imgSrc: "/images/products/decor-6.jpg",
      },
      {
        name: "Light Green",
        colorClass: "bg_light-green",
        imgSrc: "/images/products/decor-8.jpg",
      },
    ],
  },
  {
    id: 583,
    imgSrc: "/images/products/decor-9.jpg",
    imgHoverSrc: "/images/products/decor-10.jpg",
    title: "Candleholder gift set brassfox",
    price: 69.0,
    colors: [
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/decor-9.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/decor-11.jpg",
      },
      {
        name: "Gold",
        colorClass: "bg_gold",
        imgSrc: "/images/products/decor-12.jpg",
      },
    ],
  },
  {
    id: 584,
    imgSrc: "/images/products/decor-13.jpg",
    imgHoverSrc: "/images/products/decor-14.jpg",
    title: "Polar fox",
    price: 62.0,
    colors: [
      {
        name: "Light Pink",
        colorClass: "bg_light-pink",
        imgSrc: "/images/products/decor-13.jpg",
      },
      {
        name: "Light Green",
        colorClass: "bg_light-green",
        imgSrc: "/images/products/decor-14.jpg",
      },
    ],
  },
  {
    id: 585,
    imgSrc: "/images/products/decor-15.jpg",
    imgHoverSrc: "/images/products/decor-16.jpg",
    title: "Another Product",
    price: 70.0,
    colors: [
      {
        name: "Color Name",
        colorClass: "bg_color-class",
        imgSrc: "/images/products/decor-15.jpg",
      },
    ],
  },
];

export const products15 = [
  {
    id: 586,
    imgSrc: "/images/collections/electronic-8.png",
    imgHoverSrc: "/images/collections/electronic-9.jpg",
    title: "UltraGlass 2 Treated Screen Protector for iPhone 15 Pro",
    price: 39.99,
  },
  {
    id: 587,
    imgSrc: "/images/collections/electronic-10.png",
    imgHoverSrc: "/images/collections/electronic-11.png",
    title: "Smart Light Switch with Thread",
    price: 49.99,
  },
  {
    id: 588,
    imgSrc: "/images/collections/electronic-12.png",
    imgHoverSrc: "/images/collections/electronic-13.png",
    title: "SoundForm Rise",
    price: 79.99,
    oldPrice: "$100.00",
  },
  {
    id: 589,
    imgSrc: "/images/collections/electronic-14.png",
    imgHoverSrc: "/images/collections/electronic-15.png",
    title: "Wireless On-Ear Headphones for Kids",
    price: 24.99,
    oldPrice: "$34.99",
  },
  {
    id: 590,
    imgSrc: "/images/collections/electronic-16.jpg",
    imgHoverSrc: "/images/collections/electronic-17.jpg",
    title: "3-in-1 Wireless Charger with Official MagSafe Charging 15W",
    price: 127.49,
    oldPrice: "$149.99",
  },
  {
    id: 591,
    imgSrc: "/images/collections/electronic-18.jpg",
    imgHoverSrc: "/images/collections/electronic-19.jpg",
    title: "3-in-1 Wireless Charger for Apple Devices",
    price: 119.99,
  },
];

export const products16 = [
  {
    id: 592,
    imgSrc: "/images/products/setup-gear-1.jpg",
    imgHoverSrc: "/images/products/setup-gear-2.jpg",
    title: "Deltahub Carpio 2.0 Wrist Rest",
    oldPrice: "$54.95",
    price: 40.0,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/setup-gear-1.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/setup-gear-2.jpg",
      },
    ],
  },
  {
    id: 593,
    imgSrc: "/images/products/setup-gear-3.jpg",
    imgHoverSrc: "/images/products/setup-gear-4.jpg",
    title: "Topo Designs Medium Accessory Bag",
    oldPrice: "$33.00",
    price: 25.0,
    colors: [
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/setup-gear-3.jpg",
      },
      {
        name: "Navy",
        colorClass: "bg_navy",
        imgSrc: "/images/products/setup-gear-5.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/setup-gear-6.jpg",
      },
      {
        name: "Dark Green",
        colorClass: "bg_dark-green",
        imgSrc: "/images/products/setup-gear-4.jpg",
      },
    ],
  },
  {
    id: 594,
    imgSrc: "/images/products/setup-gear-7.jpg",
    imgHoverSrc: "/images/products/setup-gear-8.jpg",
    title: "Rumpl Original Puffy 1-Person Blanket",
    oldPrice: "$149.00",
    price: 97.0,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/setup-gear-7.jpg",
      },
      {
        name: "Dark Green",
        colorClass: "bg_dark-green",
        imgSrc: "/images/products/setup-gear-8.jpg",
      },
    ],
  },
  {
    id: 595,
    imgSrc: "/images/products/setup-gear-9.jpg",
    imgHoverSrc: "/images/products/setup-gear-10.jpg",
    title: 'Bellroy 16" Classic Backpack Plus 24L',
    oldPrice: "$289.00",
    price: 190.0,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/setup-gear-9.jpg",
      },
      {
        name: "Navy",
        colorClass: "bg_navy",
        imgSrc: "/images/products/setup-gear-11.jpg",
      },
      {
        name: "Copper",
        colorClass: "bg_copper",
        imgSrc: "/images/products/setup-gear-10.jpg",
      },
    ],
  },
  {
    id: 596,
    imgSrc: "/images/products/setup-gear-12.jpg",
    imgHoverSrc: "/images/products/setup-gear-13.png",
    title: "Bellroy Note Sleeve Wallet",
    price: 139.0,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/setup-gear-12.jpg",
      },
      {
        name: "Dark Green",
        colorClass: "bg_dark-green",
        imgSrc: "/images/products/setup-gear-14.jpg",
      },
      {
        name: "Navy",
        colorClass: "bg_navy",
        imgSrc: "/images/products/setup-gear-13.png",
      },
    ],
  },
  {
    id: 597,
    imgSrc: "/images/products/setup-gear-15.jpg",
    imgHoverSrc: "/images/products/setup-gear-16.jpg",
    title: "Tokyo Folio 8",
    price: 119.0,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/setup-gear-15.jpg",
      },
      {
        name: "Light Grey",
        colorClass: "bg_light-grey",
        imgSrc: "/images/products/setup-gear-16.jpg",
      },
    ],
  },
  {
    id: 598,
    imgSrc: "/images/products/setup-gear-17.jpg",
    imgHoverSrc: "/images/products/setup-gear-18.jpg",
    title: "Bellroy Laptop Sleeve",
    price: 99.0,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/setup-gear-17.jpg",
      },
      {
        name: "Light Grey",
        colorClass: "bg_light-grey",
        imgSrc: "/images/products/setup-gear-18.jpg",
      },
    ],
  },
];

export const products17 = [
  {
    id: 599,
    imgSrc: "/images/products/dog-1.jpg",
    imgHoverSrc: "/images/products/dog-2.jpg",
    title: "Cushioned Dog Harness Moonstone",
    price: 16.95,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/dog-1.jpg",
      },
      {
        name: "Pink",
        colorClass: "bg_pink",
        imgSrc: "/images/products/dog-2.jpg",
      },
      {
        name: "Green",
        colorClass: "bg_light-green",
        imgSrc: "/images/products/dog-3.jpg",
      },
    ],
  },
  {
    id: 600,
    imgSrc: "/images/products/dog-4.jpg",
    imgHoverSrc: "/images/products/dog-5.jpg",
    title: "Stripe Tee Rust and Ivory",
    price: 41.0,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/dog-4.jpg",
      },
      {
        name: "Green",
        colorClass: "bg_light-green",
        imgSrc: "/images/products/dog-5.jpg",
      },
      {
        name: "Red",
        colorClass: "bg_red",
        imgSrc: "/images/products/dog-6.jpg",
      },
    ],
  },
  {
    id: 601,
    imgSrc: "/images/products/dog-7.jpg",
    imgHoverSrc: "/images/products/dog-8.jpg",
    title: "Hooman Nosework Toy Love",
    price: 17.0,
    colors: [
      {
        name: "Dark Green",
        colorClass: "bg_dark-green",
        imgSrc: "/images/products/dog-7.jpg",
      },
      {
        name: "Blue",
        colorClass: "bg_light-blue",
        imgSrc: "/images/products/dog-8.jpg",
      },
      {
        name: "Red",
        colorClass: "bg_red",
        imgSrc: "/images/products/dog-9.jpg",
      },
    ],
  },
  {
    id: 602,
    imgSrc: "/images/products/dog-10.jpg",
    imgHoverSrc: "/images/products/dog-11.jpg",
    title: "The Scot Collar Mint",
    price: 56.0,
    colors: [
      {
        name: "Yellow",
        colorClass: "bg_yellow",
        imgSrc: "/images/products/dog-10.jpg",
      },
      {
        name: "Light Blue",
        colorClass: "bg_light-blue",
        imgSrc: "/images/products/dog-11.jpg",
      },
    ],
  },
  {
    id: 603,
    imgSrc: "/images/products/dog-12.jpg",
    imgHoverSrc: "/images/products/dog-13.jpg",
    title: "Buffalo Bow Tie",
    price: 45.0,
    colors: [],
  },
];

export const products18 = [
  {
    id: 604,
    imgSrc: "/images/products/kitchen-wear-1.jpg",
    imgHoverSrc: "/images/products/kitchen-wear-2.jpg",
    title: "Fry Pan",
    price: 16.95,
    colors: [
      {
        name: "Dark Blue",
        colorClass: "bg_dark-blue",
        imgSrc: "/images/products/kitchen-wear-1.jpg",
      },
      {
        name: "Light Grey",
        colorClass: "bg_light-grey",
        imgSrc: "/images/products/kitchen-wear-3.jpg",
      },
    ],
    filterCategories: ["Best seller", "On Sale"],
  },
  {
    id: 605,
    imgSrc: "/images/products/kitchen-wear-4.jpg",
    imgHoverSrc: "/images/products/kitchen-wear-5.jpg",
    title: "Sauce Pan",
    price: 18.95,
    filterCategories: ["Best seller", "New arrivals"],
  },
  {
    id: 606,
    imgSrc: "/images/products/kitchen-wear-6.jpg",
    imgHoverSrc: "/images/products/kitchen-wear-7.jpg",
    title: "Linen Apron",
    price: 10.0,
    colors: [
      {
        name: "Dark Blue",
        colorClass: "bg_dark-blue",
        imgSrc: "/images/products/kitchen-wear-6.jpg",
      },
      {
        name: "Cream",
        colorClass: "bg_cream",
        imgSrc: "/images/products/kitchen-wear-8.jpg",
      },
      {
        name: "Orange",
        colorClass: "bg_orange",
        imgSrc: "/images/products/kitchen-wear-9.jpg",
      },
    ],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
  },
  {
    id: 607,
    imgSrc: "/images/products/kitchen-wear-10.jpg",
    imgHoverSrc: "/images/products/kitchen-wear-11.jpg",
    title: "Whistling Tea Kettle",
    price: 16.95,
    onSale: true,
    filterCategories: ["Best seller", "On Sale"],
  },
  {
    id: 608,
    imgSrc: "/images/products/kitchen-wear-12.jpg",
    imgHoverSrc: "/images/products/kitchen-wear-13.jpg",
    title: "Food Storage Set",
    price: 16.95,
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
  },
];

export const products19 = [
  {
    id: 609,
    imgSrc: "/images/item/lookbook-item-1.jpg",
    imgAlt: "",
    title: "Fry Pan",
    price: 150.0,
    variants: ["Dark Blue", "Light Grey"],
  },
  {
    id: 610,
    imgSrc: "/images/item/lookbook-item-2.jpg",
    imgAlt: "",
    title: "Whistling Tea Kettle",
    compareAtPrice: "$105.00",
    price: 95.0,
  },
  {
    id: 611,
    imgSrc: "/images/item/lookbook-item-3.jpg",
    imgAlt: "",
    title: "Linen Apron",
    price: 95.0,
    variants: ["Dark Blue", "Cream", "Orange"],
  },
];

export const products20 = [
  {
    id: 612,
    imgSrc: "/images/products/phonecase-1.jpg",
    imgHoverSrc: "/images/products/phonecase-2.jpg",
    title: "Almond Latte - Cute iPhone Case",
    price: 30.0,
  },
  {
    id: 613,
    imgSrc: "/images/products/phonecase-3.jpg",
    imgHoverSrc: "/images/products/phonecase-4.jpg",
    title: "Full Glam - Beige iPhone Case",
    price: 30.0,
  },
  {
    id: 614,
    imgSrc: "/images/products/phonecase-5.jpg",
    imgHoverSrc: "/images/products/phonecase-6.jpg",
    title: "Emerald Pool - Elegant iPhone case",
    price: 30.0,
  },
  {
    id: 615,
    imgSrc: "/images/products/phonecase-7.jpg",
    imgHoverSrc: "/images/products/phonecase-8.jpg",
    title: "Femme Fatale - iPhone Case",
    price: 30.0,
  },
  {
    id: 616,
    imgSrc: "/images/products/phonecase-9.jpg",
    imgHoverSrc: "/images/products/phonecase-10.jpg",
    title: "Cherrybomb - iPhone Case",
    price: 30.0,
  },
  {
    id: 617,
    imgSrc: "/images/products/phonecase-11.jpg",
    imgHoverSrc: "/images/products/phonecase-12.jpg",
    title: "Trouble - iPhone Case",
    price: 30.0,
  },
];

export const products21 = [
  {
    id: 618,
    imgSrc: "/images/products/paddle-boards-7.jpg",
    imgHoverSrc: "/images/products/paddle-boards-1-small.jpg",
    vendor: "Ecomus X- Class",
    title: "CRUISER SERIES - X",
    colors: [
      {
        name: "blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/paddle-boards-1-small.jpg",
      },
      {
        name: "warm tan",
        colorClass: "bg_yellow-6",
        imgSrc: "/images/products/paddle-boards-2-small.jpg",
      },
      {
        name: "white",
        colorClass: "bg_white",
        imgSrc: "/images/products/paddle-boards-3-small.jpg",
      },
      {
        name: "marble blue",
        colorClass: "bg_green-5",
        imgSrc: "/images/products/paddle-boards-4-small.jpg",
      },
    ],
    price: 138.0,
    features: [
      "Build: Inflatable",
      "Skill: Beginner-Advanced",
      "Use: All Around",
      "Weight: 19.8 lbs",
      "Sizes: 10’6″x32″x6″",
      "Warranty: 1-year",
    ],
    new: true,
  },
  {
    id: 619,
    imgSrc: "/images/products/paddle-boards-6.jpg",
    imgHoverSrc: "/images/products/paddle-boards-1-small.jpg",
    vendor: "Ecomus X- Class",
    title: "PRO SERIES - X",
    colors: [
      {
        name: "blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/paddle-boards-1-small.jpg",
      },
      {
        name: "warm tan",
        colorClass: "bg_yellow-6",
        imgSrc: "/images/products/paddle-boards-2-small.jpg",
      },
      {
        name: "white",
        colorClass: "bg_white",
        imgSrc: "/images/products/paddle-boards-3-small.jpg",
      },
      {
        name: "marble blue",
        colorClass: "bg_green-5",
        imgSrc: "/images/products/paddle-boards-4-small.jpg",
      },
    ],
    price: 138.0,
    features: [
      "Build: Inflatable",
      "Skill: Beginner-Advanced",
      "Use: All Around",
      "Weight: 19.8 lbs",
      "Sizes: 10’6″x32″x6″",
      "Warranty: 2-year",
    ],
    populer: true,
  },
  {
    id: 620,
    imgSrc: "/images/products/paddle-boards-5.jpg",
    imgHoverSrc: "/images/products/paddle-boards-1-small.jpg",
    vendor: "Ecomus X- Class",
    title: "TOURER SERIES - X",
    colors: [
      {
        name: "blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/paddle-boards-1-small.jpg",
      },
      {
        name: "warm tan",
        colorClass: "bg_yellow-6",
        imgSrc: "/images/products/paddle-boards-2-small.jpg",
      },
      {
        name: "white",
        colorClass: "bg_white",
        imgSrc: "/images/products/paddle-boards-3-small.jpg",
      },
      {
        name: "marble blue",
        colorClass: "bg_green-5",
        imgSrc: "/images/products/paddle-boards-4-small.jpg",
      },
    ],
    price: 138.0,
    features: [
      "Build: Inflatable",
      "Skill: Beginner-Advanced",
      "Use: All Around",
      "Weight: 19.8 lbs",
      "Sizes: 10’6″x32″x6″",
      "Warranty: 3-year",
    ],
    recommend: true,
  },
];

export const products22 = [
  {
    id: 621,
    imgSrc: "/images/products/paddle-boards-2.jpg",
    imgHoverSrc: "/images/products/paddle-boards-1.jpg",
    title: "ALL AROUND 11' ULTRA™",
    price: 138.0,
    colors: [
      {
        name: "blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/paddle-boards-1.jpg",
      },
      {
        name: "warm tan",
        colorClass: "bg_yellow-6",
        imgSrc: "/images/products/paddle-boards-2.jpg",
      },
      {
        name: "white",
        colorClass: "bg_white",
        imgSrc: "/images/products/paddle-boards-3.jpg",
      },
      {
        name: "marble blue",
        colorClass: "bg_green-5",
        imgSrc: "/images/products/paddle-boards-4.jpg",
      },
    ],
  },
  {
    id: 622,
    imgSrc: "/images/products/paddle-boards-9.jpg",
    imgHoverSrc: "/images/products/paddle-boards-10.jpg",
    title: "ISUP PADDLE BOARD",
    price: 138.0,
    colors: [],
  },
  {
    id: 623,
    imgSrc: "/images/products/paddle-boards-11.jpg",
    imgHoverSrc: "/images/products/paddle-boards-12.jpg",
    title: "KAYAK ISUP PADDLE ADAPTER",
    price: 138.0,
    colors: [],
  },
  {
    id: 624,
    imgSrc: "/images/products/paddle-boards-8.jpg",
    imgHoverSrc: "/images/products/paddle-boards-1.jpg",
    title: "ALL AROUND 11' ULTRA™",
    price: 138.0,
    colors: [
      {
        name: "blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/paddle-boards-8.jpg",
      },
      {
        name: "warm tan",
        colorClass: "bg_yellow-6",
        imgSrc: "/images/products/paddle-boards-2.jpg",
      },
      {
        name: "white",
        colorClass: "bg_white",
        imgSrc: "/images/products/paddle-boards-3.jpg",
      },
      {
        name: "marble blue",
        colorClass: "bg_green-5",
        imgSrc: "/images/products/paddle-boards-4.jpg",
      },
    ],
  },
  {
    id: 625,
    imgSrc: "/images/products/paddle-boards-9.jpg",
    imgHoverSrc: "/images/products/paddle-boards-10.jpg",
    title: "ISUP PADDLE BOARD",
    price: 138.0,
    colors: [],
  },
];

export const products23 = [
  {
    id: 626,
    imgSrc: "/images/products/glasses-1.jpg",
    imgHoverSrc: "/images/products/glasses-2.jpg",
    alt: "CAT EYE GLASSES",
    title: "CAT EYE GLASSES",
    price: 12.99,
    colors: [
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/glasses-1.jpg",
      },
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/glasses-3.jpg",
      },
    ],
    filterCategories: ["NEW RELEASES"],
  },
  {
    id: 627,
    imgSrc: "/images/products/glasses-4.jpg",
    imgHoverSrc: "/images/products/glasses-5.jpg",
    alt: "FASHION GLASSES",
    title: "FASHION GLASSES",
    price: 30,
    colors: [
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/glasses-4.jpg",
      },
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/glasses-6.jpg",
      },
    ],
    filterCategories: ["NEW RELEASES", "BEST SELLERS"],
  },
  {
    id: 628,
    imgSrc: "/images/products/glasses-7.jpg",
    imgHoverSrc: "/images/products/glasses-8.jpg",
    alt: "PERSONALIZED FASHION GLASSES",
    title: "PERSONALIZED FASHION GLASSES",
    price: 30,
    colors: [
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/glasses-7.jpg",
      },
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/glasses-9.jpg",
      },
    ],
    filterCategories: ["NEW RELEASES"],
  },
  {
    id: 629,
    imgSrc: "/images/products/glasses-10.jpg",
    imgHoverSrc: "/images/products/glasses-11.jpg",
    alt: "FASHION EYE GLASSES",
    title: "FASHION EYE GLASSES",
    price: 30,
    colors: [
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/glasses-10.jpg",
      },
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/glasses-12.jpg",
      },
    ],
    filterCategories: ["NEW RELEASES", "BEST SELLERS"],
  },
  {
    id: 630,
    imgSrc: "/images/products/glasses-13.jpg",
    imgHoverSrc: "/images/products/glasses-14.jpg",
    alt: "FASHION EYE GLASSES V2",
    title: "FASHION EYE GLASSES V2",
    price: 30,
    colors: [
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/glasses-13.jpg",
      },
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/glasses-15.jpg",
      },
    ],
    filterCategories: ["NEW RELEASES", "BEST SELLERS"],
  },
  {
    id: 631,
    imgSrc: "/images/products/glasses-16.jpg",
    imgHoverSrc: "/images/products/glasses-17.jpg",
    alt: "FASHION EYE GLASSES V3",
    title: "FASHION EYE GLASSES V3",
    price: 30,
    colors: [
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/glasses-16.jpg",
      },
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/glasses-18.jpg",
      },
    ],
    filterCategories: ["NEW RELEASES", "BEST SELLERS"],
  },
];

export const products24 = [
  {
    id: 632,
    imgSrc: "/images/shop/gallery/gallery-17.jpg",
    alt: "image-gallery",
    wowDelay: "0s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 633,
    imgSrc: "/images/shop/gallery/gallery-18.jpg",
    alt: "image-gallery",
    wowDelay: ".1s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 634,
    imgSrc: "/images/shop/gallery/gallery-19.jpg",
    alt: "image-gallery",
    wowDelay: ".2s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 635,
    imgSrc: "/images/shop/gallery/gallery-20.jpg",
    alt: "image-gallery",
    wowDelay: ".3s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 636,
    imgSrc: "/images/shop/gallery/gallery-21.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 637,
    imgSrc: "/images/shop/gallery/gallery-22.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 638,
    imgSrc: "/images/shop/gallery/gallery-23.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 639,
    imgSrc: "/images/shop/gallery/gallery-24.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
];

export const products25 = [
  {
    id: 640,
    imgSrc: "/images/products/pod-store-1.jpg",
    imgHoverSrc: "/images/products/pod-store-1.jpg",
    title: "Bound To Things Up T-Shirt",
    price: 48.0,
    colors: [
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/pod-store-1.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/pod-store-4.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/pod-store-3.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
    filterCategories: ["Best seller", "On Sale"],
  },
  {
    id: 641,
    imgSrc: "/images/products/pod-store-5.jpg",
    imgHoverSrc: "/images/products/pod-store-6.jpg",
    title: "Empathy Art Print",
    price: 52.0,
    colors: [
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/pod-store-5.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/pod-store-6.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/pod-store-7.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
  },
  {
    id: 642,
    imgSrc: "/images/products/pod-store-8.jpg",
    imgHoverSrc: "/images/products/pod-store-9.jpg",
    title: "Lazy Days T-Shirt",
    price: 52.0,
    colors: [
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/pod-store-8.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/pod-store-9.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/pod-store-10.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
    filterCategories: ["Best seller", "New arrivals"],
  },
  {
    id: 643,
    imgSrc: "/images/products/pod-store-11.jpg",
    imgHoverSrc: "/images/products/pod-store-12.jpg",
    title: "Empathy Hoodie",
    price: 52.0,
    colors: [
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/pod-store-11.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/pod-store-12.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/pod-store-13.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
  },
  {
    id: 644,
    imgSrc: "/images/products/pod-store-14.jpg",
    imgHoverSrc: "/images/products/pod-store-15.jpg",
    title: "Empathy Crop Tee",
    price: 56.0,
    colors: [
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/pod-store-14.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/pod-store-15.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
  },
];

export const products26 = [
  {
    id: 645,
    imgSrc: "/images/products/pod-store-16.jpg",
    imgHoverSrc: "/images/products/pod-store-17.png",
    title: "Slasher T-Shirt",
    price: 30.0,
  },
  {
    id: 646,
    imgSrc: "/images/products/pod-store-18.jpg",
    imgHoverSrc: "/images/products/pod-store-19.jpg",
    title: "Santa Sleigher T-Shirt",
    price: 30.0,
  },
  {
    id: 647,
    imgSrc: "/images/products/pod-store-20.jpg",
    imgHoverSrc: "/images/products/pod-store-21.jpg",
    title: "Horror Movies and Chill T-Shirt",
    price: 30.0,
  },
  {
    id: 648,
    imgSrc: "/images/products/pod-store-22.jpg",
    imgHoverSrc: "/images/products/pod-store-23.jpg",
    title: "No You Hang Up T-Shirt",
    price: 30.0,
  },
  {
    id: 649,
    imgSrc: "/images/products/pod-store-24.jpg",
    imgHoverSrc: "/images/products/pod-store-25.jpg",
    title: "Teletubbies T-Shirt",
    price: 30.0,
  },
];

export const products27 = [
  {
    id: 650,
    imgSrc: "/images/products/brown-11.jpg",
    imgHoverSrc: "/images/products/brown-12.jpg",
    title: "Crossover Leggings",
    price: 38.0,
    colors: [
      {
        name: "Brown",
        colorClass: "bg_brown",
        imgSrc: "/images/products/brown-11.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-24.jpg",
      },
      {
        name: "Pink",
        colorClass: "bg_pink",
        imgSrc: "/images/products/pink-4.jpg",
      },
      {
        name: "Purple",
        colorClass: "bg_purple",
        imgSrc: "/images/products/purple-2.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
  },
  {
    id: 651,
    imgSrc: "/images/products/blue-5.jpg",
    imgHoverSrc: "/images/products/blue-6.jpg",
    title: "Pocket Shorts",
    price: 38.0,
    colors: [
      {
        name: "Blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/blue-5.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-25.jpg",
      },
      {
        name: "Red",
        colorClass: "bg_red",
        imgSrc: "/images/products/red.jpg",
      },
      {
        name: "Brown",
        colorClass: "bg_brown",
        imgSrc: "/images/products/brown-13.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
  },
  {
    id: 652,
    imgSrc: "/images/products/white-17.jpg",
    imgHoverSrc: "/images/products/white-18.jpg",
    title: "Ruched Strappy Sports Bra",
    price: 38.0,
    colors: [
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/white-17.jpg",
      },
      {
        name: "Green",
        colorClass: "bg_green",
        imgSrc: "/images/products/green-2.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-26.jpg",
      },
      {
        name: "Pink",
        colorClass: "bg_pink",
        imgSrc: "/images/products/pink-5.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
  },
  {
    id: 653,
    imgSrc: "/images/products/grey-3.jpg",
    imgHoverSrc: "/images/products/grey-4.jpg",
    title: "Vital Seamless 2.0 V Neck Sports Bra",
    price: 38.0,
    colors: [
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/grey-3.jpg",
      },
      {
        name: "Green",
        colorClass: "bg_green",
        imgSrc: "/images/products/green-3.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-27.jpg",
      },
      {
        name: "Light Green",
        colorClass: "bg_light-green",
        imgSrc: "/images/products/light-green-6.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
  },
  {
    id: 654,
    imgSrc: "/images/products/white-19.jpg",
    imgHoverSrc: "/images/products/white-20.jpg",
    title: "Adapt Fleck Seamless Long Sleever Crop Top",
    price: 38.0,
    colors: [
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/white-19.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-28.jpg",
      },
      {
        name: "Brown",
        colorClass: "bg_brown",
        imgSrc: "/images/products/brown-14.jpg",
      },
      {
        name: "Green",
        colorClass: "bg_green",
        imgSrc: "/images/products/green-4.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
  },
  {
    id: 655,
    imgSrc: "/images/products/black-29.jpg",
    imgHoverSrc: "/images/products/black-30.jpg",
    title: "Product Title 5",
    price: 38.0,

    sizes: ["S", "M", "L", "XL"],
  },
];

export const products28 = [
  {
    id: 656,
    imgSrc: "/images/products/brown-15.jpg",
    imgHoverSrc: "/images/products/sports-clothing-2.jpg",

    title: "Pocket Shorts",
    price: 38.0,
    colors: [
      {
        name: "Brown",
        colorClass: "bg_brown",
        imgSrc: "/images/products/brown-15.jpg",
      },
      {
        name: "Green",
        colorClass: "bg_green",
        imgSrc: "/images/products/green-6.jpg",
      },
      {
        name: "Red",
        colorClass: "bg_red",
        imgSrc: "/images/products/sports-clothing.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
    filterCategories: ["Men", "Women"],
  },
  {
    id: 657,
    imgSrc: "/images/products/sports-clothing-3.jpg",
    imgHoverSrc: "/images/products/sports-clothing-4.jpg",

    title: "Essential Oversized Zip Up Hoodie",
    price: 38.0,
    colors: [
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/sports-clothing-3.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-31.jpg",
      },
      {
        name: "Blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/blue-8.jpg",
      },
      {
        name: "Brown",
        colorClass: "bg_brown",
        imgSrc: "/images/products/brown-16.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
    filterCategories: ["Men"],
  },
  {
    id: 658,
    imgSrc: "/images/products/sports-clothing-6.jpg",
    imgHoverSrc: "/images/products/sports-clothing-5.jpg",

    title: 'Crest 7" Shorts',
    price: 38.0,
    colors: [
      {
        name: "Blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/sports-clothing-6.jpg",
      },
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/grey-5.jpg",
      },
      {
        name: "Brown",
        colorClass: "bg_brown",
        imgSrc: "/images/products/brown-17.jpg",
      },
      {
        name: "Light Blue",
        colorClass: "bg_light-blue",
        imgSrc: "/images/products/light-blue-5.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
    filterCategories: ["Men", "Women"],
  },
  {
    id: 659,
    imgSrc: "/images/products/sports-clothing-7.jpg",
    imgHoverSrc: "/images/products/sports-clothing-8.jpg",

    title: "Crest Long Sleeve T-Shirt",
    price: 38.0,
    colors: [
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/sports-clothing-7.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/white-21.jpg",
      },
      {
        name: "Brown",
        colorClass: "bg_brown",
        imgSrc: "/images/products/brown-18.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-32.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
    filterCategories: ["Men", "Women"],
  },
  {
    id: 660,
    imgSrc: "/images/products/t-shirt-15.jpg",
    imgHoverSrc: "/images/products/t-shirt-16.jpg",

    title: "Crest T-Shirt",
    price: 38.0,
    colors: [
      {
        name: "Light Blue",
        colorClass: "bg_light-blue",
        imgSrc: "/images/products/t-shirt-15.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/white-22.jpg",
      },
      {
        name: "Green",
        colorClass: "bg_green",
        imgSrc: "/images/products/green-7.jpg",
      },
      {
        name: "Brown",
        colorClass: "bg_brown",
        imgSrc: "/images/products/brown-19.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
    filterCategories: ["Men", "Women"],
  },
];

export const products29 = [
  {
    id: 661,
    imgSrc: "/images/products/white-17.jpg",
    title: "Ruched Strappy Sports Bra",
    price: 38.0,
    options: [
      "S / White",
      "S / Green",
      "S / Black",
      "S / Pink",
      "M / White",
      "M / Green",
      "M / Black",
      "M / Pink",
      "L / White",
      "L / Green",
      "L / Black",
      "L / Pink",
      "XL / White",
      "XL / Green",
      "XL / Black",
      "XL / Pink",
    ],
  },
  {
    id: 662,
    imgSrc: "/images/products/blue-5.jpg",
    title: "Pocket Shorts",
    price: 38.0,
    options: [
      "S / Blue",
      "S / Green",
      "S / Black",
      "S / Pink",
      "M / White",
      "M / Green",
      "M / Black",
      "M / Pink",
      "L / White",
      "L / Green",
      "L / Black",
      "L / Pink",
      "XL / White",
      "XL / Green",
      "XL / Black",
      "XL / Pink",
    ],
  },
  {
    id: 663,
    imgSrc: "/images/products/brown-11.jpg",
    title: "Crossover Leggings",
    price: 38.0,
    options: [
      "Brown / S",
      "Beige / M",
      "Black / S",
      "Black / M",
      "Black / L",
      "Black / XL",
      "Blue / S",
      "Blue / M",
      "Blue / L",
      "Blue / XL",
      "White / S",
      "White / M",
      "White / L",
      "White / XL",
    ],
  },
];

export const products30 = [
  {
    id: 664,
    imgSrc: "/images/shop/gallery/activewear-gallery1.jpg",
    alt: "image-gallery",
    wowDelay: "0s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 665,
    imgSrc: "/images/shop/gallery/activewear-gallery2.jpg",
    alt: "image-gallery",
    wowDelay: ".1s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 666,
    imgSrc: "/images/shop/gallery/activewear-gallery3.jpg",
    alt: "image-gallery",
    wowDelay: ".2s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 667,
    imgSrc: "/images/shop/gallery/activewear-gallery4.jpg",
    alt: "image-gallery",
    wowDelay: ".3s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 668,
    imgSrc: "/images/shop/gallery/activewear-gallery5.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 669,
    imgSrc: "/images/shop/gallery/activewear-gallery6.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
];

export const products31 = [
  {
    id: 670,
    imgSrc: "/images/products/green-handbag-1.jpg",
    imgHoverSrc: "/images/products/green-handbag-2.jpg",
    title: "Amanda - Green",
    price: 138.0,
  },
  {
    id: 671,
    imgSrc: "/images/products/brown-handbag-1.jpg",
    imgHoverSrc: "/images/products/brown-handbag-2.jpg",
    title: "Grace - Brown",
    price: 138.0,
  },
  {
    id: 672,
    imgSrc: "/images/products/pink-handbag-1.jpg",
    imgHoverSrc: "/images/products/pink-handbag-2.jpg",
    title: "Alice Mini - Dusty Rose",
    price: 138.0,
  },
];

export const products32 = [
  {
    id: 673,
    imgSrc: "/images/shop/gallery/handbag-gallery-1.jpg",
    alt: "image-gallery",
    wowDelay: ".2s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 674,
    imgSrc: "/images/shop/gallery/handbag-gallery-2.jpg",
    alt: "image-gallery",
    wowDelay: ".3s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 675,
    imgSrc: "/images/shop/gallery/handbag-gallery-3.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 676,
    imgSrc: "/images/shop/gallery/handbag-gallery-4.jpg",
    alt: "image-gallery",
    wowDelay: ".5s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 677,
    imgSrc: "/images/shop/gallery/handbag-gallery-5.jpg",
    alt: "image-gallery",
    wowDelay: ".6s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 678,
    imgSrc: "/images/shop/gallery/handbag-gallery-2.jpg",
    alt: "image-gallery",
    wowDelay: ".3s",
    quickAdd: true,
    price: 29.66,
    title: "demo title",
  },
];

export const products33 = [
  {
    id: 679,
    imgSrc: "/images/products/t-shirt.jpg",
    imgHoverSrc: "/images/products/t-shirt-2.jpg",
    title: "Amazing Grace Mineral Wash Premium Tee",
    price: 39.0,
  },
  {
    id: 680,
    imgSrc: "/images/products/t-shirt-3.jpg",
    imgHoverSrc: "/images/products/t-shirt-4.jpg",
    title: "God Behind Bars 'God's Purpose' Sand Tee",
    price: 38.0,
  },
  {
    id: 681,
    imgSrc: "/images/products/t-shirt-7.jpg",
    imgHoverSrc: "/images/products/t-shirt-8.jpg",
    title: "V.3 Mentality Tee",
    price: 39.0,
  },
  {
    id: 682,
    imgSrc: "/images/products/hat.jpg",
    imgHoverSrc: "/images/products/hat-2.jpg",
    title: '"The Pack" Beanie',
    price: 39.0,
  },
  {
    id: 683,
    imgSrc: "/images/products/men-hoodie-3.jpg",
    imgHoverSrc: "/images/products/men-hoodie-4.jpg",
    title: "Finish The Fight Tie Dye Sweatshirt",
    price: 39.0,
  },
  {
    id: 684,
    imgSrc: "/images/products/t-shirt-9.jpg",
    imgHoverSrc: "/images/products/t-shirt-10.jpg",
    title: "Y'all Tee - Black",
    price: 39.0,
  },
  {
    id: 685,
    imgSrc: "/images/products/t-shirt-5.jpg",
    imgHoverSrc: "/images/products/t-shirt-6.jpg",
    title: "Shein Ezwear Shoulder Tee",
    price: 39.0,
  },
  {
    id: 686,
    imgSrc: "/images/products/men-hoodie.jpg",
    imgHoverSrc: "/images/products/men-hoodie-2.jpg",
    title: '"It Is Finished" Bleach Wash Hoodie',
    price: 38.0,
  },
];

export const products34 = [
  {
    id: 687,
    imgSrc: "/images/products/men-hoodie-5.jpg",
    imgHoverSrc: "/images/products/men-hoodie-6.jpg",
    title: "Gratitude Hoodie",
    price: 48.0,
  },
  {
    id: 688,
    imgSrc: "/images/products/t-shirt-5.jpg",
    imgHoverSrc: "/images/products/t-shirt-6.jpg",
    title: "Shein Ezwear Shoulder Tee",
    price: 39.0,
  },
  {
    id: 689,
    imgSrc: "/images/products/t-shirt-11.jpg",
    imgHoverSrc: "/images/products/t-shirt-12.jpg",
    title: "Y'all Tee - Black",
    price: 38.0,
  },
  {
    id: 690,
    imgSrc: "/images/products/t-shirt-13.jpg",
    imgHoverSrc: "/images/products/t-shirt-14.jpg",
    title: "Y'all Tee - Blush",
    price: 38.0,
  },
  {
    id: 691,
    imgSrc: "/images/products/men-hoodie-7.jpg",
    imgHoverSrc: "/images/products/men-hoodie-8.jpg",
    title: "Victory Crewneck",
    price: 38.0,
  },
  {
    id: 692,
    imgSrc: "/images/products/men-hoodie.jpg",
    imgHoverSrc: "/images/products/men-hoodie-2.jpg",
    title: '"It Is Finished" Bleach Wash Hoodie',
    price: 38.0,
  },
];

export const products35 = [
  {
    id: 693,
    imgSrc: "/images/shop/gallery/tee-gallery-1.jpg",
    alt: "image-gallery",
    wowDelay: ".2s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 694,
    imgSrc: "/images/shop/gallery/tee-gallery-2.jpg",
    alt: "image-gallery",
    wowDelay: ".3s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 695,
    imgSrc: "/images/shop/gallery/tee-gallery-3.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 696,
    imgSrc: "/images/shop/gallery/tee-gallery-4.jpg",
    alt: "image-gallery",
    wowDelay: ".5s",
    quickAdd: false,
    price: 29.66,
  },
  {
    id: 697,
    imgSrc: "/images/shop/gallery/tee-gallery-5.jpg",
    alt: "image-gallery",
    wowDelay: ".6s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 698,
    imgSrc: "/images/shop/gallery/tee-gallery-6.jpg",
    alt: "image-gallery",
    wowDelay: ".6s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 699,
    imgSrc: "/images/shop/gallery/tee-gallery-7.jpg",
    alt: "image-gallery",
    wowDelay: ".6s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 700,
    imgSrc: "/images/shop/gallery/tee-gallery-8.jpg",
    alt: "image-gallery",
    wowDelay: ".6s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
];

export const products36 = [
  {
    id: 701,
    imgSrc: "/images/products/sock1.jpg",
    imgHoverSrc: "/images/products/sock2.jpg",
    title: "Skarpetki Love is Love",
    price: 39,
  },
  {
    id: 702,
    imgSrc: "/images/products/sock3.jpg",
    imgHoverSrc: "/images/products/sock4.jpg",
    title: "Skarpetki Planets",
    price: 39,
  },
  {
    id: 703,
    imgSrc: "/images/products/sock5.jpg",
    imgHoverSrc: "/images/products/sock6.jpg",
    title: "Skarpetki Hotter than Tabasco",
    price: 39,
  },
  {
    id: 704,
    imgSrc: "/images/products/sock7.jpg",
    imgHoverSrc: "/images/products/sock8.jpg",
    title: "Skarpetki About Love",
    price: 70,
  },
  {
    id: 705,
    imgSrc: "/images/products/sock9.jpg",
    imgHoverSrc: "/images/products/sock10.jpg",
    title: "Skarpetki Water Sport",
    price: 9,
  },
  {
    id: 706,
    imgSrc: "/images/products/sock11.jpg",
    imgHoverSrc: "/images/products/sock12.jpg",
    title: "Pacific Performance Socks",
    price: 39,
  },
  {
    id: 707,
    imgSrc: "/images/products/sock13.jpg",
    imgHoverSrc: "/images/products/sock14.jpg",
    title: "Pacific and Co Smile Club Socks",
    price: 39,
  },
];

export const products37 = [
  {
    id: 708,
    imgSrc: "/images/shop/gallery/sock-gallery1.jpg",
    wowDelay: ".2s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 709,
    imgSrc: "/images/shop/gallery/sock-gallery2.jpg",
    wowDelay: ".3s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 710,
    imgSrc: "/images/shop/gallery/sock-gallery3.jpg",
    wowDelay: ".4s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 711,
    imgSrc: "/images/shop/gallery/sock-gallery4.jpg",
    wowDelay: ".5s",
    quickAdd: false,
    tooltip: "View product",
    title: "demo title",
    price: 29.66,
  },
  {
    id: 712,
    imgSrc: "/images/shop/gallery/sock-gallery5.jpg",
    wowDelay: ".6s",
    quickAdd: false,
    tooltip: "View product",
    title: "demo title",
    price: 29.66,
  },
];

export const products38 = [
  {
    id: 713,
    imgSrc: "/images/products/jewerly-1.jpg",
    imgHoverSrc: "/images/products/jewerly-2.jpg",
    title: "Pearl Ring - Lea",
    price: 138.0,
    colors: [
      {
        name: "Golden Yellow",
        colorClass: "bg_golden-yellow",
        imgSrc: "/images/products/jewerly-1.jpg",
      },
      {
        name: "Silver White",
        colorClass: "bg_white",
        imgSrc: "/images/products/jewerly-2.jpg",
      },
    ],
  },
  {
    id: 714,
    imgSrc: "/images/products/jewerly-3.jpg",
    imgHoverSrc: "/images/products/jewerly-4.jpg",
    title: "Trillium Studs",
    price: 138.0,
    colors: [
      {
        name: "Golden Yellow",
        colorClass: "bg_golden-yellow",
        imgSrc: "/images/products/jewerly-3.jpg",
      },
    ],
  },
  {
    id: 715,
    imgSrc: "/images/products/jewerly-5.jpg",
    imgHoverSrc: "/images/products/jewerly-6.jpg",
    title: "Disc Necklace - Mari",
    price: 138.0,
    colors: [
      {
        name: "Golden Yellow",
        colorClass: "bg_golden-yellow",
        imgSrc: "/images/products/jewerly-5.jpg",
      },
      {
        name: "Dark Green",
        colorClass: "bg_dark-green",
        imgSrc: "/images/products/jewerly-6.jpg",
      },
    ],
  },
  {
    id: 716,
    imgSrc: "/images/products/jewerly-7.jpg",
    imgHoverSrc: "/images/products/jewerly-8.jpg",
    title: "Mother of Pearl Ring - Birgit",
    price: 138.0,
    colors: [
      {
        name: "Golden Yellow",
        colorClass: "bg_golden-yellow",
        imgSrc: "/images/products/jewerly-7.jpg",
      },
    ],
  },
  {
    id: 717,
    imgSrc: "/images/products/jewerly-9.jpg",
    imgHoverSrc: "/images/products/jewerly-10.jpg",
    title: "Three Stone Ring - Edith",
    price: 138.0,
    colors: [],
  },
];

export const products39 = [
  {
    id: 718,
    imgSrc: "/images/products/sneaker-3.png",
    imgHoverSrc: "/images/products/sneaker-4.png",
    alt: "image-product",
    title: "POLAR NIGHT PACK PACK 2 FUSION 2.0",
    price: 144.95,
    colors: [
      {
        colorClass: "bg_dark",
        imgSrc: "/images/products/sneaker-3.png",
        tooltip: "Black",
      },
      {
        colorClass: "bg_white",
        imgSrc: "/images/products/sneaker-4.png",
        tooltip: "White",
      },
    ],
  },
  {
    id: 719,
    imgSrc: "/images/products/sneaker-5.png",
    imgHoverSrc: "/images/products/sneaker-6.png",
    alt: "image-product",
    title: "SHADOW 6000 BEIGE",
    price: 144.95,
    colors: [
      {
        colorClass: "bg_dark",
        imgSrc: "/images/products/sneaker-5.png",
        tooltip: "Black",
      },
      {
        colorClass: "bg_white",
        imgSrc: "/images/products/sneaker-6.png",
        tooltip: "White",
      },
    ],
  },
  {
    id: 720,
    imgSrc: "/images/products/sneaker-7.jpg",
    imgHoverSrc: "/images/products/sneaker-8.jpg",
    alt: "image-product",
    title: "RIVALRY 86 LOW WONDER",
    price: 144.95,
    colors: [
      {
        colorClass: "bg_dark",
        imgSrc: "/images/products/sneaker-7.jpg",
        tooltip: "Black",
      },
      {
        colorClass: "bg_white",
        imgSrc: "/images/products/sneaker-8.jpg",
        tooltip: "White",
      },
    ],
  },
  {
    id: 721,
    imgSrc: "/images/products/sneaker-9.png",
    imgHoverSrc: "/images/products/sneaker-10.png",
    alt: "image-product",
    title: "SAMBA OG SHOES",
    price: 144.95,
    colors: [
      {
        colorClass: "bg_dark",
        imgSrc: "/images/products/sneaker-9.png",
        tooltip: "Black",
      },
      {
        colorClass: "bg_white",
        imgSrc: "/images/products/sneaker-10.png",
        tooltip: "White",
      },
    ],
  },
];

export const products40 = [
  {
    id: 722,
    imgSrc: "/images/products/black-4.jpg",
    imgHoverSrc: "/images/products/black-5.jpg",
    alt: "image-product",
    title: "Regular Fit Oxford Shirt",
    oldPrice: "$36.00",
    price: 25.0,
    sizes: ["3 sizes available"],
    sale: "-31%",
    colors: [
      {
        name: "Orange",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-4.jpg",
      },
      {
        name: "Dark Blue",
        colorClass: "bg_dark-blue",
        imgSrc: "/images/products/dark-blue-2.jpg",
      },
      {
        name: "Beige",
        colorClass: "bg_beige",
        imgSrc: "/images/products/beige.jpg",
      },
      {
        name: "Light Blue",
        colorClass: "bg_light-blue",
        imgSrc: "/images/products/light-blue.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/white-7.jpg",
      },
    ],
    filterCategories: ["Best seller", "Graphics", "Jeans"],
  },
  {
    id: 723,
    imgSrc: "/images/products/light-green-1.jpg",
    imgHoverSrc: "/images/products/light-green-2.jpg",
    alt: "image-product",
    title: "Loose Fit Sweatshirt",
    price: 25.0,
    sizes: ["M", "L", "XL"],
    colors: [
      {
        name: "Light Green",
        colorClass: "bg_light-green",
        imgSrc: "/images/products/light-green-1.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-3.jpg",
      },
      {
        name: "Blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/blue.jpg",
      },
      {
        name: "Dark Blue",
        colorClass: "bg_dark-blue",
        imgSrc: "/images/products/dark-blue.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/white-6.jpg",
      },
      {
        name: "Light Grey",
        colorClass: "bg_light-grey",
        imgSrc: "/images/products/light-grey.jpg",
      },
    ],
    filterCategories: ["Best seller", "Sale", "Jeans"],
  },
  {
    id: 724,
    imgSrc: "/images/products/white-8.jpg",
    imgHoverSrc: "/images/products/black-6.jpg",
    alt: "image-product",
    title: "Loose Fit Hoodie",
    price: 10.0,
    sizes: ["4 sizes available"],
    colors: [
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/white-8.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-7.jpg",
      },
      {
        name: "Blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/blue-2.jpg",
      },
    ],
    filterCategories: ["Best seller", "Sale", "Graphics"],
  },
  {
    id: 725,
    imgSrc: "/images/products/black-9.jpg",
    imgHoverSrc: "/images/products/black-10.jpg",
    alt: "image-product",
    title: "Slim Fit Fine-knit Turtleneck Sweater",
    price: 20.0,
    sizes: ["S", "M", "L", "XL"],
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-9.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/white-9.jpg",
      },
    ],
    filterCategories: ["Best seller", "Sale", "Graphics", "Jeans"],
  },
  {
    id: 726,
    imgSrc: "/images/products/black-18.jpg",
    imgHoverSrc: "/images/products/black-19.jpg",
    alt: "image-product",
    title: "Regular Fit Windbreaker",
    price: 114.95,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark sold-out",
        imgSrc: "/images/products/black-18.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white sold-out",
        imgSrc: "/images/products/white-13.jpg",
      },
    ],
    soldOut: true,
    filterCategories: ["Best seller", "Sale", "Graphics", "Jeans"],
  },
  {
    id: 727,
    imgSrc: "/images/products/black-20.jpg",
    imgHoverSrc: "/images/products/black-21.jpg",
    alt: "image-product",
    title: "Regular Fit Polo Shirt",
    price: 135.0,
    sizes: ["4 sizes available"],
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-20.jpg",
      },
      {
        name: "Taupe",
        colorClass: "bg_taupe",
        imgSrc: "/images/products/taupe-1.jpg",
      },
    ],
    preOrder: true,
    filterCategories: ["Best seller", "Sale", "Graphics", "Jeans"],
  },
  {
    id: 728,
    imgSrc: "/images/products/white-14.jpg",
    imgHoverSrc: "/images/products/white-15.jpg",
    alt: "image-product",
    title: "Regular Fit Linen-blend Shirt",
    price: 9.95,
    colors: [
      {
        name: "White",
        colorClass: "bg_white sold-out",
        imgSrc: "/images/products/white-14.jpg",
        tooltip: "White",
      },
    ],
    soldOut: true,
    filterCategories: ["Best seller", "Sale", "Graphics", "Jeans"],
  },
  {
    id: 729,
    imgSrc: "/images/products/black-23.jpg",
    imgHoverSrc: "/images/products/black-22.jpg",
    alt: "image-product",
    title: "Slim Fit Fine-knit Cotton Sweater",
    price: 31.99,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark sold-out",
        imgSrc: "/images/products/black-23.jpg",
        tooltip: "Black",
      },
      {
        name: "White",
        colorClass: "bg_white sold-out",
        imgSrc: "/images/products/white-16.jpg",
        tooltip: "White",
      },
      {
        name: "Blue",
        colorClass: "bg_blue-2 sold-out",
        imgSrc: "/images/products/blue-4.jpg",
        tooltip: "Blue",
      },
    ],
    soldOut: true,
    filterCategories: ["Best seller", "Sale", "Graphics", "Jeans"],
  },
];

export const products41 = [
  {
    id: 730,
    imgSrc: "/images/products/kid-1.jpg",
    imgHoverSrc: "/images/products/kid-2.jpg",
    width: 742,
    height: 742,
    title: "Dayton Teddy Fleece Jacket Burnt",
    price: 449.0,
    stockAvailable: 23,
    stockSold: 999,
    progress: 3,
    sizeInfo: null,
    saleInfo: null,
  },
  {
    id: 731,
    imgSrc: "/images/products/kid-14.jpg",
    imgHoverSrc: "/images/products/kid-11.jpg",
    width: 360,
    height: 360,
    title: "Dayton Teddy Fleece",
    price: 449.0,
    stockAvailable: 18,
    stockSold: 12,
    progress: 60,
    sizeInfo: "4 sizes available",
    saleInfo: ["-24%", "Pre-Order"],
  },
  {
    id: 732,
    imgSrc: "/images/products/kid-3.jpg",
    imgHoverSrc: "/images/products/kid-4.jpg",
    width: 360,
    height: 360,
    title: "S2 Anatomical Pacifiers Ivory/Sage",
    price: 99.0,
    oldPrice: "$100.00",
    stockAvailable: 12,
    stockSold: 321,
    progress: 5,
    sizeInfo: null,
    saleInfo: ["-1%"],
  },
  {
    id: 733,
    imgSrc: "/images/products/kid-12.jpg",
    imgHoverSrc: "/images/products/kid-13.jpg",
    width: 360,
    height: 360,
    title: "Vanilla White",
    price: 35.0,
    stockAvailable: 50,
    stockSold: 50,
    progress: 50,
    sizeInfo: null,
    saleInfo: null,
  },
  {
    id: 734,
    imgSrc: "/images/products/kid-14.jpg",
    imgHoverSrc: "/images/products/kid-11.jpg",
    width: 360,
    height: 360,
    title: "Dayton Teddy Fleece",
    price: 449.0,
    stockAvailable: 18,
    stockSold: 12,
    progress: 60,
    sizeInfo: "4 sizes available",
    saleInfo: ["-24%", "Pre-Order"],
  },
];

export const products42 = [
  {
    id: 735,
    imgSrc: "/images/products/kid-1.jpg",
    imgHoverSrc: "/images/products/kid-2.jpg",
    title: "Dayton Teddy Fleece Jacket Burnt",
    price: 449.0,
    sale: false,
  },
  {
    id: 736,
    imgSrc: "/images/products/kid-3.jpg",
    imgHoverSrc: "/images/products/kid-4.jpg",
    title: "S2 Anatomical Pacifiers Ivory/Sage",
    price: 99.0,
    oldPrice: "$100.00",
    sale: "-1%",
  },
  {
    id: 737,
    imgSrc: "/images/products/kid-6.jpg",
    imgHoverSrc: "/images/products/kid-6.jpg",
    title: "Wool Fleece Suit Moss Green",
    price: 45.0,
    oldPrice: "$55.00",
    sale: "-18%",
    colors: [
      {
        name: "Brown",
        imgSrc: "/images/products/kid-6.jpg",
        colorClass: "bg_brown",
      },
      {
        name: "Red",
        imgSrc: "/images/products/kid-5.jpg",
        colorClass: "bg_red",
      },
    ],
  },
  {
    id: 738,
    imgSrc: "/images/products/kid-15.jpg",
    imgHoverSrc: "/images/products/kid-10.jpg",
    title: "Ribbed Short Sleeve T-Shirt",
    price: 12.08,
    oldPrice: "$16.00",
    sale: "-25%",
    colors: [
      {
        name: "Light Green",
        imgSrc: "/images/products/kid-15.jpg",
        colorClass: "bg_light-green",
      },
      {
        name: "Yellow",
        imgSrc: "/images/products/kid-7.jpg",
        colorClass: "bg_yellow",
      },
      {
        name: "Pink",
        imgSrc: "/images/products/kid-8.jpg",
        colorClass: "bg_pink",
      },
    ],
  },
  {
    id: 739,
    imgSrc: "/images/products/kid-9.jpg",
    imgHoverSrc: "/images/products/kid-10.jpg",
    title: "Ottawa Lined Recycled Rain Set Flintstone",
    price: 48.0,
    oldPrice: "$53.00",
    sale: "-9%",
    colors: [
      {
        name: "Black",
        imgSrc: "/images/products/kid-9.jpg",
        colorClass: "bg_dark",
      },
      {
        name: "Pink",
        imgSrc: "/images/products/kid-10.jpg",
        colorClass: "bg_pink",
      },
    ],
  },
  {
    id: 740,
    imgSrc: "/images/products/kid-12.jpg",
    imgHoverSrc: "/images/products/kid-13.jpg",
    title: "Vanilla White",
    price: 35.0,
    sale: false,
  },
];

export const products43 = [
  {
    id: 741,
    imgSrc: "/images/shop/gallery/kid_gallery_1.jpg",
    alt: "image-gallery",
    wowDelay: "0s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 742,
    imgSrc: "/images/shop/gallery/kid_gallery_2.jpg",
    alt: "image-gallery",
    wowDelay: ".1s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 743,
    imgSrc: "/images/shop/gallery/kid_gallery_3.jpg",
    alt: "image-gallery",
    wowDelay: ".2s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 744,
    imgSrc: "/images/shop/gallery/kid_gallery_4.jpg",
    alt: "image-gallery",
    wowDelay: ".3s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 745,
    imgSrc: "/images/shop/gallery/kid_gallery_5.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 746,
    imgSrc: "/images/shop/gallery/kid_gallery_6.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 747,
    imgSrc: "/images/shop/gallery/kid_gallery_3.jpg",
    alt: "image-gallery",
    wowDelay: ".2s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
];

export const lookbookProducts = [
  {
    id: 748,
    imgSrc: "/images/shop/products/img-p2.png",
    altText: "lookbook-item",
    title: "Jersey thong body",
    price: 112.0,
    width: "98",
    height: "70",
    isLookBookProduct: true,
  },
  {
    id: 749,
    imgSrc: "/images/shop/products/img-p4.png",
    altText: "",
    title: "Ribbed modal T-shirt",
    price: 20.0,
    width: "98",
    height: "70",
    isLookBookProduct: true,
  },
  {
    id: 750,
    imgSrc: "/images/shop/products/img-p5.png",
    altText: "",
    title: "Ribbed Tank Top",
    price: 20.0,
    width: "98",
    height: "70",
    isLookBookProduct: true,
  },
  {
    id: 751,
    imgSrc: "/images/item/pr1.jpg",
    altText: "lookbook-item",
    title: "Stripe Tee Rust and Ivory",
    price: 41.0,
    width: "137",
    height: "120",
    isLookBookProduct: true,
  },
  {
    id: 752,
    imgSrc: "/images/item/pr7.jpg",
    altText: "",
    title: "Cushioned Dog Harness Moonstone",
    price: 48.0,
    width: "137",
    height: "120",
    isLookBookProduct: true,
  },
  {
    id: 753,
    imgSrc: "/images/item/pr10.jpg",
    altText: "",
    title: "The Scot Collar Mint",
    price: 56.0,
    width: "137",
    height: "120",
    isLookBookProduct: true,
  },
  {
    id: 754,
    imgSrc: "/images/shop/products/lb-furniture-1.jpg",
    altText: "lookbook-item",
    title: "Brasilia Lounge Chair",
    price: 982.0,
    width: "944",
    height: "676",
    isLookBookProduct: true,
  },
  {
    id: 755,
    imgSrc: "/images/shop/products/lb-furniture-2.jpg",
    altText: "",
    title: "AJ Wall Sconce",
    price: 982.0,
    width: "944",
    height: "676",
    isLookBookProduct: true,
  },
  {
    id: 756,
    imgSrc: "/images/shop/products/lb-furniture-3.jpg",
    altText: "",
    title: "Eames Lounge Chair",
    price: 6500.0,
    width: "944",
    height: "676",
    isLookBookProduct: true,
  },
  {
    id: 757,
    imgSrc: "/images/shop/products/lb-furniture-4.jpg",
    altText: "",
    title: "MG501 Cuba Lounge Chair, Paper Cord",
    price: 1366.0,
    width: "944",
    height: "676",
    isLookBookProduct: true,
  },
  {
    id: 758,
    imgSrc: "/images/products/kid-14.jpg",
    altText: "lookbook-item",
    title: "Dayton Teddy Fleece",
    price: 130.0,
    width: "360",
    height: "360",
    isLookBookProduct: true,
  },
  {
    id: 759,
    imgSrc: "/images/products/kid-3.jpg",
    altText: "",
    title: "S2 Anatomical Pacifiers Ivory/Sage",
    price: 99.0,
    width: "360",
    height: "360",
    isLookBookProduct: true,
  },
  {
    id: 760,
    imgSrc: "/images/products/kid-9.jpg",
    altText: "",
    title: "Ottawa Lined Recycled Rain Set Flintstone",
    price: 48.0,
    width: "360",
    height: "360",
    isLookBookProduct: true,
  },
  {
    id: 761,
    imgSrc: "/images/products/lb-men-1.jpg",
    altText: "",
    title: "Loose Fit Hoodie",
    price: 20.0,
    width: "167",
    height: "120",
    isLookBookProduct: true,
  },
  {
    id: 762,
    imgSrc: "/images/products/lb-men-2.jpg",
    altText: "",
    title: "Ribbed Tank Top",
    price: 20.0,
    width: "127",
    height: "120",
    isLookBookProduct: true,
  },
  {
    id: 763,
    imgSrc: "/images/item/setup-gear-1.jpg",
    altText: "",
    title: " Tag Case",
    price: 20.0,
    width: "111",
    height: "120",
    isLookBookProduct: true,
  },
  {
    id: 764,
    imgSrc: "/images/item/setup-gear-2.jpg",
    altText: "",
    title: "MG501 Cuba Lounge Chair, Paper Cord",
    price: 20.0,
    width: "168",
    height: "120",
    isLookBookProduct: true,
  },
  {
    id: 765,
    imgSrc: "/images/item/setup-gear-3.png",
    altText: "",
    title: "AJ Wall Sconce",
    price: 20.0,
    width: "168",
    height: "120",
    isLookBookProduct: true,
  },
  {
    id: 766,
    imgSrc: "/images/item/setup-gear-1.jpg",
    altText: "",
    title: " Tag Case",
    price: 20.0,
    width: "111",
    height: "120",
    isLookBookProduct: true,
  },
  {
    id: 767,
    imgSrc: "/images/item/setup-gear-2.jpg",
    altText: "",
    title: "MG501 Cuba Lounge Chair, Paper Cord",
    price: 20.0,
    width: "168",
    height: "120",
    isLookBookProduct: true,
  },
  {
    id: 768,
    imgSrc: "/images/item/setup-gear-3.png",
    altText: "",
    title: "AJ Wall Sconce",
    price: 20.0,
    width: "168",
    height: "120",
    isLookBookProduct: true,
  },
  {
    id: 769,
    imgSrc: "/images/item/setup-gear-1.jpg",
    altText: "",
    title: " Tag Case",
    price: 20.0,
    width: "111",
    height: "120",
    isLookBookProduct: true,
  },
  {
    id: 770,
    imgSrc: "/images/item/setup-gear-2.jpg",
    altText: "",
    title: "MG501 Cuba Lounge Chair, Paper Cord",
    price: 20.0,
    width: "168",
    height: "120",
    isLookBookProduct: true,
  },
  {
    id: 771,
    imgSrc: "/images/item/setup-gear-3.png",
    altText: "",
    title: "AJ Wall Sconce",
    price: 20.0,
    width: "168",
    height: "120",
    isLookBookProduct: true,
  },
  {
    id: 772,
    imgSrc: "/images/products/pink-handbag-1.jpg",
    imgAlt: "lookbook-item",
    width: 668,
    height: 668,
    title: "Alice Mini - Dusty Rose",
    price: 130.0,
    isLookBookProduct: true,
  },
  {
    id: 773,
    imgSrc: "/images/products/green-handbag-1.jpg",
    imgAlt: "Amanda - Green",
    width: 668,
    height: 668,
    title: "Amanda - Green",
    price: 138.0,
    isLookBookProduct: true,
  },
  {
    id: 774,
    imgSrc: "/images/products/brown-handbag-1.jpg",
    imgAlt: "Grace - Brown",
    width: 668,
    height: 668,
    title: "Grace - Brown",
    price: 138.0,
    isLookBookProduct: true,
  },
  {
    id: 775,
    imgSrc: "/images/products/sneaker-2.jpg",
    imgAlt: "lookbook-item",
    width: 120,
    height: 180,
    title: "REGULAR FIT WINDBREAKER",
    price: 130.0,
    isLookBookProduct: true,
  },
  {
    id: 776,
    imgSrc: "/images/products/sneaker-1.png",
    imgAlt: "Polar Night Pack 2 Fusion 2.0",
    width: 120,
    height: 120,
    title: "POLAR NIGHT PACK 2 FUSION 2.0",
    price: 99.0,
    isLookBookProduct: true,
  },
];

export const bundleproducts = [
  {
    id: 780,
    imgSrc: "/images/shop/products/hmgoepprod27.jpg",
    title: "Shein Ezwear Shoulder Tee",
    price: 39.0,
  },
  {
    id: 781,
    imgSrc: "/images/shop/products/hmgoepprod28.jpg",
    title: "Y'all Tee - Black",
    price: 38.0,
  },
  {
    id: 782,
    imgSrc: "/images/shop/products/hmgoepprod29.jpg",
    title: "Y'all Tee - Blush",
    price: 38.0,
  },
];

export const galleryData = [
  {
    id: 783,
    imgSrc: "/images/shop/gallery/gallery-7.jpg",
    alt: "image-gallery-7",
    wowDelay: ".2s",
    QuickAdd: true,
    tooltip: "Quick Add",
    title: "Y'all Tee - Black",
    price: 38.0,
  },

  {
    id: 786,
    imgSrc: "/images/shop/gallery/gallery-8.jpg",
    alt: "image-gallery-8",
    wowDelay: ".5s",

    tooltip: "View product",
    title: "Y'all Tee - Black",
    price: 38.0,
  },
];

export const products44 = [
  {
    id: 788,
    imgSrc: "/images/item/search-grocey1.jpg",
    imgAlt: "Berry World Blueberries",
    imgWidth: 68,
    imgHeight: 68,
    title: "Berry World Blueberries",
    price: 4.0,
    hasOldPrice: false,
  },
  {
    id: 789,
    imgSrc: "/images/item/search-grocey2.jpg",
    imgAlt: "Berry World Strawberries",
    imgWidth: 68,
    imgHeight: 63,
    title: "Berry World Strawberries",
    price: 30.25,
    oldPrice: 40.25,
    hasOldPrice: true,
  },
  {
    id: 790,
    imgSrc: "/images/item/search-grocey3.jpg",
    imgAlt: "Fish Said Fred Sea Bass Fillets",
    imgWidth: 68,
    imgHeight: 68,
    title: "Fish Said Fred Sea Bass Fillets",
    price: 6.0,
    hasOldPrice: false,
  },
  {
    id: 791,
    imgSrc: "/images/item/search-grocey4.jpg",
    imgAlt: "M&S Full-Bodied Greek Kalamata Olives",
    imgWidth: 68,
    imgHeight: 68,
    title: "M&S Full-Bodied Greek Kalamata Olives",
    price: 7.0,
    hasOldPrice: false,
  },
  {
    id: 792,
    imgSrc: "/images/item/search-grocey5.jpg",
    imgAlt: "M&S Roast Lamb Dinner",
    imgWidth: 68,
    imgHeight: 63,
    title: "M&S Roast Lamb Dinner",
    price: 3.7,
    oldPrice: 4.7,
    hasOldPrice: true,
  },
];

export const tfLoopItems = [
  {
    id: 796,
    imgSrc: "/images/products/white-3.jpg",
    imgAlt: "",
    imgWidth: 720,
    imgHeight: 1005,
    title: "Cotton jersey top",
    compareAtPrice: 10.0,
    price: 8.0,
    salePrice: 8.0,
    isOnSale: true,
  },
  {
    id: 797,
    imgSrc: "/images/products/white-2.jpg",
    imgAlt: "",
    imgWidth: 668,
    imgHeight: 932,
    title: "Mini crossbody bag",
    price: 18.0,
    isOnSale: false,
  },
  {
    id: 798,
    imgSrc: "/images/products/white-1.jpg",
    imgAlt: "",
    imgWidth: 720,
    imgHeight: 1005,
    title: "Oversized Printed T-shirt",
    price: 18.0,
    isOnSale: false,
  },
];

export const productBestcell = [
  {
    id: 799,
    imgSrc: "/images/shop/products/gift-card-2.jpg",
    imgAlt: "",
    imgWidth: 720,
    imgHeight: 1005,
    title: "Boutique eGift Card",
    price: 18.0,
    isOnSale: false,
  },
];

export const products45 = [
  {
    id: 800,
    imgSrc: "/images/products/bark-phone-blue.jpg",
    imgHoverSrc: "/images/products/bark-phone-blue2.jpg",
    alt: "image-product",
    title: "Case with MagSafe",
    price: 19.99,
    colors: [
      {
        name: "Blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/bark-phone-blue.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/bark-phone-black.jpg",
      },
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/bark-phone-orange.jpg",
      },
      {
        name: "Green",
        colorClass: "bg_green",
        imgSrc: "/images/products/bark-phone-green.jpg",
      },
      {
        name: "Light Brown",
        colorClass: "bg_light-brown",
        imgSrc: "/images/products/bark-phone-light-brown.jpg",
      },
    ],
  },
  {
    id: 801,
    imgSrc: "/images/products/cable-black.jpg",
    imgHoverSrc: "/images/products/cable-black2.jpg",
    alt: "image-product",
    title: "MagSafe 3 Cable",
    price: 39,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/cable-black.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/cable-white.jpg",
      },
      {
        name: "Cream",
        colorClass: "bg_cream",
        imgSrc: "/images/products/cable-cream.jpg",
      },
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/cable-grey.jpg",
      },
    ],
  },
  {
    id: 802,
    imgSrc: "/images/products/headphone-white.jpg",
    imgHoverSrc: "/images/products/headphone-red.jpg",
    alt: "image-product",
    title: "Beats Studio Buds",
    price: 199,
    colors: [
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/headphone-white.jpg",
      },
      {
        name: "Red",
        colorClass: "bg_red",
        imgSrc: "/images/products/headphone-red2.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/headphone-black.jpg",
      },
    ],
  },
  {
    id: 803,
    imgSrc: "/images/products/albert-black.jpg",
    imgHoverSrc: "/images/products/albert-white.jpg",
    alt: "image-product",
    title: "Blue Ocean Band",
    price: 9,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/albert-black.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/albert-white.jpg",
      },
    ],
  },
  {
    id: 804,
    imgSrc: "/images/products/cable-black.jpg",
    imgHoverSrc: "/images/products/cable-black2.jpg",
    alt: "image-product",
    title: "MagSafe 3 Cable",
    price: 39,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/cable-black.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/cable-white.jpg",
      },
      {
        name: "Cream",
        colorClass: "bg_cream",
        imgSrc: "/images/products/cable-cream.jpg",
      },
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/cable-grey.jpg",
      },
    ],
  },
  {
    id: 805,
    imgSrc: "/images/products/headphone-white.jpg",
    imgHoverSrc: "/images/products/headphone-red.jpg",
    alt: "image-product",
    title: "Beats Studio Buds",
    price: 199,
    colors: [
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/headphone-white.jpg",
      },
      {
        name: "Red",
        colorClass: "bg_red",
        imgSrc: "/images/products/headphone-red2.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/headphone-black.jpg",
      },
    ],
  },
];
export const products46 = [
  {
    id: 806,
    imgSrc: "/images/products/headphone-red2.jpg",
    imgHoverSrc: "/images/products/headphone-red.jpg",
    title: "Beats Studio Buds",
    price: 199,
    preOrder: true,
    colors: [
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/headphone-white.jpg",
      },
      {
        name: "Red",
        colorClass: "bg_red",
        imgSrc: "/images/products/headphone-red2.jpg",
        active: true,
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/headphone-black.jpg",
      },
    ],
  },
  {
    id: 807,
    imgSrc: "/images/products/bark-phone-blue.jpg",
    imgHoverSrc: "/images/products/bark-phone-blue2.jpg",
    title: "Case with MagSafe",
    price: 19.99,
    colors: [
      {
        name: "Blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/bark-phone-blue.jpg",
        active: true,
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/bark-phone-black.jpg",
      },
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/bark-phone-orange.jpg",
      },
      {
        name: "Green",
        colorClass: "bg_green",
        imgSrc: "/images/products/bark-phone-green.jpg",
      },
      {
        name: "Light Brown",
        colorClass: "bg_light-brown",
        imgSrc: "/images/products/bark-phone-light-brown.jpg",
      },
    ],
  },
  {
    id: 808,
    imgSrc: "/images/products/wireless-charging-black2.jpg",
    imgHoverSrc: "/images/products/wireless-charging-white3.jpg",
    title: "Smart Watch Series 6",
    price: 299.99,
    colors: [
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/wireless-charging-white2.jpg",
        active: true,
      },
      {
        name: "Dark",
        colorClass: "bg_dark",
        imgSrc: "/images/products/wireless-charging-black2.jpg",
      },
    ],
  },
  {
    id: 809,
    imgSrc: "/images/products/wireless-charging-black.jpg",
    imgHoverSrc: "/images/products/wireless-charging-white.jpg",
    title: "Ultra Thin Laptop",
    price: 999.99,
    colors: [
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/wireless-charging-white.jpg",
        active: true,
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/wireless-charging-black.jpg",
      },
    ],
  },
  {
    id: 810,
    imgSrc: "/images/products/albert-black.jpg",
    imgHoverSrc: "/images/products/albert-white.jpg",
    title: "Wireless Earbuds",
    price: 149.99,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/albert-black.jpg",
        active: true,
      },
      {
        name: "Orange",
        colorClass: "bg_orange",
        imgSrc: "/images/products/albert-orange.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/albert-white2.jpg",
      },
    ],
  },
];
export const galleryItems3 = [
  {
    id: 811,
    imgSrc: "/images/shop/gallery/accessories-gallery1.jpg",
    alt: "image-gallery",
    wowDelay: "0s",
    title: "Beats Studio Buds",
    price: 199,
    quickAdd: true,
  },
  {
    id: 812,
    imgSrc: "/images/shop/gallery/accessories-gallery2.jpg",
    alt: "image-gallery",
    wowDelay: ".1s",
    quickAdd: true,
    title: "Beats Studio Buds",
    price: 199,
  },
  {
    id: 813,
    imgSrc: "/images/shop/gallery/accessories-gallery3.jpg",
    alt: "image-gallery",
    wowDelay: ".2s",
    quickAdd: true,
    title: "Beats Studio Buds",
    price: 199,
  },
  {
    id: 814,
    imgSrc: "/images/shop/gallery/accessories-gallery4.jpg",
    alt: "image-gallery",
    wowDelay: ".3s",
    quickAdd: false,
    title: "Beats Studio Buds",
    price: 199,
  },
  {
    id: 815,
    imgSrc: "/images/shop/gallery/accessories-gallery5.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: false,
    title: "Beats Studio Buds",
    price: 199,
  },
  {
    id: 816,
    imgSrc: "/images/shop/gallery/accessories-gallery6.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: false,
    title: "Beats Studio Buds",
    price: 199,
  },
  {
    id: 817,
    imgSrc: "/images/shop/gallery/accessories-gallery7.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: false,
    title: "Beats Studio Buds",
    price: 199,
  },
  {
    id: 818,
    imgSrc: "/images/shop/gallery/accessories-gallery8.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: false,
    title: "Beats Studio Buds",
    price: 199,
  },
  {
    id: 819,
    imgSrc: "/images/shop/gallery/accessories-gallery5.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: false,
    title: "Beats Studio Buds",
    price: 199,
  },
];
export const products47 = [
  {
    id: 820,
    imgSrc: "/images/products/grocery-1.jpg",
    imgHoverSrc: "/images/products/grocery-2.jpg",
    title: "Berry World Strawberries",
    oldPrice: 40.25,
    price: 30.25,
    available: 23,
    sold: 80,
    salePercentage: -31,
    timer: 8007500,
  },
  {
    id: 821,
    imgSrc: "/images/products/grocery-3.jpg",
    imgHoverSrc: "/images/products/grocery-4.jpg",
    title: "M&S Roast Lamb Dinner",
    oldPrice: 4.7,
    price: 3.7,
    available: 5,
    sold: 105,
    salePercentage: -31,
    timer: 8007500,
  },
  {
    id: 822,
    imgSrc: "/images/products/grocery-5.jpg",
    imgHoverSrc: "/images/products/grocery-6.jpg",
    title: "Brown Rice Drink",
    price: 1.76,
    available: 82,
    sold: 40,
    timer: 8007500,
  },
  {
    id: 823,
    imgSrc: "/images/products/grocery-3.jpg",
    imgHoverSrc: "/images/products/grocery-4.jpg",
    title: "M&S Roast Lamb Dinner",
    oldPrice: 4.7,
    price: 3.7,
    available: 5,
    sold: 105,
    salePercentage: -31,
    timer: 8007500,
  },
];

export const products48 = [
  {
    id: 824,
    imgSrc: "/images/products/vegetable1.jpg",
    imgHoverSrc: "/images/products/vegetable2.jpg",
    title: "Ocado Little Gem Lettuce",
    price: 85,
    filterCategories: ["Meat", "Tomatoes", "Soup"],
  },
  {
    id: 825,
    imgSrc: "/images/products/fruits.jpg",
    imgHoverSrc: "/images/products/fruits2.jpg",
    title: "Ocado Red Seedless Grapes",
    price: 4,
    filterCategories: ["Meat", "Oils", "Soup"],
  },
  {
    id: 826,
    imgSrc: "/images/products/meat1.jpg",
    imgHoverSrc: "/images/products/meat2.jpg",
    title: "Silere Merino Lamb Boneless Leg Joint",
    price: 13,
    filterCategories: ["Meat", "Oils"],
  },
  {
    id: 827,
    imgSrc: "/images/products/fruits3.jpg",
    imgHoverSrc: "/images/products/fruits4.jpg",
    title: "Ocado Little Gem Lettuce",
    price: 85,
    filterCategories: ["Meat", "Tomatoes", "Soup"],
  },
  {
    id: 828,
    imgSrc: "/images/products/fruits5.jpg",
    imgHoverSrc: "/images/products/fruits6.jpg",
    title: "M&S Full-Bodied Greek Kalamata Olives",
    price: 7,
    filterCategories: ["Meat", "Oils", "Tomatoes"],
  },
  {
    id: 829,
    imgSrc: "/images/products/milk.jpg",
    imgHoverSrc: "/images/products/milk2.jpg",
    title: "The Collective Suckies Peach & Apricot Yoghurt",
    price: 75,
    filterCategories: ["Meat", "Oils", "Soup"],
  },
  {
    id: 830,
    imgSrc: "/images/products/fish.jpg",
    imgHoverSrc: "/images/products/fish2.jpg",
    title: "Fresh Atlantic Salmon",
    price: 20,
    filterCategories: ["Meat", "Tomatoes", "Soup"],
  },
  {
    id: 831,
    imgSrc: "/images/products/fruits3.jpg",
    imgHoverSrc: "/images/products/fruits4.jpg",
    title: "Ocado Little Gem Lettuce",
    price: 85,
    filterCategories: ["Meat", "Oils", "Tomatoes"],
  },
];
export const products49 = [
  {
    id: 832,
    imgSrc: "/images/products/kid-3.jpg",
    imgHoverSrc: "/images/products/kid-4.jpg",
    title: "S2 Anatomical Pacifiers Ivory/Sage",
    oldPrice: 100,
    price: 99,
    available: 60,
    sold: 40,
    salePercentage: -1,
  },
  {
    id: 833,
    imgSrc: "/images/products/kid-1.jpg",
    imgHoverSrc: "/images/products/kid-2.jpg",
    title: "Dayton Teddy Fleece Jacket Burnt",
    oldPrice: 449,
    price: 449,
    available: 154,
    sold: 46,
    salePercentage: 0,
  },
  {
    id: 834,
    imgSrc: "/images/products/stroller-baby-white.jpg",
    imgHoverSrc: "/images/products/stroller-baby-black3.jpg",
    title: "Butterfly Complete Stroller",
    oldPrice: 600,
    price: 499,
    available: 180,
    sold: 60,
    salePercentage: -17,
  },
  {
    id: 835,
    imgSrc: "/images/products/nes-pink.jpg",
    imgHoverSrc: "/images/products/nes-white.jpg",
    title: "Cozy Nes",
    oldPrice: 65,
    price: 60,
    available: 180,
    sold: 60,
    salePercentage: -8,
  },
  {
    id: 836,
    imgSrc: "/images/products/kid-1.jpg",
    imgHoverSrc: "/images/products/kid-2.jpg",
    title: "Dayton Teddy Fleece Jacket Burnt",
    oldPrice: 449,
    price: 449,
    available: 154,
    sold: 46,
    salePercentage: 0,
  },
];
export const products50 = [
  {
    id: 837,
    imgSrc: "/images/products/kid-5.jpg",
    imgHoverSrc: "/images/products/kid-6.jpg",
    title: "Wool Fleece Suit Moss Green",
    oldPrice: 55,
    price: 45,
  },
  {
    id: 838,
    imgSrc: "/images/products/stroller-baby-kaki2.jpg",
    imgHoverSrc: "/images/products/stroller-baby-black3.jpg",
    title: "Butterfly Complete Stroller",
    oldPrice: 515,
    price: 499,
  },
  {
    id: 839,
    imgSrc: "/images/products/nes-pink.jpg",
    imgHoverSrc: "/images/products/nes-white.jpg",
    title: "Cozy Nes",
    oldPrice: 65,
    price: 60,
  },
  {
    id: 840,
    imgSrc: "/images/products/kid-9.jpg",
    imgHoverSrc: "/images/products/kid-10.jpg",
    title: "Ottawa Lined Recycled Rain Set Flintstone",
    oldPrice: 53,
    price: 48,
  },
  {
    id: 841,
    imgSrc: "/images/products/kid-3.jpg",
    imgHoverSrc: "/images/products/kid-4.jpg",
    title: "S2 Anatomical Pacifiers Ivory/Sage",
    oldPrice: 100,
    price: 99,
  },
  {
    id: 842,
    imgSrc: "/images/products/kid-7.jpg",
    imgHoverSrc: "/images/products/kid-8.jpg",
    title: "Kids Jacket",
    oldPrice: 75,
    price: 70,
  },
];

export const allProducts = [
  ...workweare1,
  ...protectiveclothing,
  ...respiratoryprotection,
  ...eyeprotection,
  ...headprotection,
  ...kidsselection,
  ...handarmprotection,
  ...footprotection,
  ...products1,
  ...products2,
  ...products3,
  ...galleryItems,
  ...products4,
  ...products5,
  ...galleryItems2,
  ...products6,
  ...products7,
  ...productSlides,
  ...products9,
  ...products10,
  ...products11,
  ...products12,
  ...products13,
  ...products14,
  ...products15,
  ...products16,
  ...products17,
  ...products18,
  ...products19,
  ...products20,
  ...products21,
  ...products22,
  ...products23,
  ...products24,
  ...products25,
  ...products26,
  ...products27,
  ...products28,
  ...products29,
  ...products30,
  ...products31,
  ...products32,
  ...products33,
  ...products34,
  ...products35,
  ...products36,
  ...products37,
  ...products38,
  ...products39,
  ...products40,
  ...products41,
  ...products42,
  ...tfLoopItems,
  ...productBestcell,
  ...products45,
  ...products46,
  ...galleryItems3,
  ...products47,
  ...products48,
  ...products49,
  ...products50,
];
