"use client";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next"; // Import i18n

const languageOptions = [
  { id: "en", label: "English" },
  { id: "sq", label: "Shqip" },
  { id: "de", label: "German" },
];

export default function LanguageSelect({
  parentClassName = "image-select center style-default type-languages",
  topStart = false,
}) {
  const { i18n } = useTranslation(); // Access the i18n instance
  const [selected, setSelected] = useState(languageOptions[0]);
  const [isDDOpen, setIsDDOpen] = useState(false);
  const languageSelect = useRef();

  useEffect(() => {
    // Initialize the language when the component first mounts
    if (!i18n.language) {
      i18n.changeLanguage("en"); // Set default language to 'en' if not already set
    }
    const currentLanguage = i18n.language || "en"; // Fallback to 'en' if no language is set
    setSelected(languageOptions.find((lang) => lang.id === currentLanguage)); // Set the selected language
  }, [i18n]); // Runs whenever i18n is updated (language change)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        languageSelect.current &&
        !languageSelect.current.contains(event.target)
      ) {
        setIsDDOpen(false); // Close the dropdown if click is outside
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Handle language change
  const handleLanguageChange = (language) => {
    setSelected(language);
    i18n.changeLanguage(language.id); // Change language in i18next
  };

  return (
    <div
      className={`dropdown bootstrap-select ${parentClassName} dropup`}
      onClick={() => setIsDDOpen((prev) => !prev)}
      ref={languageSelect}
    >
      <select
        className="image-select center style-default type-languages"
        tabIndex="null"
        value={i18n.language} // Bind it to i18n's current language
        onChange={(e) => {
          handleLanguageChange(
            languageOptions.find((lang) => lang.id === e.target.value)
          );
        }}
      >
        {languageOptions.map((option, i) => (
          <option key={i} value={option.id}>
            {option.label}
          </option>
        ))}
      </select>
      <button
        type="button"
        tabIndex={-1}
        className={`btn dropdown-toggle btn-light ${isDDOpen ? "show" : ""}`}
      >
        <div className="filter-option">
          <div className="filter-option-inner">
            <div className="filter-option-inner-inner">{selected.label}</div>
          </div>
        </div>
      </button>
      <div
        className={`dropdown-menu ${isDDOpen ? "show" : ""}`}
        style={{
          maxHeight: "899.688px",
          overflow: "hidden",
          minHeight: 142,
          position: "absolute",
          inset: "auto auto 0px 0px",
          margin: 0,
          transform: `translate(0px, ${topStart ? 22 : -20}px)`,
        }}
        data-popper-placement={`${!topStart ? "top" : "bottom"}-start`}
      >
        <div
          className="inner show"
          style={{
            maxHeight: "869.688px",
            overflowY: "auto",
            minHeight: 112,
          }}
        >
          <ul
            className="dropdown-menu inner show"
            role="presentation"
            style={{ marginTop: 0, marginBottom: 0 }}
          >
            {languageOptions.map((elm, i) => (
              <li
                key={i}
                onClick={() => handleLanguageChange(elm)} // Update language on click
                className={`selected ${selected.id === elm.id ? "active" : ""}`}
              >
                <a
                  className={`dropdown-item ${
                    selected.id === elm.id ? "active selected" : ""
                  }`}
                >
                  <span className="text">{elm.label}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
