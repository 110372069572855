export const footerLinks = [
  { href: "/workwear", text: "Workwear" },
  { href: "/eye-protection", text: "Eye Protection" },
  { href: "/foot-protection", text: "Foot Protection" },
  { href: "/hand-arm-protection", text: "Hand Arm Protection" },
  { href: "/head-protection", text: "Head Protection" },
  { href: "/kids-selection", text: "Kids Selection" },
  { href: "/respiratory-protection", text: "Respiratory Protection" },
];

export const aboutLinks = [
  { href: "/about-us", text: "About us" },
  { href: "/our-store", text: "Store" },
  { href: "/contact-1", text: "Contact Us" },
];

export const paymentImages = [
  { src: "/images/payments/visa.png", width: 48, height: 30, alt: "" },
  { src: "/images/payments/img-1.png", width: 49, height: 30, alt: "" },
  { src: "/images/payments/img-2.png", width: 48, height: 30, alt: "" },
  { src: "/images/payments/img-3.png", width: 57, height: 30, alt: "" },
  { src: "/images/payments/img-4.png", width: 49, height: 30, alt: "" },
];
