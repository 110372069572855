import { useTranslation } from "react-i18next";

export const marqueeItems = [
  "1title",
  "2title",
  "3title",
  "4title",
  "5title",
  "6title",
  "7title",
  "8title",
  "9title",
  "10title",
];

export const marqueeItems2 = [
  { text: "LIMITED TIME OFFER: FASHION SALE YOU CAN'T RESIST" },
  { text: "FREE SHIPPING AND RETURN" },
  { text: "NEW SEASON, NEW STYLES: FASHION SALE YOU CAN'T MISS" },
  { text: "LIMITED TIME OFFER: FASHION SALE YOU CAN'T RESIST" },
  { text: "FREE SHIPPING AND RETURN" },
  { text: "NEW SEASON, NEW STYLES: FASHION SALE YOU CAN'T MISS" },
  { text: "LIMITED TIME OFFER: FASHION SALE YOU CAN'T RESIST" },
  { text: "FREE SHIPPING AND RETURN" },
  { text: "NEW SEASON, NEW STYLES: FASHION SALE YOU CAN'T MISS" },
  { text: "LIMITED TIME OFFER: FASHION SALE YOU CAN'T RESIST" },
  { text: "FREE SHIPPING AND RETURN" },
  { text: "NEW SEASON, NEW STYLES: FASHION SALE YOU CAN'T MISS" },
];
